import React, { useState, useEffect, Fragment, useMemo } from 'react';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
import SectionCrudForm from './SectionCrudForm';
import { withRouter } from 'react-router-dom';
import { IonButton, IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonList } from '@ionic/react';
import { useDeleteDoc } from '../modules/entity/useDeleteDoc';
import { sortDocsByField, stringifyAttrValParams } from '../libs/utils';
import { PaginationActions, useFilterPagination } from '../modules/entity/useFilterPagination';
import BadgeLoading from './ui/BadgeLoading';
import EntityFilterMenuDisplay from './EntityFilterMenuDisplay';
import { useScrollUtils } from '../modules/panel/useScrollUtils';
import { useModule } from '../context/ModuleContext';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { Pagination } from './ui/Pagination';


export const SectionListFilterPaginated = (props) => {
  let { filterPath, sectionId, entitySpecs, paginationLibs, history } = props;
  let { queryParams, pagesData, isLoadingFilters, totalPages, totalCount, isEmptyResults, nextPage, currentPage, setPage } = paginationLibs;
  const { scrollTo } = useScrollUtils();

  const onFormClear = () => {
    history.push(filterPath);
  };

  const onFormChange = (formValues) => {
    if (formValues) {
      const attrValParams = stringifyAttrValParams(formValues);
      const url = attrValParams.length ? `${filterPath}/#/${attrValParams}` : filterPath;
      history.push(url);
      scrollTo(sectionId);
    }
  };

  return (
    <div className={`flex flex-col lg:flex-row`}>
      <div className="lg:basis-4/12 mb-4">
        <EntityFilterMenuDisplay
          {...entitySpecs}
          style={'search-collapsible-drawer'}
          filterData={queryParams}
          defaultPath={filterPath}
          onFormClear={onFormClear}
          onFormChange={onFormChange}
          totalCount={totalCount}
        />
      </div>
      <div className={`lg:pl-4 lg:py-2.5 w-full`}>
        <div className="grid grid-cols-1 gap-4 md:gap-5 items-start">
          {pagesData?.map((pageData, index) => (
            <Fragment key={index}>
              <SectionListGrid {...props} entitySlug={entitySpecs.entitySlug} docs={pageData} />
            </Fragment>
          ))}
        </div>
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            nextPage();
            setTimeout(() => ev.target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        {/* <IonContent>
        </IonContent> */}
        {/* {isLoadingFilters ? (
          <div className="py-12 flex place-content-center content-center items-center font-brand-main">
            <BadgeLoading className="text-brand-primary" />
          </div>
        ) : null} */}
        {!isLoadingFilters && isEmptyResults ? (
          <div className="text-center">
            No hay resultados.
          </div>
        ) : null}
        {/* {!isLoadingFilters && !isEmptyResults ? (
          totalPages && pagesData?.length ? (
            <ReactVisibilitySensor
              //minTopValue={500} // Ajusta esta distancia según tu necesidad
              partialVisibility={true}
              offset={{ bottom: -100 }}
              delayedCall={false} // Activa el sensor tan pronto como se alcance la distancia mínima
              onChange={nextPage}
            >
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                setActive={setPage}
                classes={{ paginationContainer: 'mt-8' }}
              />
            </ReactVisibilitySensor>          
          ) : null
        ) : null} */}
      </div>
    </div>
  );
};

export const SectionListDragDrop = ({ entitySlug, docs, handleDragEnd, ListItem, ListBtns, handleEdit, handleDelete, ItemExtraActions, isAllowed, showBtnUpdate, showBtnDelete, showBtnMove }) => (
  <DragDropContext onDragEnd={handleDragEnd}>
    <Droppable droppableId="docs">
      {(provided) => (
        <ul className={`grid grid-cols-1 gap-2`} {...provided.droppableProps} ref={provided.innerRef}>
          {docs.map((doc, index) => (
            <Draggable key={doc.id} draggableId={doc.id} index={index}>
              {(provided) => (
                <div id={doc.id} ref={provided.innerRef} {...provided.draggableProps}>
                  <li className="bg-white rounded px-2 py-2 border border-gray-300">
                    <div className="flex place-content-stretch mb-2">
                      <ListItem doc={doc} />
                    </div>
                    <div className='relative w-full'>
                      <ListBtns doc={doc} />
                      {(isAllowed(entitySlug, ['update']) && showBtnUpdate) && (
                        <IonButton color="medium" size="small" fill="clear" onClick={() => handleEdit(doc)}>
                          Editar
                        </IonButton>
                      )}
                      {(isAllowed(entitySlug, ['delete']) && showBtnDelete) && (
                        <IonButton color="medium" size="small" fill="clear" onClick={() => handleDelete(doc)}>
                          Eliminar
                        </IonButton>
                      )}
                      {(isAllowed(entitySlug, ['update']) && showBtnMove) && (
                        <IonButton color="medium" size="small" fill="clear" {...provided.dragHandleProps}>
                          Mover
                        </IonButton>
                      )}
                      {ItemExtraActions && <ItemExtraActions doc={doc} isAllowed={isAllowed} />}
                    </div>
                  </li>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  </DragDropContext>
);

export const SectionListGrid = ({ entitySlug, docs, ListItem, ListBtns, handleEdit, handleDelete, ItemExtraActions, isAllowed, showBtnUpdate, showBtnDelete, showBtnMove }) => {
  return (
    <div className="grid grid-cols-1 gap-2">
      {docs.map(doc => (
        <div key={doc.id} className="bg-white rounded px-2 py-2 border border-gray-300">
          <div className="flex place-content-stretch mb-2">
            <ListItem doc={doc} />
          </div>
          <div className='relative w-full'>
            <ListBtns doc={doc} />
            {(isAllowed(entitySlug, ['update']) && showBtnUpdate) && (
              <IonButton color="medium" size="small" fill="clear" onClick={() => handleEdit(doc)}>
                Editar
              </IonButton>
            )}
            {(isAllowed(entitySlug, ['delete']) && showBtnDelete) && (
              <IonButton color="medium" size="small" fill="clear" onClick={() => handleDelete(doc)}>
                Eliminar
              </IonButton>
            )}
            {ItemExtraActions && <ItemExtraActions doc={doc} isAllowed={isAllowed} />}
          </div>
        </div>
      ))}
    </div>
  );
};

export const fetchData = async ({ model, reorder, sortDocs, softFilter, setDocs, onFetch }) => {
  let docs = await model.getAll();
  docs = docs.filter(doc => !doc.data?.deleted);

  if (reorder) {
    sortDocsByField(docs, 'sort');
  } else {
    if (sortDocs) {
      docs = sortDocs(docs);
    } else {
      sortDocsByField(docs, 'createdAt DESC', 'dateString');
    }
  }

  if (softFilter) {
    docs = softFilter(docs);
  }

  setDocs(docs);
  onFetch && onFetch(docs);
};

// En SectionCrudModel absatrae la lógica de gestión de los datos del listado
// separando en dos modos: 'all' y 'filter-paginated'
// Por ahora ignora el modo 'filter-paginated', lo implementaremos en otro momento
// Implementa el modo: 'all'

// Ahora implementa el modo: 'filter-paginated'
// utiliza useFilterPagination
// adapta SectionListGrid para utilizar pagesData[currentPage]
// SectionListDragDrop sólo funciona con el modo 'all'


function SectionCrudModel(props) {
  let {
    history,
    refresh,
    model,
    entitySlug,
    editStyle,
    dataMode = 'all', // 'all' or 'filter-paginated'
    listStyle = 'dragable',
    title,
    showToast = true,
    showBtnAdd = true,
    showBtnUpdate = true,
    showBtnDelete = true,
    showBtnMove = true,
    fieldsRequired,
    onValidation = (() => null),
    fetchItems = null,
    sortDocs = null,
    handleBeforeSave = (() => null),
    handleBeforeDelete = (() => null),
    softFilter = null,
    onDelete = (() => null),
    onFetch = (() => null),
    navigateTo = (() => null),
    navigateToClose = null,
    ListItem,
    ExtraActions,
    ItemExtraActions,
    ListBtns = (() => null),
    FormSection = null,
    FormInputFields = null,
    addButtonPermissionAction = 'create',
    addButtonLabel = '+ Agregar',
    classNameFormSection = '',

    // dataMode 'filter-paginated'
    entitySpecs,
    filterPath,
    queryParams
  } = props;

  const sectionId = useMemo(() => (_.uniqueId('section')), []);
  const [docs, setDocs] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [openSelector, setOpenSelector] = useState(false);
  const { isAllowed } = useModule();
  let { DeleteModal, handleDelete } = useDeleteDoc({
    selectedDoc,
    setSelectedDoc,
    showToast: true,
    entitySlug
  });

  const reorder = listStyle === 'dragable';
  const FormSectionToUse = FormSection || SectionCrudForm;

  const paginationLibs = useFilterPagination({ entitySpecs, queryParams, isEnabled: dataMode === 'filter-paginated' });
  let { pagesData, currentPage } = paginationLibs;

  let ListComponent;
  if (listStyle === 'dragable') {
    ListComponent = SectionListDragDrop
  } 
  else if (listStyle === 'list') {
    ListComponent = SectionListGrid;
  }
  else if (listStyle === 'filter-paginated') {
    ListComponent = SectionListFilterPaginated;
  }

  const refreshList = () => {
    let props = {
      model,
      reorder,
      sortDocs,
      softFilter,
      setDocs,
      onFetch
    };
    if (fetchItems) {
      fetchItems(props);
    } else {
      fetchData(props);
    }
  };

  useEffect(() => {
    if (dataMode === 'all') {
      refreshList();
    }
    // Aquí es donde podrías implementar la lógica para 'filter-paginated' en el futuro
  }, [refresh, dataMode]);

  // modes comprobations
  if (listStyle === 'dragable' && dataMode !== 'all') {
    throw new Error('listStyle "dragable" only works with dataMode "all"');
  }

  if (listStyle !== 'filter-paginated' && dataMode === 'filter-paginated') {
    throw new Error('dataMode "filter-paginated" only works with listStyle "filter-paginated"');
  }

  const handleSave = async (docData) => {
    if (reorder) {
      docData.sort = (docData.sort >= 0) ? docData.sort : docs.length;
    }
    handleBeforeSave(docData);
    await model.createOrUpdate(docData);
    refreshList();
    setOpenSelector(false);
    setSelectedDoc(null);
  };

  const handleEdit = (doc) => {
    setSelectedDoc(doc);
    if (editStyle === 'modal' || editStyle === 'onsite') {
      setOpenSelector(true);
    } else if (editStyle === 'route') {
      history.push(navigateTo(doc));
    }
  };

  const handleAdd = () => {
    if (editStyle === 'modal' || editStyle === 'onsite') {
      setSelectedDoc({});
      setOpenSelector(true);
    } else if (editStyle === 'route') {
      setSelectedDoc({});
      history.push(navigateTo());
    }
  };

  const handleOnDelete = async (selectedDoc) => {
    refreshList();
    await handleBeforeDelete(selectedDoc);
  }

  const handleClose = () => {
    setOpenSelector(false);
    setSelectedDoc(null);
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;
    const newOrder = Array.from(docs);
    const [movedItem] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedItem);
    if (reorder) {
      setDocs(newOrder);
      await model.saveSort(newOrder);
      showToast && toast.success('Se ha actualizado el orden');
    }
  };

  const renderListComponent = () => {
    if (dataMode === 'all') {
      return (
        <ListComponent
          entitySlug={entitySlug}
          docs={docs}
          handleDragEnd={handleDragEnd}
          ListItem={ListItem}
          ListBtns={ListBtns}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          ItemExtraActions={ItemExtraActions}
          reorder={reorder}
          isAllowed={isAllowed}
          showBtnUpdate={showBtnUpdate}
          showBtnDelete={showBtnDelete}
          showBtnMove={showBtnMove}
          history={history}
          sectionId={sectionId}
        />
      );
    } else if (dataMode === 'filter-paginated') {
      return (
        <ListComponent
          filterPath={filterPath}
          docs={pagesData ? pagesData[currentPage - 1] : []}
          entitySpecs={entitySpecs}
          paginationLibs={paginationLibs}
          ListItem={ListItem}
          ListBtns={ListBtns}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          ItemExtraActions={ItemExtraActions}
          isAllowed={isAllowed}
          showBtnUpdate={showBtnUpdate}
          showBtnDelete={showBtnDelete}
          showBtnMove={showBtnMove}
          history={history}
          sectionId={sectionId}
        />
      );
    }
  };

  return (
    <div className="section-crud-model">
      <div className={`flex flex-row place-content-between items-center place-items-center ${(pagesData?.length || docs?.length) > 0 ? 'mb-4' : ''}`}>
        {title && <h1 className="text-xl font-semibold">{title}</h1>}
        {(isAllowed(entitySlug, [addButtonPermissionAction]) && showBtnAdd) && (
          <IonButton size="small" fill="solid" color="secondary" onClick={handleAdd}>
            {addButtonLabel}
          </IonButton>
        )}
        {ExtraActions && <ExtraActions {...{ refreshList, toast }} />}
      </div>
      {openSelector && selectedDoc && (
        <div className={`my-5 ${classNameFormSection}`}>
          <FormSectionToUse
            model={model}
            editStyle={editStyle}
            doc={selectedDoc}
            onSave={handleSave}
            onClose={handleClose}
            fieldsRequired={fieldsRequired}
            onValidation={onValidation}
            FormInputFields={FormInputFields}
            showToast={showToast}
          />
        </div>
      )}
      {renderListComponent()}
      <DeleteModal onDelete={handleOnDelete} />
    </div>
  );
}

export default withRouter(SectionCrudModel);
