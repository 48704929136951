import React, { useState } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import Autocomplete from 'react-google-autocomplete';
import config from '../../config';


export const DistanceSlider = ({ value, onChange, classes }) => {
  return (
    <div className={classes?.fieldContainer}>
      <p className={`mb-1 ${classes?.fieldLabel}`}>
        Alcance <span className="font-semibold text-brand-secondary">{value} km</span>
      </p>
      <input
        type="range"
        min={config.modules.panel.gps.defaultDistanceMin}
        max={config.modules.panel.gps.defaultDistanceMax}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full h-3 bg-gray-200 rounded-full outline-none appearance-none"
      />
      <div className="flex justify-between">
        <span>{config.modules.panel.gps.defaultDistanceMin} km</span>
        <span>{config.modules.panel.gps.defaultDistanceMax} km</span>
      </div>
    </div>
  );
}

const RawInputGPSbyPlaces = ({ value, onChange, className, showSlider = true }) => {
  const [distance, setDistance] = useState(value?.distance || 5); // Valor inicial de distancia (en kilómetros)
  const [innerShowSlider, setInnerShowSlider] = useState(false); // Estado para mostrar u ocultar el slider

  const handlePlaceSelect = (place) => {
    onChange({ 
      distance,
      place_id: place?.place_id,
      lat: place?.geometry?.location?.lat(),
      lng: place?.geometry?.location?.lng(),
      formatted_address: place?.formatted_address,
      address_components: place?.address_components
    });
  };

  const handleDistanceChange = (event) => {
    setDistance(event.target.value);
    onChange({ 
      distance: event.target.value,
      place_id: value?.place_id,
      lat: value?.lat,
      lng: value?.lng,
      formatted_address: value?.formatted_address,
      address_components: value?.address_components
    });
  };

  const toggleSlider = () => {
    value?.place_id && setInnerShowSlider(!innerShowSlider);
  };

  return (
    <div className={`relative px-2 border-gray-200 bg-white h-[40px] border rounded-md ${className}`}>
      <div className="float-left flex items-center overflow-hidden">
        <FaMapMarkerAlt className="text-gray-700" />
        <Autocomplete
          key={value?.place_id}
          apiKey={config.googleMapApiKey}
          onPlaceSelected={handlePlaceSelect}
          types={['(regions)']}
          defaultValue={value?.formatted_address}
          inputAutocompleteValue={value?.formatted_address}
          className="w-full px-3 py-1.5 outline-none text-gray-700 overflow-x-hidden"
        />
      </div>
      {showSlider ? (
        <button
          className={`
            float-right block absolute top-0 right-0 focus:outline-none
            py-1 px-1.5 ml-2 justify-center bg-white rounded-md
            ${value?.place_id ? '' : 'opacity-0'}
          `}
          onClick={toggleSlider}
        >
          <div className="min-w-[70px] rounded-md mt-[1px] px-1 py-1.5 bg-gray-200 text-xs text-gray-700">
            {distance} km
          </div>
        </button>
      ) : null}
      <div className="clear-both"></div>
      {showSlider && innerShowSlider && value?.place_id && (
        <DistanceSlider 
          value={value?.distance}
          onChange={handleDistanceChange}
          classes={{ fieldContainer: 'absolute w-full top-full left-0 right-0 mt-2 bg-white border border-gray-200 rounded-md shadow-md px-4 py-2' }}
        />
      )}
    </div>
  );
};

export default RawInputGPSbyPlaces;
