import { Link } from "react-router-dom";

export default function TextGridWithTitleBlock({ data }) {
  return (
    <div className="px-4 pt-10 pb-20 bg-white text-center">
      <div className="container-width">
        <h3 className="pb-16 text-2xl md:text-4xl font-brand-secondary mb-4 font-bold">{data.title}</h3>
        <div className="flex flex-wrap md:grid md:grid-cols-3 gap-4 lg:gap-8 ">
          {data.items.map((item, index) => (
            <div key={index} className="font-brand-secondary">
              {item?.ctaType === 'link' ? (
                <Link to={item.ctaUrl}>
                  <h4 className="font-bold text-lg mb-2 underline">{item.title}</h4>
                </Link>
              ) : (
                <h4 className="font-bold text-lg mb-2">{item.title}</h4>
              )}
              <p>{item.content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
