import { Link } from "react-router-dom";
import ContactBTN from "../../../components/ContactBTN";
import { FaFacebook, FaWhatsapp, FaInstagram } from 'react-icons/fa';
import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import BtnSignUpModal from "../../../components/ui/BtnSignUpModal";
import { lineBreaks } from "../../../libs/utils";


export default function () {
  return {
    type: 'pageFooter',
    variation: 'linksFixedCTA',
    template: PageFooterALinksFixedCta,
    params: {
      showCTA: {
        type: 'boolean',
        defaults: true
      },
      showPoliticsLinks: {
        type: 'boolean',
        defaults: true
      },
      showMadeWithLove: {
        type: 'boolean',
        defaults: true
      },
    }
  };
}

export function PageFooterALinksFixedCta({ data, showCTA, showMadeWithLove, showPoliticsLinks, history }) {
  let classes = {
    ctaBtn: `
      text-brand-primary-contrast text-2xl p-3 lg:p-3 cursor-pointer rounded-full
      border border-brand-primary-contrast 
      hover:border-brand-secondary hover:scale-110 transition-all duration-300
    `
  }
  return (
    <footer className="pt-4 md:pt-8 bg-cover relative bg-brand-primary" style={{ backgroundImage: data.backgroundImageUrl ? `url(${data.backgroundImageUrl})` : '' }}>
      {/* overlay */}
      {data.backgroundImageUrl ? (
        <div className="absolute inset-0 bg-black opacity-30"></div>
      ) : null}

      {/* cta */}
      {data.linkWebsite ? (
        <div className="my-14 relative z-10 container-width text-center ">
          <AnimationAddClassWhenVisible classToAdd="animate-fadeIn">
            <a href={data.linkWebsite} className="text-white font-semibold text-3xl md:text-4xl cursor-pointer font-brand-mulish rounded-sm">
              {lineBreaks(data.linkWebsiteLabel) || data.linkWebsite}
            </a>
          </AnimationAddClassWhenVisible>
        </div>
      ) : null}
      
      {/* links */}
      <div className=""> 
        <div className="text-center text-brand-primary-contrast">
          {(data.socialLinkTitle || data.linkFacebook || data.number || data.linkInstagram) ? (
            <div className="my-8">
              {data.socialLinkTitle ? (
                <p className="text-lg">{lineBreaks(data.socialLinkTitle)}</p>
              ) : null}
              <div className="flex justify-center space-x-4 lg:space-x-6 mt-4">
                {data.linkFacebook ? (
                  <div className={classes.ctaBtn}>
                    <a target='_blank' href={data.linkFacebook} rel="noreferrer"><FaFacebook/></a>
                  </div>
                ) : null}
                {data.number && data.contactMessage ? (
                  <div className={classes.ctaBtn}>
                    <a target='_blank' href={`https://wa.me/${data.number}?text=${data.contactMessage}`} rel="noreferrer"><FaWhatsapp/></a>
                  </div>
                ) : null}
                {data.linkInstagram ? (
                  <div className={classes.ctaBtn}>
                    <a target='_blank' href={data.linkInstagram} rel="noreferrer"><FaInstagram/></a>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className="my-4">
            <p className="text-base ml-2 mt-0">{lineBreaks(data.locationString)}</p>
            {data.email ? (
              <a href={`mailto:${data.email}?subject=${data.contactMessage}`} target="_blank" rel="noopener noreferrer" className="block text-base ml-2 mt-0">
                {data.email}
              </a>
            ) : null}
            {data.number ? (
              <a href={`https://wa.me/${data.number}?text=${data.contactMessage}`} target="_blank" rel="noopener noreferrer" className="block text-base ml-2 mt-0">
                {data.numberString || data.number}
              </a>
            ) : null}
          </div>
        </div>

        {showMadeWithLove ? (
          <div className="flex justify-center text-brand-primary-contrast w-full px-10 my-12 lg:text-lg xl:text-xl">
            Hecho con
            <img src="/icons/heart.svg" alt="amor" className="beating mx-1"/>
            en Paraguay
          </div>
        ) : null}

        {showPoliticsLinks ? (
          <div className="text-left md:text-center py-4 md:py-7 pl-4 md:pl-0 bg-gray-900 text-white">
            <Link to="/m/page/politica-de-privacidad" className="underline block md:inline">Política de privacidad</Link>
            <span className="hidden md:inline"> | </span>
            <Link to="/m/page/seguridad-de-datos" className="underline block md:inline">Seguridad de datos</Link>
          </div>
        ) : null}
      </div>

      {/* fixed footer */}
      {showCTA ? (
        <div className="fixed bottom-4 right-4 md:right-6 min-w-40 pr-2 z-50 flex place-content-end">
          {data.parts?.ctaFloat.ctaType === 'whatsapp' ? (
            <ContactBTN className={'place-content-end'} label={data.floatCtaWhatsappLabel} message={data.contactMessage} />
          ) : null}
          {data.parts?.ctaFloat.ctaType === 'signup' ? (
            <BtnSignUpModal strings={data.parts.ctaFloat} history={history} design="large" />
          ) : null}
        </div>
      ) : null}
    </footer>
  );
}