import { useRef, useEffect, useState } from 'react';
import { CupertinoPane } from 'cupertino-pane';
import useOnResize from "../../libs/useOnResize";

export const usePanelSheet = (configGetter, effectListener = []) => {
  const windowHeight = useOnResize(() => window?.innerHeight);
  const panelRef = useRef(null);
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [paneInstance, setPaneInstance] = useState(null);

  useEffect(() => {
    let pane;
    if (!paneInstance && windowHeight) {
      let paneConfig = configGetter({ windowHeight });
      paneConfig.events = { ...paneConfig.events };
      pane = new CupertinoPane(panelRef.current, paneConfig);
      pane.device.ionic = false;
      setPaneInstance(pane);
    }
    return () => (pane || paneInstance)?.destroy();
  }, [paneInstance, windowHeight, ...effectListener]);

  const openPanel = () => {
    paneInstance?.present({ animate: true });
    setIsPaneOpen(true);
  };

  const closePanel = () => {
    paneInstance?.destroy({ animate: true });
    setIsPaneOpen(false);
  };

  return { panelRef, isPaneOpen, setIsPaneOpen, paneInstance, openPanel, closePanel };
};