import {
  IonBackButton,
  IonBreadcrumb,
  IonBreadcrumbs,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { 
  arrowBack
} from 'ionicons/icons';
import { UserList, UserMenu } from "./MenuOfUserSession";
import { useAuth } from "../user/AuthContext";
import BadgeLoading from "../../components/ui/BadgeLoading";
import { LayoutLogo } from "./LayoutLogo";
import { specDesign } from "../../customModules/specSite";
import { useLayoutHome } from "../layoutHome/useLayoutHome";
import { useEffect } from "react";
import { stackClasses } from "../../libs/utils";
import { useScrollUtils } from "./useScrollUtils";


export function LayoutAdmin({ 
  title,
  breadcrumbs,
  ToolbarLeft,
  ToolbarRight,
  ToolbarFull,
  defaultHref,
  forceBack = false,
  showGoBack = true,
  showMenu = true,
  showGoToProfile = true,
  showRefresher = true,
  mainSectionClass,
  history,
  className,
  contentScrollY = true,
  children
 }) {
  const { isLayoutSetted, layoutDesign, setLayoutDesign } = useLayoutHome();
  const { user } = useAuth();
  const { elementToScrollRef, handleScroll, resetScroll } = useScrollUtils();

  useEffect(() => {
    setLayoutDesign(specDesign);
  }, [specDesign]);
  
  const handleRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 400);
  };
  
  return (
    <IonPage id="admin">
      {!user?.userDoc?.data || !isLayoutSetted ? (
        <div className="pt-24 flex place-content-center content-center items-center font-brand-main">
          <BadgeLoading className="text-brand-primary" />
        </div>
      ) : (<>
        {/* header */}
        <IonHeader className="z-30" id="menu-header">
          <IonToolbar className="main-toolbar">
            <IonButtons slot="start">
              {showGoBack ? (
                forceBack ? (
                  <IonButton onClick={()=> { history.push(defaultHref); }} >
                    <IonIcon slot="icon-only" icon={arrowBack} />
                  </IonButton>
                ) : (
                  <IonBackButton defaultHref={defaultHref}></IonBackButton>
                )
              ) : null}
              {ToolbarLeft ? (
                <ToolbarLeft />
              ) : null}
              <div className="hidden lg:block ml-4">
                <LayoutLogo classes={stackClasses(layoutDesign?.logo, layoutDesign?.mainMenu)} />
              </div>
            </IonButtons>

            <div className="header-title-logo absolute !top-0 !left-0 pt-2.5 w-full items-center">
              <div className="lg:hidden">
                <LayoutLogo classes={stackClasses(layoutDesign?.logo, layoutDesign?.mainMenu)} />
              </div>
            </div>
            
            <IonButtons slot="end">
              {ToolbarRight ? (
                <ToolbarRight />
              ) : null}
              <UserMenu menuTrigger="user-menu" className="lg:hidden">
                <IonList>    
                  <UserList
                    menuTrigger="user-menu"
                    history={history}
                    triggerMode="button"
                    showCurrentUser={true}
                    showCurrentUserPic={false}
                    showGoToProfile={showGoToProfile}
                  />
                </IonList>
              </UserMenu>
              {showMenu ? (
                <IonMenuButton menu="main"></IonMenuButton>
              ) : null}
            </IonButtons>
          </IonToolbar>
          {ToolbarFull ? (
            <ToolbarFull />
          ) : null}
        </IonHeader>

        {/* content */}
        <IonContent
          className={className}
          scrollY={contentScrollY}
          ref={elementToScrollRef}
          scrollEvents={true}
          onIonScroll={handleScroll}
        >
          {showRefresher ? (
            <IonRefresher slot="fixed" pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={handleRefresh}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
          ) : null}

          {(title || breadcrumbs) ? (
            <>
            {breadcrumbs?.length ? (
              <IonBreadcrumbs>
                {breadcrumbs?.map(({ url, title }, index) => (
                  <IonBreadcrumb routerLink={url} routerDirection="back" key={index}>{title}</IonBreadcrumb>
                ))}
              </IonBreadcrumbs>
            ) : null}
            {title ? (
              <IonToolbar color="secondary">
                <IonTitle className="text-brand-secondary-contrast">{title}</IonTitle>
              </IonToolbar>
            ) : null}
            </>
          ) : null}

          {children}
          
        </IonContent>
      </>)}
    </IonPage>
  );
}