import { Field } from 'react-final-form';
import _ from 'lodash';
import { FieldLabel, ShowFieldHelp } from './formParts';
import dayjs from 'dayjs';
import { IonDatetime } from '@ionic/react';

const FormFieldDate = (props) => {
  let {
    name,
    title,
    placeholder,
    fieldsRequired = null,
    showLabel = true,
    showClearBtn = true,
    validate = null,
    disabled = false,
    formatter = (value) => value,
    minValue = null,
    maxValue = null,
    UnderInput = null,
    onChange = null,
    onBlur = null,
  } = props;

  const validateField = (value) => {
    let error;
    
    if (value !== undefined) {
      
    }
    
    if (!error && validate && typeof validate === 'function') {
      error = validate(value);
    }
    
    return error;
  };

  
  return (
    <Field name={name} validate={(args) => validateField(args)}
      className={`pb-3 ${props?.classes?.fieldContainer || ''}`}
    >
      {({ input, meta }) => {
        const dateValue = dayjs(input.value || new Date()).utc().format('YYYY-MM-DD');
        return (
          <>
            <FieldLabel {...{...props, input, meta}} />
            <div className="p-0.5 border border-gray-200 rounded-md relative z-0">
              <IonDatetime
                presentation="date"
                className="mx-auto z-0"
                value={dateValue}
                onIonChange={e => input.onChange(e.target.value?.split('T')[0] )}
              >
              </IonDatetime>
            </div>
            {UnderInput && (<UnderInput input={input} />)}
            {/* error */}
            <ShowFieldHelp name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} {...props} />
          </>
        );
      }}
    </Field>
  );
};

export default FormFieldDate;