import { Link } from 'react-router-dom';
import { stringsLayout } from '../specSite';
import { PageHeaderHeroStatic } from '../../modules/blockStack/blocks/BlockPageHeaderAHero';
import { PageFooterALinksFixedCta } from '../../modules/blockStack/blocks/BlockPageFooterALinksFixedCta';
import { LayoutHome } from '../../modules/layoutHome/LayoutHome';


export function Page401({ history }) {
  return (
    <LayoutHome metaTitle="404">
      <PageHeaderHeroStatic data={stringsLayout.headerHero} history={history} />

      <div className="my-24 max-w-lg mx-auto px-10">
        {/* path */}
        <div className="flex mb-5">
          <Link to={'/'}><h2 className="text font-thin mb-4 mr-3">Panel</h2></Link>
          <h2 className="text font-semibold mb-4">401</h2>
        </div>

        <div className="mb-5 text-2xl text-bold">
          No posee autorización
        </div>

        <Link to={'/'} className="bg-zinc-200 hover:bg-zinc-300 rounded py-1.5 px-2 text-black text-xs xsm:text-sm">
          Volver
        </Link>
      </div>

      <PageFooterALinksFixedCta data={stringsLayout.footerSection} showCTA={true} showMadeWithLove={true} />
    </LayoutHome>
  );
}

export function Page404({ history }) {
  return (
    <LayoutHome metaTitle="404">
      <PageHeaderHeroStatic data={stringsLayout.header} history={history} />

      <div className="my-24 max-w-lg mx-auto px-10">
        {/* path */}
        <div className="flex mb-5">
          <Link to={'/'}><h2 className="text font-thin mb-4 mr-3">Panel</h2></Link>
          <h2 className="text font-semibold mb-4">404</h2>
        </div>

        <div className="mb-5 text-2xl text-bold">
          La página no existe
        </div>

        <Link to={'/'} className="bg-zinc-200 hover:bg-zinc-300 rounded py-1.5 px-2 text-black text-xs xsm:text-sm">
          Volver
        </Link>
      </div>

      <PageFooterALinksFixedCta data={stringsLayout.footer} showCTA={true} showMadeWithLove={true} />
    </LayoutHome>
  );
}
