import _ from "lodash";
import { routePageStack } from "../layout/RouteLayoutBlock";
import config from "../../config";
import { specDesign, stringsLayout } from "../specSite";
import CtaContactUsBlock from "./Blocks/CtaContactUs";
import CtaImgFullBlock from "./Blocks/CtaImgFull";


const entitySlug = config.modules.cart.cartItemVariantsEntitySlug;

const specStack = [
  {
    type: 'productView',
    variation: 'expanded',
    // strings: blocks.headerHero,
    params: {
      filterPath: "/m/products/filter",
      viewPath: "/m/products/view",
      cartPath: "/m/carts/view",
      imgProportion: 'vertical-wide',
      classes: { 
        blockContainer: 'py-16 lg:py-20'
      }
    }
  },
  {
    strings: stringsLayout.contactCta,
    template: CtaContactUsBlock
  },
  {
    strings: stringsLayout.footer,
    template: CtaImgFullBlock
  },
];

export const RouteProductView = routePageStack({ specDesign, specStack });