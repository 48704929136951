import _ from 'lodash';
import { setConfig } from "../../config";
import { cssToRgb } from "../../libs/utils";


const colorGroups = ['primary', 'secondary', 'tertiary', 'success', 'warning', 'danger', 'dark', 'medium', 'light'];
const colorModes = ['constrast, shade', 'tint'];

export default function applyLayoutDesign ({
  siteIdentity,
  logo,
  fonts,
  colors
}) {
  /////////////////////////////////////////
  // PAGE
  /////////////////////////////////////////
  if (siteIdentity) {
    siteIdentity.pageTitle = siteIdentity.pageTitle || siteIdentity.siteName + ' | ' + siteIdentity.siteMoto;
    setConfig(siteIdentity);
    // Acceso al objeto siteIdentity y sus propiedades
    document.title = siteIdentity.pageTitle;
    // Actualizar el meta tag name y content
    const metaTag = document.querySelector('meta[name="description"]');
    metaTag.setAttribute('content', siteIdentity.pageTitle);
    // Actualizar el meta tag property og:title
    const metaTagOgTitle = document.querySelector('meta[property="og:title"]');
    metaTagOgTitle.setAttribute('content', siteIdentity.pageTitle);
  }

  /////////////////////////////////////////
  // LOGO
  /////////////////////////////////////////
  if (logo) {
    // Actualizar el meta tag property og:image
    const metaOgImage = document.querySelector('meta[property="og:image"]');
    metaOgImage.setAttribute('content', logo.logoDark || logo.logoLight);
  }

  /////////////////////////////////////////
  // FONTS
  /////////////////////////////////////////
  if (fonts) {
    // Actualizar el style de google font
    let fontsLink = fonts.fontsLink || '';
    let fontVariants = ':ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900';
    if (!fontsLink && fonts.mainFont) {
      let families = 'family=' + fonts.mainFont.replace(' ', '+') + fontVariants;
      if (fonts.secondaryFont) {
        families += '&family=' + fonts.secondaryFont.replace(' ', '+') + fontVariants;
      }
      fontsLink = `https://fonts.googleapis.com/css2?${families}&display=swap`;
    }
    if (fontsLink) {
      const linkFont = document.querySelector('link[resource="google-font"]');
      linkFont.setAttribute('href', fontsLink);
    }
    document.documentElement.style.setProperty('--main-font', '"' + fonts.mainFont + '", ' + fonts.mainFontAlt);
    document.documentElement.style.setProperty('--secondary-font', '"' + fonts.secondaryFont + '", ' + fonts.secondaryFontAlt);
  }

  /////////////////////////////////////////
  // COLORS
  /////////////////////////////////////////
  if (colors) {
    // add rgb modes
    colorGroups.forEach((group) => {
      if (colors[group]) {
        colors[group+'-rgb'] = cssToRgb(colors[group]);
      }
      if (colors[group+'-contrast']) {
        colors[group+'-contrast-rgb'] = cssToRgb(colors[group+'-contrast']);
      }
    });
    // set colors
    _.forEach(colors, (colorCode, colorName) => {
      document.documentElement.style.setProperty('--ion-color-' + colorName, colors[colorName]);
    });
  }
}