import { Field } from 'react-final-form';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { required, RequiredByFieldsList, ShowFieldHelp } from './formParts';
import SwitchInputA from './SwitchInputA';
import SwitchInputB from './SwitchInputB';


const FormFieldCheckboxToggle = (props) => {
  let {
    name,
    title,
    textTrue,
    textFalse,
    color,
    colorTrue="blue-700",
    colorFalse="gray-300",
    className="",
    fieldsRequired=null,
    validate=null,
    disabled=false,
    formatter=null,
    showLabel=true,
    switchType="b" // "a"
  } = props;

  if (!formatter) {
    formatter = (value) => value
  }

  return (
    <div className={`pb-3 ${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={(args) => validate && validate(args)}>
        {({ input, meta }) => (<>
          <>
            {showLabel ? (
              <div className={props?.classes?.fieldLabel}>
                <span className={`mr-3 ${meta.touched && meta.error ? 'text-red-600' : ''}`}>
                  {title}{(meta.touched && meta.error || (_.includes(fieldsRequired, name) && !input.value)) ? '*' : ''}
                </span>
              </div>
            ) : ''}

            <div className={props?.classes?.fieldInput || ''}>
              {switchType === 'a' ? (
                <SwitchInputA 
                  value={formatter(input.value) ? true : false} 
                  onChange={input.onChange}
                  {...{colorTrue: color, textTrue, textFalse, disabled}}>
                </SwitchInputA>
              ) : ''}

              {switchType === 'b' ? (
                <SwitchInputB 
                  value={formatter(input.value) ? true : false} 
                  onChange={input.onChange}
                  {...{
                    colorTrue: colorTrue || color,
                    colorFalse,
                    textTrue,
                    textFalse,
                    disabled
                  }}>
                </SwitchInputB>
              ) : ''}
            </div>           

            {/* error */}
            <ShowFieldHelp name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} {...props} />
          </>
        </>)}
      </Field>
    </div>
  );
}

export default FormFieldCheckboxToggle;