import { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import _ from 'lodash';
import SwitchInputB from './SwitchInputB';
import { FieldLabel, ShowFieldHelp } from './formParts';

const SelectSwitch = (props) => {
  let {
    mode = 'string', // input.value mode string || array
    input, 
    meta,
    options,
    multiple = true,
    disabled = false,
  } = props;

  const getValue = () => {
    if (mode === 'string') {
      if (input.value.length > 0) {
        return _.compact( input.value.split(',') );
      }
      return '';
    }
    return input.value || [];
  }

  const setValue = (inputValue) => {
    if (mode === 'string') {
      if (inputValue.length > 0) {
        inputValue = _.compact( inputValue ).join(',');
        input.onChange(inputValue);
      } else {
        input.onChange(null);
      }
    }
    input.onChange(inputValue?.length ? inputValue : null);
  }

  const [selectedOptions, setSelectedOptions] = useState(getValue());

  useEffect(() => {
    if (!input.value) {
      setSelectedOptions(null);
      setValue(null);
    }
  }, [input.value]);

  const handleOptionChange = (optionValue) => {
    if (multiple) {
      let updatedOptions;
      if (selectedOptions?.includes(optionValue)) {
        updatedOptions = selectedOptions.filter((value) => value !== optionValue);
      } else {
        updatedOptions = [...(selectedOptions || []), optionValue];
      }
      updatedOptions = _.compact(updatedOptions);
      if (_.size(updatedOptions)) {
        setSelectedOptions(updatedOptions);
        setValue(updatedOptions);
      } else {
        setSelectedOptions(null);
        setValue(null);
      }
    } 
    // individual
    else {
      if (optionValue === selectedOptions) {
        setSelectedOptions(null);
        setValue(null);
      } else {
        setSelectedOptions(optionValue);
        setValue(optionValue);
      }
    }
  };  

  return (
    <div className={`flex gap-4 ${props?.classes?.fieldInput}`}>
      {options && options.map((option) => (
        <SwitchInputB
          key={option.value}
          value={multiple ? selectedOptions?.includes(option.value) : selectedOptions === option.value}
          onChange={() => handleOptionChange(option.value)}
          textTrue={option.label}
          textFalse={option.label}
          colorTrue="blue-700"
          disabled={disabled}
        />
      ))}
    </div>
  );
}

const FormFieldMultiSelectSwitch = (props) => {
  const {
    name,
    title,
    fieldsRequired = null,
    validate = null,
    disabled = false,
    // multiple = true,
    showLabel = true,
    onSelect = (() => null),
  } = props;
  
  return (
    <div className={`pb-3 ${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={validate}>
        {({ input, meta }) => {
          return (
            <>
              <FieldLabel {...{...props, input, meta}} />              
              <SelectSwitch {...props} {...{input, meta}} mode='array' />
              <ShowFieldHelp name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} {...props} />
            </>
          );
        }}
      </Field>
    </div>
  );
};

export default FormFieldMultiSelectSwitch;