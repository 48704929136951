import { useEffect, useState } from 'react';
import { LayoutAdmin } from "../modules/panel/LayoutAdmin";
import SectionCrudForm from "../components/SectionCrudForm";
import Model, { useStateSingleResult } from "../libs/ModelClass";
import useEntityTaxonomyForm from '../components/EntityTaxonomyForm';
import PartOfModule from '../components/Module/PartOfModule';
import { useModule } from '../context/ModuleContext';
import { IonToolbar } from '@ionic/react';
import toast from 'react-hot-toast';

export function Content({ entity, history, match }) {
  const entitySlug = entity?.data?.nameSlug;
  const ExtendedModel = Model.extend(entitySlug);
  const { docId } = match.params;
  const { fireEventReducer, isAllowed } = useModule();
  const doc = useStateSingleResult({
    Model: ExtendedModel, 
    id: docId
  });
  
  const [fieldsRequired, setFieldsRequired] = useState([]);
  const { taxonomyTypesDocList, EntityTaxonomyForm } = useEntityTaxonomyForm({ 
    entityId: entity?.id,
    entitySlug,
    fieldsRequired, 
    setFieldsRequired
  });

  const [ firedAfterRead, setFiredAfterRead ] = useState(false);
  const [ parsedDoc, setParsedDoc ] = useState();
  useEffect(() => {
    fireAfterRead();
  }, [doc, taxonomyTypesDocList]);

  const fireAfterRead = async () => {
    if (!firedAfterRead && taxonomyTypesDocList?.length) {
      const modifiedDocData = await fireEventReducer('afterRead', { doc, entitySlug, taxonomyTypesDocList }, doc.data);  
      doc.data = modifiedDocData;
      setParsedDoc(doc);
      setFiredAfterRead(true);
    }
  }

  const handleSave = async (formValues) => {
    try {
      const modifiedFormValues = await fireEventReducer('beforeSave', { entitySlug, taxonomyTypesDocList }, formValues);
      const savedItem = await ExtendedModel.createOrUpdate(modifiedFormValues);
      await fireEventReducer('afterSave', { entitySlug, taxonomyTypesDocList }, savedItem);
      if (docId === 'new') {
        // force save again to ensure that the id is added as a field
        await savedItem.save();
      }
      history.push(`/a/entity/${entity?.data?.nameSlug}/${savedItem.id}`);
    } catch (error) {
      console.error(error);
      toast.error(error?.message || error?.code);
    }
  };

  const onValidation = (values, errors) => {
    // all required fields
    fieldsRequired.forEach(field => {
      if (!values[field]) {
        errors[field] = ' '; 
      }
    });
    // [TODO] realiar validación de taxonomías segú tipo y param
  };

  const FormInputFields = (({ values }) => (<>
    <EntityTaxonomyForm
      values={values}
      classes={{
        renderListContainer: 'space-y-8',
        renderItemContainer: '',
        fieldContainer: 'flex-grow',
        fieldLabel: 'block font-bold text-xs text-black uppercase mb-1 flex flex-row place-content-between items-center h-6',
        fieldInput: 'block w-full'
      }}
      overrideParams={{
        showLabel: true,
        showClearBtn: true
      }}
    />
    <PartOfModule
      type="crud"
      action="FormAppend"
      entitySlug={entitySlug}
      param={{ values }} 
    />
  </>));

  return (
    <>
      {/* Entity Form */}
      {parsedDoc ? (<>
        <div className="p-3 rounded-md border border-gray-200 shadow-md hover:shadow-lg">
          <SectionCrudForm
            doc={parsedDoc}
            onSave={handleSave}
            onValidation={onValidation}
            fieldsRequired={fieldsRequired}
            FormInputFields={FormInputFields}
            editStyle="route"
            showTitle={false}
          />
        </div>
        <PartOfModule
          type="crud"
          action="UnderForm"
          entitySlug={entitySlug}
          param={{ doc: parsedDoc, entitySlug, taxonomyTypesDocList, isAllowed }} 
        />
      </>) : null}
    </>
  );
}

export function AdminEntityCrudForm(props) {
  const { entitySlug, docId } = props.match.params;
  const entity = useStateSingleResult({
    Model: Model.extend('entities'), 
    nameSlug: entitySlug
  });
  let breadcrumbs = [{
    url: '/a/entity',
    title: 'Listados'
  }];
  if (entity?.data?.showInMenu) {
    breadcrumbs.push({
      url: `/a/entity/${entitySlug}`,
      title: entity?.data?.name
    });
  }
  breadcrumbs.push({
    title: "Formulario"
  });

  return (
    <LayoutAdmin 
      history={props.history} 
      defaultHref={entity?.data?.showInMenu ? `/a/entity/${entitySlug}` : `/a/entity`}
      title={docId === 'new' ? 'Agregar' : 'Editar'}
      breadcrumbs={breadcrumbs}
    >
      <div className="ion-padding">
        {entity && <Content entity={entity} {...props} />}
      </div>
    </LayoutAdmin>
  );
}
