import { processRenderProps } from './dataTypeUtils';
import {
  FormFieldSelect,
  FormFieldListItems,
  FormFieldSelectButtons,
  FormFieldSelectButtonsWithColors,
} from '../Form';
import {
  // Select
  FilterRenderSelect,
  FilterCreatorSelect,
} from '../Filters';


export default {
  Render(props) {
    const {
      onChange,
      fieldsRequired
    } = props;
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);
    
    if(design === "select" || design === "default") {
      return(
        <FormFieldSelect 
          name={_fieldName}
          title={title}
          placeholder={title}
          onSelect={onChange}
          fieldsRequired={fieldsRequired}
          {...param}
          {...style}
          {...props}
        />
      )
    }

    else if (design === "buttons") {
      return (
        <FormFieldSelectButtons 
          name={_fieldName}
          title={title}
          onSelect={onChange}
          fieldsRequired={fieldsRequired}
          {...param}
          {...style}
          {...props}
        />
      )
    }

    else if (design === "buttonsColors") {
      return (
        <FormFieldSelectButtonsWithColors
          name={_fieldName}
          title={title}
          fieldsRequired={fieldsRequired}
          {...param}
          {...style}
          {...props}
        />
      )
    }
    
    return null;
  },

  RenderFilter(props) {
    return <FilterRenderSelect {...props} DataTypeRender={this.Render} />
  },

  RenderFilterParam(props) {
    return <FilterCreatorSelect {...props} />
  },

  RenderInputParams({ values, param }) {
    return <FormFieldListItems
      name="param.options"
      title="Opciones"
      placeholder="Opciones"
      typesToShow={{ color: true, label: true, value: true }}
    />
  },
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (<>
    <FormFieldSelect
      name="show.select.operator"
      title="Operador"
      placeholder="Operador"
      options={[
        { value: 'eq', label: 'es igual' },
      ]}
    />
    <FormFieldSelect
      name="show.select.values"
      title="Opciones"
      placeholder="Opciones"
      options={taxonomyToWatch?.data?.param?.options}
    />
  </>),
  
  RenderShowParams: ({ values }) => (
    <FormFieldSelect
      name="style.design"
      title="Diseño"
      placeholder="Diseño"
      options={[
        { value: 'select', label: 'Select' },
        { value: 'buttons', label: 'Buttons' },
        { value: 'buttonsColors', label: 'Buttons colored' }
      ]}
    />
  ),
  
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => {
    if (values?.style?.design === 'select') {
      return (<FormFieldSelect {...formFieldProps} />);
    }
    if (values?.style?.design === 'buttons') {
      return (<FormFieldSelectButtons {...formFieldProps} />);
    }
    if (values?.style?.design === 'buttonsColors') {
      return (<FormFieldSelectButtonsWithColors {...formFieldProps} />);
    }
    return '';
  },

  getDefaultTaxonomyTypeDesign: () => ('select')
}