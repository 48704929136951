import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import _ from 'lodash';
import { Capacitor } from '@capacitor/core';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';


const usePhoto = () => {
  const openPhotoOptions = async () => {
    const actionSheet = await ActionSheet.showActions({
      title: 'Seleccionar Foto',
      message: 'Seleccione de donde obtener la imágen',
      options: [
        {
          title: 'Cámara'
        },
        {
          title: 'Galería'
        },
        {
          title: 'Cancelar',
          style: ActionSheetButtonStyle.Cancel,
        },
      ],
    });
    if (actionSheet.index === 0) {
      return await takePhoto();
    }
    else if (actionSheet.index === 1) {
      return await chooseFromGallery();
    }
    else if (actionSheet.index === 2) {
      return null;
    }
  };

  const takePhoto = async () => {
    if (!Capacitor.isNativePlatform()) {
      return null;
    }
    try {
      const hasPermission = await checkCameraPermissions();
      if (hasPermission) {
        const capturedPhoto = await Camera.getPhoto({
          resultType: CameraResultType.Uri,
          source: CameraSource.Camera,
          quality: 100,
        });
        return capturedPhoto;
      } else {
        throw new Error('Camera permission denied');
      }
    } catch (error) {
      throw new Error('Error capturing photo');
    }
  };

  const chooseFromGallery = async () => {
    try {
      const galleryPhotos = await Camera.pickImages({
        quality: 100
      });
      return galleryPhotos;
    } catch (error) {
      console.error('Error choosing from gallery:', error);
    }
  };

  const checkCameraPermissions = async () => {
    try {
      const permission = await Camera.checkPermissions();
      if (permission.camera && permission.camera === 'granted') {
        return true;
      }
      const requestPermission = await Camera.requestPermissions();
      if (requestPermission.camera && requestPermission.camera === 'granted') {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const convertCameraPhotoToFile = async (photo) => {
    const response = await fetch(photo.webPath);
    const blob = await response.blob();
    const format = photo?.format?.split('/')[1] || photo?.format;
    const file = new File([blob], `photo.${format}`, { type: `image/${format}` });
    return file;
  };

  return {
    openPhotoOptions,
    takePhoto,
    chooseFromGallery,
    convertCameraPhotoToFile
  };
};

export default usePhoto;