import React from 'react';
import { Field } from 'react-final-form';
import RawInputGallery from './RawInputGallery';
import { required, ShowFieldHelp } from './formParts';
import config from '../../config';

const FormFieldGallery = (props) => {
  let {
    name,
    title,
    required: isRequired = false,
    showLabel = true,
  } = props;
  const validate = isRequired ? required : undefined;

  return (
    <Field
    name={name}
    validate={validate}>
      {({ input, meta }) => (
        <div className={`${props?.classes?.fieldContainer || ''}`}>
          {showLabel && name ? (
            <label className={props?.classes?.fieldLabel}>
              <span className={`mr-3 ${meta.touched && meta.error ? 'text-red-600' : ''}`}>
                {title}
                {isRequired ? '*' : ''}
              </span>
            </label>
          ) : ''}

          <div className="mt-2">
            <RawInputGallery
              value={input.value}
              onChange={input.onChange}
              {...props}
            />
          </div>
          {/* Render error if any */}
          {meta.touched && meta.error && <div className="text-red-600" style={{ fontSize: 12 }}>{meta.error}</div>}
          {/* Error */}
          <ShowFieldHelp name={name} input={input} meta={meta} required={isRequired} {...props} />
        </div>
      )}
    </Field>
  );
};

export default FormFieldGallery;
