import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import _ from 'lodash';
import { compareVersions } from 'compare-versions';
import entities from './entities.json';
import { AdminHomeContent } from './AdminTabHome';
// import { saveCountriesData, saveStatesData, saveCitiesData } from "../i18n/data/saveData";
// import BtnLoading from "../../components/ui/BtnLoading";

import {
  desktopOutline,
  infiniteOutline,
  cogOutline,
  fileTrayStackedOutline
} from 'ionicons/icons';

// let { systemVersions } = entities;

export const getMenuPages = ({ menuName, user }) => {
  const pages =  [
    {
      title: 'Panel',
      url: '/a/panel',
      ionIcon: desktopOutline,
      permissions: { resource: 'panel', actions: ['tab:panel'] }
    },
    {
      title: 'Listados',
      url: '/a/entity',
      ionIcon: fileTrayStackedOutline,
      permissions: { resource: 'panel', actions: ['tab:lists'] }
    },
    {
      title: 'Configuraciones',
      url: '/a/config',
      ionIcon: cogOutline,
      permissions: { resource: 'panel', actions: ['tab:config'] }
    }
  ];

  return pages;
};

export const sortDocsVersions = (docs) => {
  return docs.sort((a, b) => {
    return compareVersions(a?.data?.versionCode || '0', b?.data?.versionCode || '0', '>') === 1 ? 1 : -1;
  })
  .reverse();
};

const AdminConfigAfterContent = ({ isAllowed }) => (<>
  {isAllowed('systemVersions', ['list']) ? (
    <IonItem routerLink="/a/entity/lCzJQLdmfUBGMEnyj9SR">
      <IonIcon icon={infiniteOutline}></IonIcon>
      <IonLabel className="ml-2">Versiones del sistema</IonLabel>
    </IonItem>
  ) : null}

  {/* IMPORT i18n DATA */}
  {/* <div className="ion-padding space-y-4">
    <BtnLoading 
      label="Save i18n countries data"
      onClickAsync={saveCountriesData} 
      className="px-1 py-1 rounded bg-gray-200 !text-black"
    >
    </BtnLoading>
    <BtnLoading 
      label="Save i18n states data"
      onClickAsync={saveStatesData} 
      className="px-1 py-1 rounded bg-gray-200 !text-black"
    >
    </BtnLoading>
    <BtnLoading 
      label="Save i18n cities data"
      onClickAsync={saveCitiesData} 
      className="px-1 py-1 rounded bg-gray-200 !text-black"
    >
    </BtnLoading>
  </div> */}
</>);

const UnderListItemTitleForSystemVersions = ({ ViewData }) => (<>
  <ViewData field="announcedBranchs" classes={{ fieldContainer: 'text-sm text-gray-800' }} />
  <ViewData field="upgradeLevel" classes={{ fieldContainer: 'text-xs text-gray-800' }} />
</>);

export default function ({ setPermissions }) {
  return {
    slug: 'panel',
    label: 'Panel del sistema',
    entities: {
      // systemVersions
    },
    permissions: [
      setPermissions({ slug: 'panel', label: 'Panel', type: 'system', actions: ['tab:panel', 'tab:lists', 'tab:config'] }),
      // setPermissions({ slug: 'systemVersions', label: 'Versiones del sistema', type: 'system' })
    ],
    components: {
      main: {
        // AdminHomeBeforeContent,
        AdminHomeContent
        // AdminConfigAfterContent
      },
      // 'systemVersions': {
      //   crud: {
      //     settings: () => ({
      //       addButtonLabel: 'Anunciar nueva versión',
      //       sortDocs: sortDocsVersions
      //     }), 
      //     UnderListItemTitle: UnderListItemTitleForSystemVersions
      //   }
      // },
    },
  };
};