import { openWhatsApp, stackClasses } from "../libs/utils";
import { getConfig } from "../config";
import { addLogEvent } from '../firebase';
import { FaWhatsapp } from 'react-icons/fa';
import { useState } from "react";


export default function ContactBTN (props) {
  const [isHovered, setIsHovered] = useState(false);
  const config = getConfig();
  let {
    className,
    number = config.number, 
    message = config.whatsAppTextBTN(),
    label = 'Whatsapp',
    afterSend = () => {},
    design
  } = props;
  const openWA = () => {
    addLogEvent('contact_btn'); // analytics
    openWhatsApp({ 
      number, 
      message
    });
    afterSend();
  }

  let classes = {
    btnContainer: 'h-12 md:h-14',
    iconContainer: 'ml-1.5 w-12 md:w-14 h-12 md:h-14',
    icon: 'p-2 w-12 h-12 md:w-14 md:h-14',
    label: 'text-lg'
  };
  if (design === 'small') {
    classes = {
      btnContainer: 'h-8 md:h-10',
      iconContainer: 'ml-2 w-10 md:w-10 h-8 md:h-10',
      icon: 'p-2 py-2.5 w-10 h-10 md:w-10 md:h-10',
      label: 'text-base'
    };
  }
  classes = stackClasses(classes, props.classes);

  classes.hoverColors = '!bg-brand-tertiary !text-brand-primary-contrast';

  /////// design A
  return (<>
    <button 
      onClick={() => openWA()} type="button" 
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      className={`
        bg-brand-primary text-brand-primary-contrast shadow-black/20
        hover:scale-105 transition-all duration-200
        shadow-md cursor-pointer rounded-lg flex items-center 
        ${isHovered ? classes.hoverColors : ''}
        ${classes.btnContainer} ${className}
      `}
    >
      <div className={`
        flex place-content-center items-center ${classes.iconContainer}
      `}>
        <FaWhatsapp className={`fill-current ${classes.icon}`} />
      </div>
      <div className={`pr-4 ${classes.label}`}>
        {label}
      </div>
    </button>
  </>)

  /////// design B
  // return (<>
  //   <button 
  //     onClick={() => openWA()} type="button" 
  //     onMouseEnter={() => {
  //       setIsHovered(true);
  //     }}
  //     onMouseLeave={() => {
  //       setIsHovered(false);
  //     }}
  //     className={`
  //       bg-brand-secondary shadow-black/20 text-brand-secondary-contrast 
  //       hover:scale-105 transition-all duration-200
  //       shadow-md cursor-pointer rounded-full flex items-center 
  //       ${isHovered ? classes.hoverColors : ''}
  //       ${classes.btnContainer} ${className}
  //     `}
  //   >
  //     <div className={`
  //       bg-brand-primary text-brand-primary-contrast
  //       transition-all duration-200 font-bold flex place-content-center items-center rounded-full 
  //       ${isHovered ? classes.hoverColors : ''} ${classes.iconContainer}
  //     `}>
  //       <FaWhatsapp className={`fill-current ${classes.icon}`} />
  //     </div>
  //     <div className={`pl-2.5 pr-4 ${classes.label}`}>
  //       {label}
  //     </div>
  //   </button>
  // </>)
}