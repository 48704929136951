import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import _ from 'lodash';
import { FieldLabel, ShowFieldHelp } from './formParts';
import { onChangeButtonsHandler } from '../DataTypes/multiselect';


export const SelectButtons = (props) => {
  let {
    options,
    current,
    onSelect,
    className,
    itemClassName,
    trueClassName,
    falseClassName,
    hiddenClassName
  } = props;

  return (
    <div className={`flex flex-row flex-wrap gap-4 justify-center ${className}`}>
      {options && options.map((option) => (
        <button
          type='button'
          onClick={() => !option.hidden && onSelect(option.value, option)}
          key={option.value}
          className={`
            ${itemClassName}
            ${(option.value === current && !option.hidden) ? trueClassName : (option.hidden ? hiddenClassName : falseClassName)}
          `}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export const FieldViewSelectButtons = (props) => {
  let {
    value,
    taxonomyType,
    showLabel = true,
    classes,
    parts
  } = props;

  const { name, param } = taxonomyType?.data;
  const options = param.options;
  const multiple = _.isArray(value) || (_.isObject(value) && _.size(value));

  const isSelected = (option) => {
    return multiple
            ? _.includes(value, option.value) // array
            // ? value[option.value] // true map
            : value === option.value;
  }

  return (
    <div className={`${classes?.fieldViewContainer || ''}`}>
      {showLabel && name ? (
        <label className={classes?.fieldViewLabel}>
          <span className={`font-semibold mr-3`}>
            {name}
          </span>
        </label>
      ) : ''}

      <div className={`flex flex-row flex-wrap gap-2`}>
        {parts?.beforeItems ? (
          parts.beforeItems()
        ) : null}
        {options && options.map((option) => (
          <div
            key={option.value}
            className={`
              flex flex-row content-center gap-1
              ${isSelected(option) ? classes?.fieldViewSelectedOption : classes?.fieldViewUnselectedOption}
            `}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
};


const FormFieldSelectButtons = (props) => {
  let {
    name,
    title,
    options,
    fieldsRequired = null,
    validate = null,
    disabled = false,
    // showLabel = true,
    // showClearBtn = true,
    onSelect = (() => null),
    multiple = false,
    classes
  } = props;

  const validateField = (value) => {
    let error;

    if (fieldsRequired?.includes(name)) {
      if (multiple) {
        if (!value || value.length === 0) {
          error = 'Debes seleccionar al menos una opción';
        }
      } else {
        if (value === undefined || value === '') {
          error = 'Este campo es obligatorio';
        }
      }
    }

    if (!error && validate && typeof validate === 'function') {
      error = validate(value);
    }

    return error;
  };

  return (
    <div className={`pb-3 ${classes?.fieldContainer || ''}`}>
      <Field name={name} validate={(args) => validateField(args)}>
        {({ input, meta }) => {
          const onChange = onChangeButtonsHandler(input, multiple, onSelect);

          return (
            <>
              <FieldLabel {...{...props, input, meta}} />
              <div className={classes?.fieldInput || ''}>
                <div className={`flex flex-row flex-wrap gap-2`}>
                  {options && options.map((option) => (
                    <button
                      type="button"
                      key={option.value}
                      onClick={() => onChange(option.value)}
                      className={`p-1.5 px-3 text-xs lg:text-sm inline-block rounded-md cursor-pointer ${
                        multiple
                          ? _.includes(input?.value, option.value) // array
                          // ? input?.value[option.value] // true map
                            ? 'bg-brand-secondary text-white shadow-lg'
                            : 'bg-gray-100 shadow-sm hover:scale-[1.05]'
                          : input.value === option.value
                            ? 'bg-brand-secondary text-white shadow-lg'
                            : 'bg-gray-100 shadow-sm hover:scale-[1.05]'
                      }`}
                    >
                      {option.label}                      
                    </button>
                  ))}
                </div>
              </div>
              {/* help */}
              <ShowFieldHelp name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} {...props} />
            </>
          );
        }}
      </Field>
    </div>
  );
};

export default FormFieldSelectButtons;
