import { IonButton } from "@ionic/react";
import { useState } from "react";


export default function IonBtnLoading({ 
  label,
  icon,
  onClick,
  onClickAsync,
  className,
  classNameDisabled,
  color,
  size,
  fill,
  disabled = false,
  transitions = true,
  onClickDisabled
 }) {
  const [loading, setLoading] = useState(false);

  const onClickWrapper = async () => {
    if (disabled) {
      onClickDisabled && onClickDisabled();
      return;
    }
    if (onClick) {
      onClick();
    } 
    else if (!loading) {
       setLoading(true);
       await onClickAsync();
       setLoading(false);
     }
  };

  return (
    <IonButton
    {...{
      color, size, fill
    }}
    className={`
      ${transitions ? 'hover:scale-105 transition duration-400 ease-in-out' : ''}
      ${ disabled ? (classNameDisabled || className) : className || ''}
    `}
    disabled={disabled || loading}
    onClick={onClickWrapper}>
      {(loading || icon) ? (
        <div className="w-6 text-center">
          {loading ? (
            <svg className={`animate-spin ${size === 'small' ? 'h-5 w-5' : ''} ${size === 'medium' ? 'h-7 w-7' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : icon}
        </div>
      ) : null}
      {label || null}
    </IonButton>
  );
}