import _ from 'lodash';
import { processRenderProps } from './dataTypeUtils';
import {
  FormField,
  FormFieldSelect,
  FormFieldListItems,
  FormFieldMultiSelectSwitch,
  FormFieldSelectButtons,
  FormFieldSelectButtonsWithColors
} from '../Form';
import {
  // Select
  FilterRenderMultiSelect,
  FilterCreatorMultiSelect,
} from '../Filters';
import { arrayToTrueMap } from '../../libs/utils';


export const onChangeButtonsHandler = (input, multiple, onSelect) => (value) => {
  if (multiple) {
    //// Array
    const selectedValues = [ ...input?.value ];
    // Si es selección múltiple, gestionar un array de valores
    if (_.includes(selectedValues, value)) {
      // Remove the value if it's already selected
      const index = selectedValues.indexOf(value);
      selectedValues.splice(index, 1);
    } else {
      // Add the value if it's not already selected
      selectedValues.push(value);
    }
    
    //// True map
    //// Si es selección múltiple, gestionar un mapa de valores true
    // const selectedValues = { ...input?.value };
    // if (selectedValues[value]) {
    //   // Remove the value if it's already selected
    //   Reflect.deleteProperty(selectedValues, value);
    // } else {
    //   // Add the value if it's not already selected
    //   selectedValues[value] = true;
    // }

    input.onChange(selectedValues);
    onSelect(selectedValues);
  } else {
    // Si es selección única, simplemente actualizar el valor
    input.onChange(value);
    onSelect(value);
  }
}

export default {
  Render(props) {
    const {
      onChange,
      fieldsRequired,
      isFilter
    } = props;
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);

    if(design === "toggle") {
      return(
        <FormFieldMultiSelectSwitch 
          name={_fieldName}
          title={title}
          placeholder={title}
          fieldsRequired={fieldsRequired}
          multiple={isFilter ? false : true} // multiple only for entity.form
          {...param}
          {...style}
          {...props}
        />
      )
    }

    if(design === "select") {
      return(
        <FormFieldSelect 
          name={_fieldName}
          title={title}
          placeholder={title}
          fieldsRequired={fieldsRequired}
          multiple={isFilter ? false : true} // multiple only for entity.form
          {...param}
          {...style}
          {...props}
        />
      )
    }

    else if (design === "buttons") {
      return (
        <FormFieldSelectButtons 
          name={_fieldName}
          title={title}
          fieldsRequired={fieldsRequired}
          multiple={isFilter ? false : true} // multiple only for entity.form
          {...param}
          {...style}
          {...props}
        />
      )
    }

    else if (design === "buttonsColors") {
      return (
        <FormFieldSelectButtonsWithColors 
          name={_fieldName}
          title={title}
          fieldsRequired={fieldsRequired}
          multiple={isFilter ? false : true} // multiple only for entity.form
          {...param}
          {...style}
          {...props}
        />
      )
    }
    
    return null;
  },

  RenderFilter(props) {
    return <FilterRenderMultiSelect {...props} DataTypeRender={this.Render} />
  },

  RenderFilterParam(props) {
    return <FilterCreatorMultiSelect {...props} />
  },

  RenderInputParams: ({ values, param }) => (<>
    <FormFieldSelectButtons
      name="param.typesToShow"
      title="Datos a mostrar"
      multiple={true}
      options={[
        { value: 'label', label: 'label' },
        { value: 'color', label: 'color' },
        { value: 'value', label: 'value' },
        { value: 'amount', label: 'amount' },
        { value: 'timeRange', label: 'timeRange' },
        { value: 'number', label: 'number' },
        { value: 'numberSlider', label: 'numberSlider' },
      ]}
    />
    <FormFieldListItems
      name="param.options"
      title="Opciones"
      placeholder="Opciones"
      typesToShow={arrayToTrueMap(param.typesToShow)}
    />
  </>),
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (<>
    <FormFieldSelect
      name="show.multiselect.operator"
      title="Operador"
      placeholder="Operador"
      options={[
        { value: 'eq', label: 'es igual' },
      ]}
    />
    <FormFieldSelect
      name="show.multiselect.values"
      title="Opciones"
      placeholder="Opciones"
      options={taxonomyToWatch?.data?.param?.options}
    />
  </>),
  
  RenderShowParams: ({ values }) => (<>
    <FormFieldSelect
      name="style.design"
      title="Diseño"
      placeholder="Diseño"
      options={[
        { value: 'toggle', label: 'Switch' },
        { value: 'select', label: 'Select' },
        { value: 'buttons', label: 'Buttons' },
        { value: 'buttonsColors', label: 'Buttons colored' }
      ]}
    />
    <div className="mt-4 p-2 bg-white rounded">
      <label className="mb-2 pb-2 block font-semibold border-b border-gray-200">Estilos</label>
      <FormField
        name={`style.classes.switchListContainer`}
        showLabel={true}
        title="Contenedor de lista de Switchs"
        placeholder="Clases"
        className="inline-block"
        type="text"
      />
    </div>  
  </>),
  
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => {
    if (values.style?.design === 'toggle') {
      return (<FormFieldMultiSelectSwitch {...formFieldProps} multiple={true} />);
    }
    if (values.style?.design === 'select') {
      return (<FormFieldSelect {...formFieldProps} multiple={true} />);
    }
    if (values.style?.design === 'buttons') {
      return (<FormFieldSelectButtons {...formFieldProps} multiple={true} />);
    }
    if (values.style?.design === 'buttonsColors') {
      return (<FormFieldSelectButtonsWithColors {...formFieldProps} multiple={true} />);
    }
    return '';
  },
 
  getDefaultTaxonomyTypeDesign: () => ('toogle')
}