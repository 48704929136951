import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ModalAlert from './ui/ModalAlert';
import SectionCrudForm from './SectionCrudForm';

function SectionCrudObject({
  docs, // typeof Model
  editStyle, // onsite modal
  reorder,
  title,
  showAddBtn = true,
  showToast = true,
  // validatiion
  fieldsRequired,
  onValidation = (() => null),
  // callbacks 
  onSave = (() => null),
  onDelete = (() => null),
  onReorder = (() => null),
  // UI
  listStyle,
  ListItem,
  ListBtns = (() => null),
  FormSection = null,
  FormInputFields = null,
  // Classes
  classNameFormSection = ''
}) {
  const [docList, setDocList] = useState(docs);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [openSelector, setOpenSelector] = useState(false);

  useEffect(() => {
    setDocList(docs);
  }, [docs]);

  FormSection = FormSection || SectionCrudForm;
  ListItem = ListItem || (({ doc }) => (<>
    <span className="">{doc?.data?.name}</span>
  </>));

  const handleSave = (formValues) => {
    onSave(formValues);
    setOpenSelector(false);
    setSelectedItem(null);
  };

  const handleAdd = () => {
    setSelectedItem({});
    setOpenSelector(true);
  };

  const handleEdit = (doc) => {
    setSelectedItem(doc);
    setOpenSelector(true);
  };

  const handleDelete = (doc) => {
    setShowDeleteConfirmation(true);
    setSelectedItem(doc);
  };

  const confirmDelete = () => {
    onDelete(selectedItem);
    setShowDeleteConfirmation(false);
    setSelectedItem(null);
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setSelectedItem(null);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    let newOrder = Array.from(docList);
    const [movedItem] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedItem);
    if (reorder) {
      newOrder = newOrder.map((doc, i) => ({ id: doc.id, value: i }));
    }
    setDocList(newOrder);
    onReorder(newOrder);
  };

  return (
    <div className="section-crud-object">
      {/* header */}
      <div className="flex flex-row place-content-between items-center place-items-center">
        {title ? (
          <h2 className="text-lg font-semibold">{title}</h2>
        ) : ''}
        {showAddBtn ? (
          <button type="button" onClick={handleAdd} className="text-left bg-blue-600 text-blue-100 shadow-md rounded px-3 p-1 text-lg">
            + Agregar
          </button>
        ) : ''}
      </div>
      {openSelector && selectedItem && !selectedItem.id && (
        <div className={`my-5 ${classNameFormSection}`}>
          {/* form add */}
          <FormSection
            doc={selectedItem}
            editStyle={editStyle}
            onClose={() => {
              setOpenSelector(false);
              setSelectedItem(null);
            }}
            onSave={handleSave}
            fieldsRequired={fieldsRequired}
            onValidation={onValidation}
            FormInputFields={FormInputFields}
            showToast={showToast}
          />
        </div>
      )}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="docList">
          {(provided) => (
            <ul className={`grid grid-cols-1 gap-2 ${docList.length ? 'my-5' : ''}`} {...provided.droppableProps} ref={provided.innerRef}>
              {docList.map((doc, index) => (
                <Draggable key={doc.id} draggableId={doc.id} index={index}>
                  {(provided) => (
                    <>
                      {/* form edit */}
                      {openSelector && selectedItem && selectedItem.id === doc.id ? (
                        <div ref={provided.innerRef} {...provided.draggableProps} className={`mb-3 ${classNameFormSection}`}>
                          <FormSection
                            doc={selectedItem}
                            editStyle={editStyle}
                            onClose={() => {
                              setOpenSelector(false);
                              setSelectedItem(null);
                            }}
                            onSave={handleSave}
                            fieldsRequired={fieldsRequired}
                            onValidation={onValidation}
                            FormInputFields={FormInputFields}
                            showToast={showToast}
                          />
                        </div>
                      ) : (
                        <>
                          {/* list */}
                          {provided.innerRef && (
                            <li
                              {...provided.draggableProps}
                              className="bg-white rounded px-4 py-2 border border-gray-200"
                              ref={provided.innerRef}
                            >
                              <div className="flex justify-between mb-2">
                                <ListItem doc={doc} style={listStyle }/>
                              </div>
                              <div className='relative w-full'>
                                <ListBtns doc={doc} />
                                <button type="button" onClick={() => handleEdit(doc)}
                                className="mr-2 px-2.5 py-0.5 text-xs bg-gray-200 text-gray-600 rounded-md">
                                  Editar
                                </button>
                                <button type="button" onClick={() => handleDelete(doc)}
                                className="px-2.5 py-0.5 text-xs bg-red-200 text-red-900 rounded-md">
                                  Eliminar
                                </button>
                                {reorder ? (
                                  <div {...provided.dragHandleProps}
                                  className="block absolute top-0 right-0 px-2.5 py-0.5 text-xs bg-blue-500 text-white rounded-md">
                                    Mover
                                  </div>
                                ) : ''}
                              </div>
                            </li>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>

      {showDeleteConfirmation && (
        <ModalAlert
          text="¿Estás seguro de que deseas eliminar este elemento?"
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
      )}
    </div>
  );
}

export default SectionCrudObject;
