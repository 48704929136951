import { Fragment } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";

const getTarget = (url) => {
  return url.startsWith("http") ? "_blank" : null;
};

export default function CtaGrid({ items, classes }) {
  return (
    <div className={`flex flex-row gap-4 mt-4 justify-center ${classes?.ctaGridContainer}`}>
      {items?.map((item, index) => (
        <Fragment key={index}>
          {item.ctaType === "whatsapp" && (
            <a href={item.ctaUrl} target={getTarget(item.ctaUrl)} rel={getTarget(item.ctaUrl) ? "noopener noreferrer" : ""} className={`
              px-6 py-2 flex items-center
              ${classes?.ctaGridButton || "bg-brand-secondary rounded-sm text-brand-secondary-contrast"}
            `}>
              <h3 className="text-sm sm:text-base">
                {item.ctaTitle}
              </h3>
              <FaWhatsapp className="text-xl text-brand-secondary-contrast ml-1.5" />
            </a>
          )}
          {item.ctaType === "link" && (
            getTarget(item.ctaUrl) ? (
              <a href={item.ctaUrl} target={getTarget(item.ctaUrl)} rel="noopener noreferrer" className={`
                px-6 py-2 flex items-center
                ${classes?.ctaGridButton || "bg-brand-secondary rounded-sm text-brand-secondary-contrast"}
              `}>
                <h3 className="text-sm sm:text-base">
                  {item.ctaTitle}
                </h3>
              </a>
            ) : (
              <Link to={item.ctaUrl} className={`
                px-6 py-2 flex items-center
                ${classes?.ctaGridButton || "bg-brand-secondary rounded-sm text-brand-secondary-contrast"}
              `}>
                <h3 className="text-sm sm:text-base">
                  {item.ctaTitle}
                </h3>
              </Link>
            )
          )}
        </Fragment>
      ))}
    </div>
  )
}
