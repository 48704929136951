import { processRenderProps } from './dataTypeUtils';
import {
  FormField,
  FormFieldGallery,
  FormFieldSelect,
} from '../Form';


const imgProportionOptions = ['vertical-square', 'vertical-wide'].map(option => ({ label: option, value: option }));

export default {
  Render(props) {
    const {
      onChange,
      fieldsRequired
    } = props;
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);

    return (<FormFieldGallery 
      name={_fieldName}
      fieldsRequired={fieldsRequired}
      title={title}
      placeholder={title}
      {...param}
      {...style}
      {...props}
    />);
  },

  RenderInputParams: ({ values, param }) => (
    <>
      <FormField
        name="param.folder"
        title="Carpeta de almacenamiento para archivos"
        placeholder="Carpeta de almacenamiento para archivos"
        showLabel={true}
      />
      <FormFieldSelect
        name="param.imgProportion"
        title="Proporcion de imagenes"
        showLabel={true}
        options={imgProportionOptions}
      />
    </>
  ),
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
  
  RenderShowParams: ({ values }) => (null),
  
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => (
    <FormFieldGallery {...formFieldProps} />
  ),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}