import { useEffect, useState } from 'react';
import _ from 'lodash';
import { IonDatetime, IonIcon } from '@ionic/react';
import { 
  chevronUpOutline,
  chevronDownOutline,
  timeOutline,
  arrowForwardOutline
} from 'ionicons/icons';


const RawInputTimeRange = (props) => {
  const {
    value,
    onChange
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    !value && onChange({ start: '00:00', end: '00:00' });
  }, [value]);
  
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <div className="relative inline-flex text-left place-content-stretch h-[32px]">
      <button
        onClick={toggleDropdown}
        className={`rounded inline-flex items-center gap-1 text-sm px-3 py-1 text-center text-gray-800 bg-gray-200 shadow`}
        type="button"
      >
        {value?.start ? (
          <span className="">{value.start}</span>
        ) : null}
        <IonIcon slot="icon-only" className="text-xs" icon={arrowForwardOutline} />
        {value?.end ? (
          <span className="">{value.end}</span>
        ) : null}
        <IonIcon slot="icon-only" size='small' icon={timeOutline} />
        <IonIcon slot="icon-only" icon={isOpen ? chevronUpOutline : chevronDownOutline} />
      </button>
      {isOpen && (
        <div className="z-10 absolute right-0 mt-10 bg-white divide-y rounded-lg shadow w-auto max-w-[80vw] sm:max-w-[450px] flex flex-row gap-1">
          <div className="text-center px-4 py-2">
            <label className="w-full text-xs font-semibold uppercase text-gray-600">Inicio</label>
            <IonDatetime presentation="time" value={'2024-12-01T'+value?.start+':00'} onIonChange={e => onChange({ ...value, start: e.target.value?.split('T')[1].slice(0, -3) })}></IonDatetime>
          </div>
          <div className="w-[1px] bg-gray-300"></div>
          <div className="text-center px-4 py-2">
            <label className="w-full text-xs font-semibold uppercase text-gray-600">Fin</label>
            <IonDatetime presentation="time" value={'2024-12-01T'+value?.end+':00'} onIonChange={e => onChange({ ...value, end: e.target.value?.split('T')[1].slice(0, -3) })}></IonDatetime>
          </div>
        </div>
      )}
    </div>
  );
};

export default RawInputTimeRange;