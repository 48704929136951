import { useMemo } from "react";
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuToggle,
} from "@ionic/react";
import { menuController } from '@ionic/core/components';
import { useLocation } from "react-router-dom";
import { CurrentUserItem, UserList } from "./MenuOfUserSession";
import config from "../../config";
import { useModule } from "../../context/ModuleContext";
import { usePanel } from "./usePanel";
import { LayoutLogo } from "./LayoutLogo";
import { getMenuPages } from ".";
import { useAuth } from "../user/AuthContext";
import PartOfModule from "../../components/Module/PartOfModule";
import { useLayoutHome } from "../layoutHome/useLayoutHome";
import { stackClasses } from "../../libs/utils";


export function MenuMainContent({ 
  history,
  triggerId,
  menuName
}) {
  const location = useLocation();
  const moduleLibs = useModule(); 
  const { isAllowed } = moduleLibs;
  const { versionCurrent } = usePanel();
  const { user } = useAuth();
  const { layoutDesign } = useLayoutHome();
  
  const version = useMemo(() => {
    return versionCurrent ? (
      `${versionCurrent?.bundle?.version} / ${versionCurrent?.native}`
    ) : (
      config?.version
    )
  }, [versionCurrent]);

  const pagesList = useMemo(() => {
    return getMenuPages({ menuName, user, isAllowed });
  }, [user?.userDoc]);

  const openMenu = async () => {
    await menuController.open(triggerId);
  };

  const closeMenu = async () => {
    await menuController.close(triggerId);
  };

  return (
    <IonContent>
      <div className="flex flex-col place-content-center pt-8 pb-14">
        <LayoutLogo classes={stackClasses(layoutDesign?.logo, layoutDesign?.mainMenu)} design="light" />
        {/* <LayoutLogoLight classes={{ logoContainer: '', logoIcon: '!h-11 -mt-3', logoText: '!text-4xl' }} /> */}
      </div>

      <div className="mx-2.5">
        <CurrentUserItem showUserPic={false} />
      </div>
      
      <PartOfModule
        type="main"
        action="SideMenuUnderCurrentUser"
        param={{ user, history, triggerId, openMenu, closeMenu, ...moduleLibs }}
      />

      <IonList className="mt-4">
        {pagesList.map((page, index) => {
          return (
            (page?.permissions 
              ? isAllowed(page?.permissions?.resource, page?.permissions?.actions)
              : true
            ) ? (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === page.url ? 'selected' : ''} routerLink={page.url} routerDirection="none" lines="none" detail={false}>
                  {page.ionIcon ? (
                    <IonIcon slot="start" icon={page.ionIcon} />
                  ) : null}
                  {page.Icon ? (
                    <div className="py-3 pr-8">
                      <page.Icon className="text-gray-500 text-xl" />
                    </div>
                  ) : null}
                  <IonLabel>{page.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            ) : null
          );
        })}
      </IonList>

      <PartOfModule
        type="main"
        action="SideMenuUnderSectionsList"
        param={{ user, history, triggerId, openMenu, closeMenu, ...moduleLibs }}
      />

      <UserList history={history} showCurrentUser={false} triggerMode="menu" triggerId={triggerId} />

      <div className="font-normal text-xs w-full text-center mt-14 text-gray-400">
        <PartOfModule
          type="main"
          action="SideMenuBeforeVersion"
          param={{ user, history, triggerId, openMenu, closeMenu, ...moduleLibs }}
        />
        <div className="font-mono">
          {version}
        </div>
      </div>
    </IonContent>
  );
}