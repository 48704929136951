import { processRenderProps } from './dataTypeUtils';
import {
  FormField,
  FormFieldDate
} from '../Form';
import {
  // Date
  FilterRenderDate,
  FilterCreatorDate,
} from '../Filters';

const valueFormatter = (value) => {
  return new Date(value);
}

export default {
  Render(props) {
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);
    param.formatter = valueFormatter;

    if (taxonomyTypeData.readOnly) {
      return (
        <FormField
          name={_fieldName}
          {...param}
          {...style}
          {...props}
          disabled={true}
        />
      );
    }

    return (
      <FormFieldDate
        name={_fieldName}
        {...param}
        {...style}
        {...props}
      />
    );
  },
  
  RenderFilter(props) {
    return <FilterRenderDate {...props} DataTypeRender={this.Render} />
  },

  RenderFilterParam(props) {
    return <FilterCreatorDate {...props} />
  },

  valueFormatter,

  RenderInputParams: ({ values, param }) => (null),
  
  RenderShowParams: ({ values }) => (
    <>
      <FormField
        name="show.format"
        title="Formato dayjs().format()"
        placeholder="Formato dayjs().format()"
        showLabel={true}
      />
    </>
  ),

  RenderShowPreview: ({ values }) => (null),
  
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => (
    <FormField {...formFieldProps} type="date" />
  ),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}