import { useState } from "react";
import _ from 'lodash';
import SectionCrudModel from "../../components/SectionCrudModel";
import Model from "../../libs/ModelClass";
import useEntityTaxonomyForm from "../../components/EntityTaxonomyForm";
import { useListDoc } from "../../routes/AdminEntityCrud";
import config from '../../config';

const variantsEntitySlug = config.modules.cart.cartItemVariantsEntitySlug;
const ItemVariantsModel = Model.extend(variantsEntitySlug);
const mainEntitySlug = config.modules.cart.cartItemEntitySlug;
const ItemMainModel = Model.extend(mainEntitySlug);
const categoryEntitySlug = config.modules.cart.cartItemCategoriesEntitySlug;
const CategoryModel = Model.extend(categoryEntitySlug);

// export const syncVariantDocsWithMain = async () => {
//   const docs = await ItemMainModel.getAll();
//   for (const mainDoc of docs) { 
//     const oldDocs = await ItemVariantsModel.filterByAttributes({
//       mainItemId: mainDoc.id
//     });
//     await ItemVariantsModel.updateMany(oldDocs, {
//       name: mainDoc?.data?.name,
//       price: mainDoc?.data?.price,
//       mainAvailable: mainDoc?.data?.mainAvailable,
//       itemType: mainDoc?.data?.itemType,
//       itemCategory: mainDoc?.data?.itemCategory,
//       mainImgs: null
//     });
//     console.log('updated', mainDoc?.data?.name, mainDoc?.data?.price, oldDocs?.length)
//   }
// };

// export const syncVariantDocsSizes = async () => {
//   const docs = await ItemMainModel.getAll();
//   let i = 0;
//   for (const mainDoc of docs) { 
//     const oldDocs = await ItemVariantsModel.filterByAttributes({
//       mainItemId: mainDoc.id
//     });
//     if (oldDocs?.length) {
//       for (const doc of oldDocs) {
//         if (doc.data.sizes?.xs) {
//           delete doc.data.sizes.xs;
//           doc.data.sizes.xp = true;
//           console.log(i++, 'updated xs => xp')
//         }
//         if (doc.data.sizes?.s) {
//           delete doc.data.sizes.s;
//           doc.data.sizes.p = true;
//           console.log(i++, 'updated s => p')
//         }
//         if (doc.data.sizes?.l) {
//           delete doc.data.sizes.l;
//           doc.data.sizes.g = true;
//           console.log(i++, 'updated l => g')
//         }
//         if (doc.data.sizes?.xl) {
//           delete doc.data.sizes.xl;
//           doc.data.sizes.xg = true;
//           console.log(i++, 'updated xl => xg')
//         }
//         await doc.save();
//       }
//     }
//   }
// };

export const updateVariantDocs = async ({ doc, entitySlug, taxonomyTypesDocList }, formValues) => {
  const mainItemDoc = doc;
  if ((!formValues['name'] || formValues['name'] === ' ') && formValues['itemCategory']) {
    const catDoc = await CategoryModel.findById(formValues['itemCategory']);
    formValues['name'] = catDoc?.data?.nameSingle || catDoc?.data?.name || null;
  }
  if (mainItemDoc?.id) {
    const modifiers = {};
    taxonomyTypesDocList.forEach(taxonomyType => {
      modifiers[taxonomyType.data.nameSlug] = formValues[taxonomyType.data.nameSlug];
    });
    const oldDocs = await ItemVariantsModel.filterByAttributes({
      mainItemId: mainItemDoc?.id
    });
    await ItemVariantsModel.updateManyBatch(oldDocs, modifiers);
  }
  return formValues;
};

export const deleteVariantDocs = async ({ doc, entitySlug }) => {
  const mainItemDoc = doc;
  const modifiers = {
    deleted: true
  };
  const oldDocs = await ItemVariantsModel.filterByAttributes({
    mainItemId: mainItemDoc.id
  });
  await ItemVariantsModel.updateManyBatch(oldDocs, modifiers);
};

// VariantsCRUD
export const VariantsCRUD = (props) => {
  const { doc, entitySlug, isAllowed } = props;
  const mainTaxonomyTypesDocList = props.taxonomyTypesDocList;
  
  // TODO promote logic to Entity as Extended Entity
  const mainItemDoc = doc;
  const [fieldsRequired, setFieldsRequired] = useState([]);
  const { EntityTaxonomyForm, ...variantsEntitySpecs } = useEntityTaxonomyForm({ 
    entitySlug: variantsEntitySlug,
    fieldsRequired, 
    setFieldsRequired
  });

  // prevent render main taxonomy types on variant list
  const explicitTaxonomyTypes = [];
  variantsEntitySpecs?.taxonomyTypesDocList?.forEach((variantTaxonomyTypeDoc) => {
    const isMainTaxonomyType = mainTaxonomyTypesDocList.find((mainTaxonomyTypeDoc) => {
      return mainTaxonomyTypeDoc.data.nameSlug === variantTaxonomyTypeDoc.data.nameSlug;
    });
    if (!isMainTaxonomyType) {
      explicitTaxonomyTypes.push(variantTaxonomyTypeDoc);
    }
  });

  const onValidation = (values, errors) => {
    // all required fields
    fieldsRequired.forEach(field => {
      if (!values[field]) {
        errors[field] = ' '; 
      }
    });
    // [TODO] realiar validación de taxonomías segú tipo y param
  };

  const fetchItems = async ({ setDocs }) => {
    if (!mainItemDoc.id) {
      setDocs([]);
      return;
    }
    let docs = await ItemVariantsModel.filterByAttributes({ mainItemId: mainItemDoc.id });
    docs = docs.filter((doc) => doc.data?.deleted !== true);
    setDocs(docs);
  };

  const handleBeforeSave = async (doc) => {
    doc.mainItemId = mainItemDoc.id;
    const refreshMainItemDoc = await ItemMainModel.findById(mainItemDoc.id);
    // assing main attr values
    mainTaxonomyTypesDocList.forEach(taxonomyType => {
      doc[taxonomyType.data.nameSlug] = refreshMainItemDoc.data[taxonomyType.data.nameSlug];
    });
  };
  
  const ListItem = useListDoc({ 
    entitySpecs: variantsEntitySpecs,
    isAllowed,
    // outstandingOnly: false,
    ExtraFields: ({ doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug }) => (<>
      <ViewData field="sizes" force={true} classes={{ fieldContainer: 'text-sm block', fieldViewSelectedOption: 'font-bold text-black', fieldViewUnselectedOption: 'text-gray-400 line-through' }} />
      <ViewData field="variantAvailable" force={true} classes={{ fieldContainer: 'text-sm block', fieldValueTrue: 'text-blue-500', fieldValueFalse: 'text-gray-500' }} />
    </>)
   });

  const FormInputFields = (({ values }) => (<>
    <EntityTaxonomyForm values={values} explicitTaxonomyTypes={explicitTaxonomyTypes} />
  </>));

  return (
    mainItemDoc?.id ? (
      <div className="mt-10 p-2 rounded-md border border-gray-200 shadow-md">
        <SectionCrudModel
          model={ItemVariantsModel}
          entitySlug={variantsEntitySlug}
          editStyle="onsite"
          reorder={false}
          title="Variantes del producto"
          // validatiion
          fieldsRequired={fieldsRequired}
          onValidation={onValidation}
          // callbacks 
          fetchItems={fetchItems}
          handleBeforeSave={handleBeforeSave}
          // add UI
          ListItem={ListItem}
          FormInputFields={FormInputFields}
          classNameFormSection="p-2 border border-gray-700 rounded-md shadow-md"
        />
      </div>
    ) : null
  );
};