import { Carousel } from 'react-responsive-carousel';
import CtaGrid from '../../../components/ui/CtaGrid';


export default function HeaderHeroBlock({ data }) {
  return (

    <Carousel showThumbs={false} showStatus={false} infiniteLoop={true} autoPlay={true}>
      {data?.items?.map((item, index) => (
        <div key={index} id='inicio'>
          <img 
          src={item.imageSrc} 
          alt={item.title}
          className=" h-[60vh] sm:h-[65vh] md:h-[75vh] lg:h-[100vh] object-cover md:object-top "
          />
          
          <div className="absolute bottom-4 sm:bottom-1 w-full mx-auto md:space-y-5 pb-24 md:pb-32 lg:pb-32 xl:pb-36">
            <h3 className='text-white font-brand-main font-black italic px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl uppercase drop-shadow-md '>{item.title}</h3>
            <p className='text-white font-brand-main font-black italic px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl uppercase drop-shadow-md '>{item.subtitle}</p>
            <CtaGrid items={item.ctaButtons}/>
          </div>

        </div>
      ))}
    </Carousel>

  );
}
