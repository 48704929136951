import permissionsDataType from './permissionsDataType';
import entities from './entities.json';

let { roles, usersProfiles, credentials } = entities;

const UnderListItemTitleForCredentials = ({ ViewData }) => (
  <ViewData field="roles" classes={{ fieldContainer: 'text-xs text-white bg-gray-600 px-2 py-0.5 rounded-full inline-block' }} />
);

export default function ({ setPermissions }) {
  return {
    slug: 'user',
    label: 'User',
    entities: {
      roles, usersProfiles, credentials
    },
    permissions: [
      setPermissions({ slug: 'credentials', label: 'Credenciales', type: 'system', actionsToAdd: ['owner:read'] }),
      setPermissions({ slug: 'usersProfiles', label: 'Usuarios', type: 'system', actionsToAdd: ['owner:read', 'owner:edit'] }),
      setPermissions({ slug: 'roles', label: 'Cargos', type: 'system' })
    ],
    components: {
      'credentials': {
        crud: {
          UnderListItemTitle: UnderListItemTitleForCredentials
        }
      },
      'roles': {
        codedDataTypes: {
          permissions: permissionsDataType // RenderFormField
        }
      },
    },
    // events: {
    //   'usersProfiles': {
    //     beforeSave: async ({ taxonomyTypesDocList }, formValues) => {
    //       let modifiedFormValues = formValues;
    //       return modifiedFormValues;        
    //     }
    //   }
    // }
  };
};