import { Link } from 'react-router-dom';
import { LayoutAdmin } from "../modules/panel/LayoutAdmin";
import SectionCrudModel from "../components/SectionCrudModel";
import Entity, { getEntitiesBlueprint, getEntityBlueprintBySlug, saveEntityFromBlueprint } from '../modules/entity/Entity';
import { downloadJSON } from '../libs/utils';
import Model from '../libs/ModelClass';
import IonBtnLoading from '../components/ui/IonBtnLoading';
import ActionsDropdown from '../components/ui/ActionsDropdown';
import { IonItem } from '@ionic/react';
import { useState } from 'react';
import ModalAlert from '../components/ui/ModalAlert';
import toast from 'react-hot-toast';


export function Content(props) {
  const [showExportBlueprintsConfirmation, setExportBlueprintsConfirmation] = useState(false);
  const [entitySlugToExport, setEntitySlugToExport] = useState(null);
  const [showExportAllRecordsConfirmation, setExportAllRecordsConfirmation] = useState(false);
  const [entitySlugToClone, setEntitySlugToClone] = useState(null);

  const cloneEntity = async () => {
    try {
      let entityBlueprintToClone = await getEntityBlueprintBySlug({ entitySlug: entitySlugToClone });
      let newEntityBlueprintCloned = await saveEntityFromBlueprint(entityBlueprintToClone);
      console.log('entityBlueprintToClone', entityBlueprintToClone);
      console.log('newEntityBlueprintCloned', newEntityBlueprintCloned);
      toast.success('Entidad clonada');
      setEntitySlugToClone(null);
    } catch (error) {
      console.log('Error cloning entity:', error);
    }
  };

  const exportBlueprints = async () => {
    try {
      let entitiesBlueprints = await getEntitiesBlueprint();
      downloadJSON(entitiesBlueprints, 'entitiesBlueprints.json');
      setExportBlueprintsConfirmation(false);
    } catch (error) {
      console.log('Error exporting entities:', error);
    }
  };

  const exportAllRecordsOfEntitySlug = async (entitySlug) => {
    try {
      let entitiesDocs = await Model.extend(entitySlug || entitySlugToExport).getAll();
      entitiesDocs = entitiesDocs.map(({ data }) => ({
        ...data
      }));
      downloadJSON(entitiesDocs, 'entitiesDocs.json');
      setEntitySlugToExport(null);
    } catch (error) {
      console.log('Error exporting entity:', error);
    }
  };

  const exportAllRecords = async () => {
    try {
      let recordsOfEntities = {};
      let entitiesDocs = await Model.extend('entities').getAll();
      let entitiesSlugs = entitiesDocs.map(doc => doc.data.nameSlug);
      entitiesSlugs = [ ...entitiesSlugs, 'entities', 'taxonomyTypes', 'filterMenu' ];
      for (const nameSlug of entitiesSlugs) {
        let allDocsOfEntity = await Model.extend(nameSlug).getAll();
        allDocsOfEntity = allDocsOfEntity.map(doc => doc.data);
        recordsOfEntities[nameSlug] = [ ...allDocsOfEntity ];
      }
      downloadJSON(recordsOfEntities, 'recordsOfEntities.json');
      setExportAllRecordsConfirmation(false);
    } catch (error) {
      console.log('Error exporting entity:', error);
    }
  };
  
  const ListItem = ({ doc }) => (<div className=''>
    <span className="">{doc?.data?.name}</span>
    <div className="">
      <span className="text-sm text-sky-600">{doc.data?.nameSlug}</span>
    </div>
  </div>);

  const ExtraActions = () => (<>
    <ActionsDropdown classes={{ actionsContainer: '', actionsPopover: 'rounded-md overflow-hidden shadow-xl' }}>
      <IonItem button onClick={() => setExportBlueprintsConfirmation(true)} color="medium" size="small">
        Exportar Blueprints
      </IonItem>
      <IonItem button onClick={() => setExportAllRecordsConfirmation(true)} color="medium" size="small">
        Exportar todos los registros
      </IonItem>
    </ActionsDropdown>
  </>);

  const ItemExtraActions = ({ doc, isAllowed }) => (<>
    <ActionsDropdown classes={{ actionsContainer: '!absolute top-0 right-1', actionsPopover: 'rounded-md overflow-hidden shadow-xl' }}>
      {(isAllowed('entities', ['export'])) ? (
        <IonBtnLoading
          label="Exportar datos"
          color="medium"
          size="small"
          fill="clear"
          transitions={false}
          onClick={() => setEntitySlugToExport(doc.data.nameSlug)}
        >
        </IonBtnLoading>
      ) : null}
      {(isAllowed('entities', ['clone'])) ? (
        <IonBtnLoading
          label="Clonar entidad"
          color="medium"
          size="small"
          fill="clear"
          transitions={false}
          onClick={() => setEntitySlugToClone(doc.data.nameSlug)}
        >
        </IonBtnLoading>
      ) : null}
    </ActionsDropdown>
  </>);

  return (
    <div className="">
      <SectionCrudModel
        model={Entity}
        entitySlug="entities"
        editStyle="route"
        reorder={true}
        navigateTo={(doc) => (`/a/config/entity-creator/${doc ? doc.id : 'new'}/form`)}
        // add UI
        ExtraActions={ExtraActions}
        ListItem={ListItem}
        ItemExtraActions={ItemExtraActions}
      />
      {/* modal for exports */}
      {entitySlugToClone && (
        <ModalAlert
          text={`¿Estás seguro de que deseas CLONAR la entidad ${entitySlugToClone}?`}
          onConfirm={cloneEntity}
          onCancel={() => setEntitySlugToClone(null)}
          confirmClass="bg-brand-primary text-white"
        />
      )}
      {entitySlugToExport && (
        <ModalAlert
          text={`¿Estás seguro de que deseas exportar todos los datos de la entidad ${entitySlugToExport}?`}
          onConfirm={exportAllRecordsOfEntitySlug}
          onCancel={() => setEntitySlugToExport(null)}
          confirmClass="bg-brand-primary text-white"
        />
      )}
      {showExportBlueprintsConfirmation && (
        <ModalAlert
          text="¿Estás seguro de que deseas exportar los Blueprints de todas las entidades?"
          onConfirm={exportBlueprints}
          onCancel={() => setExportBlueprintsConfirmation(false)}
          confirmClass="bg-brand-primary text-white"
        />
      )}
      {showExportAllRecordsConfirmation && (
        <ModalAlert
          text="¿Estás seguro de que deseas exportar todos los datos de todas la entidades?"
          onConfirm={exportAllRecords}
          onCancel={() => setExportAllRecordsConfirmation(false)}
          confirmClass="bg-brand-primary text-white"
        />
      )}
    </div>
  );
}

export function AdminEntityCreator(props) {
  return (
    <LayoutAdmin 
      history={props.history} 
      defaultHref={`/a/config`}
      title="Entidades"
      breadcrumbs={[{
        url: '/a/config',
        title: 'Config'
      }, {
        title: "Entidades"
      }]}
    >
      <div className="ion-padding">
        <Content {...props} />
      </div>
    </LayoutAdmin>
  );
}
