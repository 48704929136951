import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core';
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { SplashScreen } from '@capacitor/splash-screen'
import { useEffect, useMemo, useState } from "react";
import _ from 'lodash';
import Model from '../../libs/ModelClass';
import config from '../../config';
import { sortDocsVersions } from '.';
import { compareVersions } from 'compare-versions';
import toast from 'react-hot-toast';

const SystemVersionModel = Model.extend(config.modules.panel.panelSystemVersionEntitySlug);

const listenBranch = 'release';


export const useVerifyVersion = () => {
  const [ versionList, setVersionList ] = useState();
  const [ versionCurrent, setVersionCurrent ] = useState();
  const [ availableLightVersion, setAvailableLightVersion ] = useState();
  const [ availableHardVersion, setAvailableHardVersion ] = useState();
  const [ versionDowloaded, setVersionDowloaded ] = useState();

  // download and install
  useEffect(() => {
    const appStateChangeListener = App.addListener("appStateChange", async (state) => {
      // update when go to background
      if (!state.isActive && versionDowloaded) {
        // Activate the update when the application is sent to background
        SplashScreen.show();
        try {
          await CapacitorUpdater.set({ id: versionDowloaded?.id });
          // At this point, the new version should be active, and will need to hide the splash screen
        } catch (error) {
          SplashScreen.hide(); // Hide the splash screen again if something went wrong
        }
      }
    });

    CapacitorUpdater.addListener("majorAvailable", () => {
      setAvailableHardVersion(true);    
    });
    CapacitorUpdater.addListener('download', (info) => {
      // console.log('Se inició la descarga de una nueva versión', info.percent);
    });
    CapacitorUpdater.addListener('downloadComplete', (info) => {
      console.log('Descarga completa', info);
    });
    // errors
    CapacitorUpdater.addListener("downloadFailed", () => {
      console.error('Error durante la descarga');    
    });
    CapacitorUpdater.addListener("updateFailed", () => {
      console.error('Error durante la instalación');    
    });
    // cleanup
    return () => {
      appStateChangeListener.remove();
      CapacitorUpdater.removeAllListeners();
    };
  }, [availableLightVersion, availableHardVersion, versionDowloaded]);
  
  // compare data
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      if (!versionCurrent) {
        fetchCurrent();
      }
    }
    fetchList();
    if (
      !versionList || !versionCurrent || availableLightVersion || availableHardVersion
      || !Capacitor.isNativePlatform()
    ) { 
      return;
    }
    const latestLightAvailable = getLatestAnnouncedAvailableVersion('light');
    if (latestLightAvailable) {
      if (
        latestLightAvailable.data?.linkLightBundle
        && latestLightAvailable.data?.versionCode
        && latestLightAvailable.data?.checksum
        && compareVersions(latestLightAvailable.data?.versionCode, calcCurrentVersion(), '>') === 1
      ) {
        setTimeout(() => {
          toast('Hay una actualización disponible.', { icon: "⚙️" });
        }, 1500);
        setTimeout(() => {
          toast('Se instalará durante el siguiente bloqueo de pantalla', { icon: "⚙️" });
        }, 3000);
        setAvailableLightVersion(latestLightAvailable);
        // download when active
        downloadZip(latestLightAvailable);
      } else {
        console.error('cap bundle light link missing');
      }
    }

    const latestHardAvailable = getLatestAnnouncedAvailableVersion('hard');
    if (latestHardAvailable) {
      setTimeout(() => {
        toast('Hay una actualización disponible.', { icon: "⚙️" });
      }, 1500);
      setTimeout(() => {
        toast('Debe actualizar desde ' + (Capacitor.getPlatform() === 'ios' ? 'la AppStore' : 'Google PlayStore'), { icon: "⚙️" });
      }, 3000);
      setAvailableHardVersion(true);
    }
  }, [versionList, versionCurrent]);

  const downloadZip = async (availableLightVersion) => {
    let version = await CapacitorUpdater.download({
      url: availableLightVersion.data.linkLightBundle,
      version: availableLightVersion.data.versionCode,
      checksum: availableLightVersion.data.checksum
    });
    setVersionDowloaded(version);
  };

  const getLatestAnnouncedAvailableVersion = (updateType) => {
    return versionList?.find((versionDoc) => {
      if (updateType === 'light' && !versionDoc?.data?.isHard) {
        if (
          compareVersions(versionDoc?.data?.versionCode || '0', calcCurrentVersion(), '>') === 1
          && versionDoc?.data?.announcedBranchs === listenBranch
        ) {
          return true;
        }
      }
      else if (updateType === 'hard' && versionDoc?.data?.isHard === true) {
        if (
          compareVersions(versionDoc?.data?.versionCode || '0', calcCurrentVersion(), '>') === 1
          && versionDoc?.data?.announcedBranchs === listenBranch
        ) {
          return true;
        }
      }
    });
  };

  const calcCurrentVersion = () => {
    return versionCurrent.bundle?.version === 'builtin' ? config.version : versionCurrent.bundle?.version;
  }

  const fetchList = async () => {
    if (!versionList) {
      let docs = await SystemVersionModel.getAll();
      docs = docs.filter((doc) => {
        return !doc.data.deleted;
      });
      // sort by version
      docs = sortDocsVersions(docs);
      setVersionList(docs);
    }
  };

  const fetchCurrent = async () => {
    const currentVersions = await CapacitorUpdater.current();
    setVersionCurrent({ ...currentVersions });
    return currentVersions;
  };

  return {
    versionList, 
    versionCurrent, 
    availableLightVersion,
    availableHardVersion
  };
}