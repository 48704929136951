import { Link } from 'react-router-dom';
import config from '../../config';
import { LayoutAdmin } from '../../modules/panel/LayoutAdmin';


export function Page401() {
  return (
    <LayoutAdmin metaTitle="404">
      <div className="ion-padding">
        {/* path */}
        <div className="flex mb-5">
          <Link to={config.modules.panel.mainPath}><h2 className="text font-thin mb-4 mr-3">Panel</h2></Link>
          <h2 className="text font-semibold mb-4">401</h2>
        </div>

        <div className="mb-5 text-2xl text-bold">
          No posee autorización
        </div>

        <Link to={config.modules.panel.mainPath} className="bg-zinc-200 hover:bg-zinc-300 rounded py-1.5 px-2 text-black text-xs xsm:text-sm">
          Volver
        </Link>
      </div>
    </LayoutAdmin>
  );
}

export function Page404() {
  return (
    <LayoutAdmin metaTitle="404">
      <div className="ion-padding">
        {/* path */}
        <div className="flex mb-5">
          <Link to={config.modules.panel.mainPath}><h2 className="text font-thin mb-4 mr-3">Panel</h2></Link>
          <h2 className="text font-semibold mb-4">404</h2>
        </div>

        <div className="mb-5 text-2xl text-bold">
          La página no existe
        </div>

        <Link to={config.modules.panel.mainPath} className="bg-zinc-200 hover:bg-zinc-300 rounded py-1.5 px-2 text-black text-xs xsm:text-sm">
          Volver
        </Link>
      </div>
    </LayoutAdmin>
  );
}
