import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import VisibilitySensor from 'react-visibility-sensor';
import { Tooltip } from 'react-tooltip';
import { FaCartPlus } from 'react-icons/fa';
import { useEntityFullBySlug } from '../entity/Entity';
import { SelectButtonsWithColors } from '../../components/Form/FormFieldSelectButtonsWithColors';
import { SelectButtons } from '../../components/Form/FormFieldSelectButtons';
import { QtyButtons } from './QtyButtons';
import { CartProvider, useCart } from './useCartData';
import { withProvider, priceFormat } from '../../libs/utils';
import { EntityDocListView } from '../../components/EntityDocView';
import ContactBTN from '../../components/ContactBTN';
import config from '../../config';
import { CurrentCartDetails, CurrentCartDetailsDrawer } from './CurrentCartDetails';
import {
  gridOutline
} from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import BadgeLoading from '../../components/ui/BadgeLoading';


const bagId = 0;

export default function () {
  return {
    type: 'productView',
    variation: 'expanded',
    template: ProductView,
    params: {
      filterPath: {
        type: 'string',
        defaults: '/m/products/filter'
      },
      viewPath: {
        type: 'string',
        defaults: '/m/products/view'
      },
      cartPath: {
        type: 'string',
        defaults: '/m/carts/view'
      },
      imgProportion: {
        type: 'string',
        options: ['vertical-square', 'vertical-wide'],
        defaults: 'vertical-square'
      },
      classes: {
        type: 'object',
        defaults: {}
      }
    }
  };
};

export const ProductView = withProvider(ProductViewRaw, CartProvider);

function ProductViewRaw(props) {
  let {
    data,
    history,
    parsedParams,
    // entitySlug,
    // action,
    // config,
    user,
    isAllowed,
    Model,
    isMinBreakpointActive,
    location,
    filterMenuSlug,
    filterPath,
    viewPath,
    cartPath,
    showFilters,
    imgProportion,
    classes = {}
  } = props;
  const [ variantsByColor, setVariantsByColor ] = useState();
  const [ currentVariantItemDoc, setCurrentVariantItemDoc ] = useState();
  const [ currentSize, setCurrentSize ] = useState();
  const { 
    ItemMainModel, ItemVariantModel, CategoryModel, TypeModel, 
    fetchItems, items, itemsTypes, itemsCategories,
    bags, getBagById, getBagTotal, isItemInBag, setItemToBag, getItemsOfBag,
    cartDoc, saveNewCart, saveCurrentCart, closeCart,
    totalPriceItems
  } = useCart();
  const variantsEntitySlug = ItemVariantModel.collectionName;
  const entitySpects = useEntityFullBySlug({ entitySlug: variantsEntitySlug });

  const mainItemId = parsedParams.main;
  const variantItemId = parsedParams.variant;

  const params = { 
    color: currentVariantItemDoc?.data?.colors || 'none',
    size: currentSize || 'none',
    variantId: variantItemId
  };

  const itemInBag = isItemInBag(bagId, variantItemId, params);

  // const {
  //   // analytics
  //   viewItem
  // } = useData();

  useEffect(() => {
    fetchData();
  }, [variantItemId]);
  
  const fetchData = async () => {
    const VariantsModel = Model.extend(variantsEntitySlug);
    let variantsItemsDocs = await VariantsModel.filterByAttributes({ mainItemId });
    variantsItemsDocs = variantsItemsDocs.filter(doc => {
      return !doc.data?.deleted && doc.data.mainAvailable && doc.data.variantAvailable;
    });
    const currentVariantItemDoc = variantsItemsDocs.find((variantItem) => variantItem.id === variantItemId);
    const variantsByColor = {};
    variantsItemsDocs.forEach((variantDoc) => {
      if (!variantsByColor[variantDoc.data.colors]) {
        variantsByColor[variantDoc.data.colors] = variantDoc;
      }
    });
    setVariantsByColor(variantsByColor);
    setCurrentVariantItemDoc(currentVariantItemDoc);
  };

  const colorsTaxonomyType = entitySpects?.taxonomyTypesDocList?.find((taxonomyType) => taxonomyType.data.nameSlug === 'colors');
  const sizesTaxonomyType = entitySpects?.taxonomyTypesDocList?.find((taxonomyType) => taxonomyType.data.nameSlug === 'sizes');

  const getColorsList = () => {
    const colorsAvailables = colorsTaxonomyType.data.param.options.filter(colorDef => variantsByColor[colorDef.value]);
    return colorsAvailables;
  };

  const getVarianPath = () => {
    if (currentVariantItemDoc) {
      return `${viewPath}/main/${mainItemId}/variant/${currentVariantItemDoc.id}`;
    }
  }

  const goToItemByParams = (mainId, variantId, params) => {
    if (mainId === mainItemId) {
      selectColor(params?.color);
      selectSize(params?.size);
    } else {
      history.push(`${viewPath}/main/${mainId}/variant/${variantId}`);
    }
  };

  const selectColor = (newColor) => {
    const newColorDoc = variantsByColor[newColor];
    setCurrentVariantItemDoc(newColorDoc);
    history.push(`${viewPath}/main/${mainItemId}/variant/${newColorDoc.id}`);
  };

  const selectSize = (newSize) => {
    setCurrentSize(newSize);
  };

  const getSizesList = () => {
    const options = _.cloneDeep(sizesTaxonomyType.data.param.options);
    _.forEach(options, option => {
      if (!_.includes(currentVariantItemDoc.data.sizes, option.value)) {
        option.hidden = true;
        // if (currentSize === option.value) {
        //   setCurrentSize(null);
        // }
      }
    });
    return options;
  };

  const onSensor = (_isVisible) => {
    // if (_isVisible && mainItemDoc?.id) {
    //   viewItem(item.id); // analytics
    // }
  };

  const EditBtn = () => (user?.userDoc?.data ? (
    <Link
    className="bg-zinc-200 hover:bg-zinc-300 rounded py-1 px-1.5 text-black text-xs"
    to={`/a/entity/${config.modules.cart.cartItemEntitySlug}/${currentVariantItemDoc?.data?.mainItemId}/form`}>
      Editar
    </Link>
  ) : null);

  return (
    <div className={`px-4 container-width-wide ${classes.blockContainer}`}>
      
      <Link to={filterPath} className="flex flex-row items-center gap-2 lg:hidden text-left text-sm text-brand-primary font-semibold underline" >
        <IonIcon icon={gridOutline} className="text-xl text-brand-primary"></IonIcon>
        Ver más opciones
      </Link>

      <hr className="border-gray-200 my-2 lg:hidden" />

      {!currentVariantItemDoc ? (
        <div className="py-12 flex place-content-center content-center items-center font-brand-main">
          <BadgeLoading className="text-brand-primary" />
        </div>
      ) : (<>
        <EntityDocListView
          {...entitySpects}
          doc={currentVariantItemDoc}
          classes={{ fieldLabel: "hidden" }}
          render={({ ViewData, mainAttr, mainImgAttr }) => (<>

            <VisibilitySensor minTopValue={0} onChange={onSensor}>
              <div className=""> 

                <div className="lg:hidden mb-4">
                  <ViewData field="name" classes={{ fieldContainer: 'text-2xl font-bold' }} />
                  <ViewData field="price" classes={{ fieldContainer: 'text-lg text-gray-500' }} decorator={priceFormat} />  
                  <EditBtn />
                </div>

                <div className="lg:flex">
                  {/* images */}
                  <ViewData field="variantImgs" classes={{ fieldContainer: 'lg:w-3/5' }} />

                  {/* options */}
                  <div className="my-6 lg:my-0 text-center lg:w-2/5 place-content-start">
                    <div className="space-y-8 py-8 lg:mx-4 border border-black shadow-md rounded-lg">
                      <Link to={filterPath} className={`
                        hidden lg:flex flex-row items-center gap-2
                        -mt-8 px-4 py-3 rounded-t-lg text-left text-sm 
                        text-brand-primary-contrast bg-brand-primary font-semibold underline
                      `}>
                        <IonIcon icon={gridOutline} className="text-xl text-brand-primary-contrast"></IonIcon>
                        Ver más opciones
                      </Link>
                      
                      <div className="px-3">
                        <ViewData field="name" classes={{ fieldLabel: "hidden", fieldContainer: 'text-3xl font-semibold mt-2' }} />
                        <ViewData field="price" classes={{ fieldLabel: "hidden", fieldContainer: 'text-xl text-gray-500 mt-1 mb-1' }} decorator={priceFormat} />  
                        <EditBtn />
                      </div>

                      {/* selector de color */}
                      <div className="">
                        <h3 className="mb-3 text-xs uppercase font-semibold">Color</h3>
                        <SelectButtonsWithColors colors={getColorsList()} current={currentVariantItemDoc.data.colors} onSelect={selectColor} />
                      </div>
                      
                      {/* selector de tamaños */}
                      <div className="">
                        <h3 className="mb-3 text-xs uppercase font-semibold">Tamaño</h3>
                        <SelectButtons options={getSizesList()} current={currentSize} onSelect={selectSize} 
                          trueClassName="    uppercase min-w-9 h-9 px-2 py-1 rounded-full text-lg bg-black font-bold text-white -top-1 relative"
                          falseClassName="   uppercase min-w-6 h-6 px-2 py-1 rounded-full text-xs bg-gray-200 font-semibold text-black"
                          hiddenClassName="  uppercase min-w-6 h-6 px-2 py-1 rounded-full text-xs bg-gray-50 text-gray-400 line-through cursor-not-allowed"
                        />
                      </div>

                      {!currentSize ? (
                        <div className="pt-6">
                          <a className={`addToCartRequirement py-2 px-4 inline-block mx-auto text-sm bg-blue-100 text-blue-700 content-center rounded-md cursor-default`}>
                            <FaCartPlus className="mr-2 h-5 w-5 inline"/>
                            Agregar al pedido
                          </a>
                        </div>
                      ) : (
                        <QtyButtons {...{
                          itemDoc: currentVariantItemDoc,
                          itemInBag, bagId, setItemToBag, params,
                          itemId: mainItemId,
                          price: currentVariantItemDoc.data.price
                        }} />
                      )}

                      <Tooltip anchorSelect=".addToCartRequirement" place="top" offset={40}>
                        Primero seleccioná el tamaño 👆🏻
                      </Tooltip>
                    </div>
                      
                    <div className="lg:mx-4 lg:mt-4">
                      <CurrentCartDetails cartPath={cartPath} history={history} />

                      <p className="mt-12 md:mt-6 text-gray-500">Preguntar disponibilidad</p>
                      <ContactBTN
                        message={`Hola, disponen de este producto para entrega inmediata? ${config.getURLprefix()}${getVarianPath(currentVariantItemDoc)}`} 
                        label={"¿Entrega inmediata?"}
                        classes={{ btnContainer: 'mx-auto' }}
                      />
                    </div>
                  </div>
                </div>
                
              </div>
            </VisibilitySensor>
          </>)}
        />
        
      </>)}

      <CurrentCartDetailsDrawer goToItemByParams={goToItemByParams} history={history} />
    </div>
  );
}