import { LayoutAdmin } from "./LayoutAdmin";
import { useModule } from "../../context/ModuleContext";
import PartOfModule from "../../components/Module/PartOfModule";
import config from "../../config";


export function AdminTabConfig(props) {
  const { isAllowed, user } = useModule();  

  return (
    <LayoutAdmin 
      history={props.history}
      title={"Configuraciones"}
      defaultHref={config.modules.panel.mainPath}
    >
      <div className="ion-padding">
        <PartOfModule
          type="main"
          action="AdminConfigBeforeContent"
          param={{ isAllowed, user }}
        />

        <PartOfModule
          type="main"
          action="AdminConfigContent"
          param={{ isAllowed, user }}
        />

        <PartOfModule
          type="main"
          action="AdminConfigAfterContent"
          param={{ isAllowed, user }}
        />
      </div>
    </LayoutAdmin>
  );
}