import _ from 'lodash';
import { LayoutAdmin } from "../modules/panel/LayoutAdmin";
import SectionCrudModel from "../components/SectionCrudModel";
import Model, { useStateSingleResult } from "../libs/ModelClass";
import { EntityDocListView } from "../components/EntityDocView";
import { getImageURL, parseAttrValParams } from "../libs/utils";
import PartOfModule from '../components/Module/PartOfModule';
import { useModule } from "../context/ModuleContext";
import { IonButton, IonContent, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import { useLocation, Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useMemo, useState } from 'react';
import { useEntityFullBySlug } from '../modules/entity/Entity';
import { 
  cog
} from 'ionicons/icons';
import { useAsyncMemo } from 'use-async-memo';
import { useModuleSettings } from '../modules/entity';
import BadgeLoading from '../components/ui/BadgeLoading';


export const useListDoc = ({
  entitySpecs,
  isAllowed,
  ExtraFields,
  outstandingOnly = true 
}) => ({ doc, routerLink, isSelected, classes, onClick }) => {
  if (!entitySpecs) { 
    return null;
  }
  const { entitySlug, taxonomyTypesDocList } = entitySpecs;
  return (
    <EntityDocListView
      {...entitySpecs}
      doc={doc}
      outstandingOnly={outstandingOnly}
      viewType='list'
      classes={{
        fieldContainer: "py-1 space-x-2 text-xs",
        fieldLabel: '!inline-block',
        fieldValue: '!inline-block',
        ...classes
      }}
      render={({ ViewData, mainAttr, mainImgAttr }) => (
        <div onClick={onClick} className={`flex flex-row ${isSelected ? 'text-brand-primary' : ''} ${onClick ? 'cursor-pointer' : ''} `}>
          {mainImgAttr && (doc?.data && doc.data[mainImgAttr] && doc.data[mainImgAttr].length) ? (
            <img src={doc?.data && doc.data[mainImgAttr] && doc.data[mainImgAttr].length && getImageURL(doc.data[mainImgAttr][0], 'xs')} 
            className="rounded shadow-md hover:shadow-xl h-[70px] w-[70px] object-cover" />
          ) : null}
          <div className="px-2 w-auto">
            <Link to={routerLink} className="block cursor-pointer">
              <ViewData field={mainAttr} classes={{ fieldContainer: `-mb-0.5 underline text-base leading-tight font-semibold ${isSelected ? 'text-brand-primary' : ''}` }} />
            </Link>

            {taxonomyTypesDocList.map((taxonomyType) => (
              (![mainAttr, mainImgAttr].includes(taxonomyType.data.nameSlug)) ? (
                <ViewData key={taxonomyType.data.nameSlug} field={taxonomyType.data.nameSlug} classes={{ fieldContainer: `text-xs font-normal text-gray-600` }} />
                // TODO: Agregar decorator y classes al TaxonomyType para View
              ) : null
            ))}

            <PartOfModule
              type="crud"
              action="UnderListItemTitle"
              entitySlug={entitySlug}
              param={{ doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug }} 
            />
            {ExtraFields ? (
              <ExtraFields {...{ doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug }} />
            ) : null}
          </div>
          <PartOfModule
            type="crud"
            action="UnderListItemHeader"
            entitySlug={entitySlug}
            param={{ doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug }} 
          />
        </div>
      )}
    />
  );
};

export const useCanAddMoreDocs = (entityDoc) => {
  const entitySlug = entityDoc?.data?.nameSlug;
  
  const canAddMoreDocs = useAsyncMemo(async () => {
    const ExtendedModel = Model.extend(entitySlug);
    if (!entityDoc?.data.limitMaxRecords) {
      return true;
    }
    let limitMaxRecords = _.toNumber(entityDoc?.data.limitMaxRecords);
    let allValidRecords = await ExtendedModel.getAll();
    allValidRecords = allValidRecords.filter(doc => !doc.data?.deleted);
    return limitMaxRecords > 0 && allValidRecords?.length < limitMaxRecords;
  }, [entitySlug]);
  
  if (!entityDoc) { 
    return false;
  }

  return canAddMoreDocs;
};

// TODO
// Mostrar/Ocultar botón ADD
// Mostrar barra de filtros
export function Content(props) {  
  let {
    entityDoc,
    history,
    parsedParams,
    navigateTo,
    fetchItems,
    attachPrefix,
    filterMenuSlug
  } = props;
  const { isAllowed } = useModule();
  const entitySlug = entityDoc?.data?.nameSlug;
  const ExtendedModel = Model.extend(entitySlug);
  const location = useLocation();
  const [ refresh, doRefresh ] = useState(0);
  const entitySpecs = useEntityFullBySlug({ entitySlug, filterMenuSlug });
  const ListDoc = useListDoc({ entitySpecs, isAllowed });
  const canAddMoreDocs = useCanAddMoreDocs(entityDoc);

  useEffect(() => {
    doRefresh(refresh + 1);
  }, [location]);

  const { moduleSettings } = useModuleSettings( entitySlug );

  const validateForm = () => {};
  const handleBeforeSave = () => {};
  const handleDelete = () => {};
  
  const ExtraActions = () => (<>
    <PartOfModule
      type="crud"
      action="ListHeaderExtraActions"
      entitySlug={entitySlug}
      param={{ Model, isAllowed }}
    />
    {isAllowed('panel', ['tab:config']) ? (
      <IonButton
        routerLink={attachPrefix(`/a/config/entity-creator/${entityDoc?.data.id}/form`)}
        size="small"
        fill="clear"
        color="secondary"
      >
        <IonIcon icon={cog}></IonIcon>
      </IonButton>
    ) : null}
  </>);

  const ListBtns = ({ doc }) => {
    if ( isAllowed(entitySlug, ['read']) && moduleSettings.showBtnShow ) {
      return <IonButton
        size="small"
        fill="clear"
        color="secondary"
        routerLink={attachPrefix(`/a/entity/${entityDoc?.data.nameSlug}/${doc.id}`)}
      >
        Ver
      </IonButton>
    }
    return null;
  };

  if (entitySpecs?.isLoading) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-primary" />
      </div>
    );
  }

  let dataMode = "all";
  let listStyle = "list";

  if (!fetchItems && entitySpecs?.filterMenuDocList?.find(item => item.data.nameSlug === filterMenuSlug)) {
    dataMode = "filter-paginated";
    listStyle = "filter-paginated";
  }

  const navigateToToUse = navigateTo || ((doc) => (
    attachPrefix(`/a/entity/${entitySpecs?.entitySlug}/${doc ? doc.id : 'new'}/form`)
  ));

  return (
    <SectionCrudModel
      history={history}
      model={ExtendedModel}
      entitySlug={entitySlug}
      editStyle="route"
      dataMode={dataMode}
      listStyle={listStyle}
      
      navigateTo={navigateToToUse}
      refresh={refresh}

      // callbacks 
      fetchItems={fetchItems}
      onValidation={validateForm}
      handleBeforeSave={handleBeforeSave}
      onDelete={handleDelete}

      // UI
      ListItem={ListDoc}
      ListBtns={ListBtns}
      ExtraActions={ExtraActions}
      {...moduleSettings}

      // show
      showBtnAdd={canAddMoreDocs}

      // dataMode 'filter-paginated'
      filterPath={attachPrefix(`/a/entity/${entityDoc?.data.nameSlug}`)} // TODO add context
      entitySpecs={entitySpecs}
      queryParams={parsedParams}
    />
  );
}

export function AdminEntityCrud(props) {
  let { context, match, history, location } = props;
  // const { attachPrefix } = usePanel();
  const attachPrefix = (url) => url;
  const entitySlug = context?.entitySlug || match.params.entitySlug;
  const parsedParams = useMemo(() => {
    return {
      ...parseAttrValParams( location.hash.replace('#/', '') )
    };
  }, [location.hash]);

  const ExtendedModel = Model.extend('entities');
  const entityDoc = useStateSingleResult({
    Model: ExtendedModel,
    nameSlug: entitySlug
  });
  const breadcrumbs = context?.breadcrumbs || [
    {
      url: '/a/entity',
      title: 'Listados'
    },
    {
      title: entityDoc?.data?.name
    }
  ];
  
  return (
    <LayoutAdmin 
      history={props.history} 
      defaultHref={context?.defaultBackRoute || `/a`}
      title={entityDoc?.data?.name}
      breadcrumbs={breadcrumbs}
      TitleToolbarRight={context?.TitleToolbarRight}
    >
      <div className="ion-padding">
        {entityDoc?.data && 
          <Content
            {...{
              entityDoc,
              parsedParams,
              attachPrefix
            }}
            navigateTo={context?.goToForm}
            fetchItems={context?.fetchItems}
            filterMenuSlug={context?.filterMenuSlug || 'panelSearch'}
            {...props} 
          />
        }
      </div>
    </LayoutAdmin>
  );
}
