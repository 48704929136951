/////////////////////////////////////////
// Theme specification
/////////////////////////////////////////

export default {
  /////////////////////////////////////////
  // FONTS
  /////////////////////////////////////////
  fonts: {
    mainFont: 'Barlow',
    mainFontAlt: 'sans-serif',
    secondaryFont: 'Montserrat',
    secondaryFontAlt: 'sans-serif',
  },

  /////////////////////////////////////////
  // COLORS
  /////////////////////////////////////////
  colors: {
    /** primary **/
    'primary': '#000000',
    'primary-contrast': '#ffffff',
    'primary-shade': '#7e7a7a',
    'primary-tint': '#e2dada',

    /** secondary **/
    'secondary': '#fbbf24',
    'secondary-contrast': '#000000',
    'secondary-shade': '#d7e6f8',
    'secondary-tint': '#41a0eb',

    /** tertiary **/
    'tertiary': '#334155',
    'tertiary-contrast': '#932a7d',
    'tertiary-shade': '#995656',
    'tertiary-tint': '#c9a0a0',

    /** success **/
    'success': '#2dd36f',
    'success-contrast': '#ffffff',
    'success-shade': '#28ba62',
    'success-tint': '#42d77d',

    /** warning **/
    'warning': '#ff6700',
    'warning-contrast': '#ffffff',
    'warning-shade': '#e0ac08',
    'warning-tint': '#ffca22',

    /** danger **/
    'danger': '#eb445a',
    'danger-contrast': '#ffffff',
    'danger-shade': '#cf3c4f',
    'danger-tint': '#ed576b',

    /** dark **/
    'dark': '#202E39',
    'dark-contrast': '#ffffff',
    'dark-shade': '#1e2023',
    'dark-tint': '#383a3e',

    /** medium **/
    'medium': '#92949c',
    'medium-contrast': '#ffffff',
    'medium-shade': '#808289',
    'medium-tint': '#9d9fa6',

    /** light **/
    'light': '#f4f5f8',
    'light-contrast': '#000000',
    'light-shade': '#d7d8da',
    'light-tint': '#f5f6f9',
  },
};