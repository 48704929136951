import _ from 'lodash';
import toast from 'react-hot-toast';
import { EntityDocListView } from '../../components/EntityDocView';
import { FieldViewSelectButtonsWithColors } from '../../components/Form/FormFieldSelectButtonsWithColors';
import { getImageURL, openWhatsApp, priceFormat } from "../../libs/utils";
import { useEntityFullBySlug } from '../entity/Entity';
import { usePanelSheet } from "../../components/ui/PanelSheet";
import BtnLoading from '../../components/ui/BtnLoading';
import config from '../../config';
import { QtyButtons } from './QtyButtons';
import { useCart } from './useCartData';
import { useEffect, useMemo } from 'react';
import ContactBTN from '../../components/ContactBTN';
import { getCartMessage } from './BlockCartView';


const variantsEntitySlug = config.modules.cart.cartItemVariantsEntitySlug;
const bagId = 0; // main bag

export const CurrentCartDetailsDrawer = (props) => {
  let { 
    goToItemByParams,
    history
  } = props;
  const {
    isDetailsOpen, setIsDetailsOpen, openDetailsDrawer,
    ItemMainModel, ItemVariantModel, CategoryModel, TypeModel, 
    fetchItems, items, itemsTypes, itemsCategories,
    bags, getBagById, getBagTotal, isItemInBag, setItemToBag, getItemsOfBag,
    cartDoc, saveNewCart, saveCurrentCart, closeCart,
    doPopulateBags,
    totalPriceItems
  } = useCart();
  const mainBag = getBagById(bagId);
  const { taxonomyTypesDocList, mainAttr, mainImgAttr } = useEntityFullBySlug({ entitySlug: variantsEntitySlug });
  const { panelRef, paneInstance, isPaneOpen, setIsPaneOpen, openPanel, closePanel } = usePanelSheet(({ windowHeight }) => {
    return {
      parentElement: '#single-pane-container',
      breaks: {
        top: { 
          enabled: true, 
          height: (windowHeight || 900) - 70,
          bounce: true
        },
        middle: { enabled: false },
        bottom: { enabled: false },
      },
      events: {
        onDidDismiss: () => {
          setIsDetailsOpen(false);
        },
      },
      initialBreak: 'top',
      backdrop: true,
      showDraggable: false,
      dragBy: ['.no-draggable'],
      draggableOver: true,
      bottomClose: false,
      buttonDestroy: true,
    };
  });

  useEffect(() => {
    if (isDetailsOpen) {
      openPanel();
    }
  }, [isDetailsOpen]);

  const colorsTaxonomyType = taxonomyTypesDocList?.find((taxonomyType) => taxonomyType.data.nameSlug === 'colors');

  const doGoToItemByParams = (mainId, variantId, params) => {
    goToItemByParams && goToItemByParams(mainId, variantId, params)
    // history.push(`/${variantsEntitySlug}/${mainId}/${variantId}`);
    closePanel();
  };

  return (<>
    {/* Detalles del pedido */}
    <div id="single-pane-container" className="drawer-details">
      <div ref={panelRef} className="pb-64 w-full h-screen bg-white">
        {mainBag?.itemsInBag?.length ? (<>
          <div className="px-3 xs:px-4 pt-1 pb-1 w-full bg-white z-20 fixed shadow-md">
            <h3 className="text-xl font-bold text-center">
              Detalles del pedido
            </h3>
          </div>

          <div className="px-3 pt-12">
            <h3 className="mb-4 text-md font-semibold text-center">
              Productos seleccionados
            </h3>
            <div className="grid grid-cols-1 gap-2 md:gap-5 items-start cursor-default">
              {mainBag.itemsInBag.map((itemInBag, index) => {
                const variantDoc = itemInBag.itemDoc;
                return (
                  <EntityDocListView
                    key={index}
                    doc={variantDoc}
                    taxonomyTypesDocList={taxonomyTypesDocList}
                    mainAttr={mainAttr}
                    mainImgAttr={mainImgAttr}
                    classes={{
                      fieldLabel: "hidden"
                    }}
                    render={({ ViewData, mainAttr, mainImgAttr }) => (
      
                      <div className="flex flex-row border border-gray-300 rounded-xl overflow-hidden text-center relative grow">
                        {/* image xs */}
                        <img src={variantDoc?.data && getImageURL(variantDoc?.data['variantImgs'][0], 'xs')} 
                          className=" h-64 w-44 object-cover cursor-pointer"
                          onClick={() => doGoToItemByParams(itemInBag.id, variantDoc.id, itemInBag.params)}
                        /> 
                        {/* data */}
                        <div className="pb-1 flex-1">
                          <div className="my-2 px-1 space-y-1 md:space-y-2">
      
                            <div className="cursor-pointer" onClick={() => doGoToItemByParams(itemInBag.id, variantDoc.id, itemInBag.params)}>
                              <ViewData field="name" classes={{ fieldContainer: 'px-1 md:text-lg lg:text-xl font-semibold leading-tight underline' }} />
                              <ViewData field="price" classes={{ fieldContainer: 'text-sm sm:text-md md:text-lg text-gray-600 lg:my-0' }} decorator={priceFormat} />
                              {/* params */}
                              <div className="flex flex-row gap-2 place-content-center items-center">
                                <FieldViewSelectButtonsWithColors {...{ taxonomyType: colorsTaxonomyType, value: itemInBag.params.color }} showLabel={false} />
                                <div className="font-bold text-black uppercase text-md">
                                  {itemInBag.params.size}
                                </div>
                              </div>
                            </div>
      
                            <QtyButtons {...{
                              bagId, setItemToBag, itemInBag, 
                              itemId: itemInBag.id,
                              params: itemInBag.params,
                              price: variantDoc?.data.price,
                              onChange: doPopulateBags,
                              showIncludedLabel: false
                            }} />
      
                          </div>
                        </div>
                      </div>
                      
                    )}
                  />
                );
              })}
            </div>
          </div>
        </>) : null}
      </div>
    </div>

  </>);
};

export const CurrentCartDetails = (props) => {
  let { 
    cartPath,
    history
  } = props;
  const {
    isDetailsOpen, setIsDetailsOpen, openDetailsDrawer,
    ItemMainModel, ItemVariantModel, CategoryModel, TypeModel, 
    fetchItems, items, itemsTypes, itemsCategories,
    bags, getBagById, getBagTotal, isItemInBag, setItemToBag, getItemsOfBag,
    cartDoc, saveNewCart, saveCurrentCart, closeCart,
    doPopulateBags,
    totalPriceItems
  } = useCart();

  const confirm = async () => {
    history.push(`${cartPath}/id/${cartDoc?.id}`);
    setTimeout(() => {
      toast('Se confirmó el pedido', { icon: "👍🏻" });
    }, 3000);
    closeCart();
    setTimeout(() => {
      toast('Avisá a la tienda', { icon: '📨' });
    }, 6000);
    setTimeout(() => {
      toast('Se abrirá WhatsApp en breve', { icon: '📨' });
    }, 8000);
    setTimeout(() => {
      openWhatsApp({
        number: config.number, 
        message: getCartMessage(cartDoc?.id)
      });
    }, 12000);
  };

  const TotalPanel = useMemo(() => ({ classes = {} }) => {
    return (totalPriceItems ? (
      <div className={`
        fixed left-0 w-screen bottom-0 ${classes.totalContainer}
      `}>
        {/* fixed footer */}
        <div className={`min-w-40 mr-8 flex place-content-end ${classes.contactBtn}`}>
          <ContactBTN className={'mb-4 lg:mb-4 bg-brand-dark'} />
        </div>
        {/* card */}
        <div className={`py-4 bg-white text-center border-t-2 border-black shadow-xl shadow-black ${classes.totalCard}`}>
          <h3 className="shaked-cascade-1 text-black text-base font-semibold uppercase leading-none tracking-wide">
            total
          </h3>
          <h3 className="mb-3 text-amber-600 text-xl font-normal leading-none tracking-wide">
            {priceFormat(totalPriceItems)}
          </h3>

          {!isDetailsOpen ? (
            <BtnLoading 
              label="Detalles del pedido"
              onClickAsync={openDetailsDrawer} 
              withLoading 
              className="shaked-cascade-2 mx-auto px-6 p-2 bg-gray-600 text-white text-xs hover:scale-105 shadow-md shadow-brand-red/30 rounded-md inline-block"
            />
          ) : (
            <BtnLoading
              label="Confirmar"
              onClickAsync={confirm} 
              withLoading 
              className="shaked-cascade-2 mx-auto px-6 p-2 bg-amber-600 text-white text-xs hover:scale-105 shadow-md shadow-brand-red/30 rounded-md inline-block"
            />
          )}
        </div>
      </div>
    ) : null)
  }, [totalPriceItems, isDetailsOpen]);

  return (<>
    {/* Confirmation pane */}
    <TotalPanel classes={{
      totalContainer: 'z-[60] lg:z-10 lg:relative lg:w-full',
      totalCard: 'lg:shadow-md lg:rounded-lg lg:border-2 lg:border-amber-600 lg:py-6',
      contactBtn: 'lg:hidden'
    }} />
    <TotalPanel classes={{
      totalContainer: `z-[80] ${isDetailsOpen ? '' : 'hidden'}`
    }} />
  </>);
};