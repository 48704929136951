import { useEffect, useState } from "react";


export default function useOnResize(listener) {
  const [data, setData] = useState();

  useEffect(() => {
    const handleResize = () => {
      const result = listener();
      setData(result);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return data;
}