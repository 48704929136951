import { useState } from 'react';
import CtaGrid from '../../../components/ui/CtaGrid';


export default function CtaAccordionBlock({ data }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="px-4 pt-20 pb-40 text-black text-center">
      <div className="container-width">
        <div className="pb-5 md:pt-10">
          <h3 className="text-lg lg:text-xl font-brand-secondary md:mb-2">{data.title}</h3>
          <h3 className="text-3xl md:text-4xl font-brand-secondary text-brand-slate font-bold">{data.subtitle}</h3>
        </div>

        <div className="flex justify-center gap-2 md:gap-4 flex-wrap font-brand-secondary">
          {data.items.map((item, index) => (
            <div
              key={index}
              className={`cursor-pointer bg-white border-brand-secondary border py-1.5 md:py-4 shadow-sm hover:shadow-lg rounded-sm ${index === activeIndex ? 'border-brand-secondary border-2 lg:border-4' : ''}`}
              onClick={() => handleAccordionClick(index)}
            >
              <div className="accordion-title px-4 font-semibold md:text-lg cursor-pointer font-brand-secondary">
                {item.title}
              </div>
            </div>
          ))}
        </div>

        {data.items.map((item, index) => (
          <div key={index} className={`mt-4 ${index === activeIndex ? 'block' : 'hidden'}`}>
            {index === activeIndex && (
              <div className=" border-brand-secondary bg-white border-2 lg:border-4 py-4 mx-3 shadow-sm rounded-sm">
                <div className="accordion-content px-2 py-2 md:text-lg font-brand-secondary">
                  {item.content}
                  <CtaGrid items={[item]} />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
