import { Field } from 'react-final-form';
import _ from 'lodash';
import { ShowFieldHelp } from './formParts';
import { RawInputTextId } from '.';


const FormFieldTextId = (props) => {
  let {
    name,
    title,
    fieldsRequired = null,
    validate,
    // disabled = false,
    showLabel = true,
    showClearBtn = true,
    onChange,
    Render,
    minLength,
    maxLength
  } = props;

  const validateField = (value) => {
    let error;
    if (value?.length) {
      if (minLength && value?.length < parseInt(minLength)) {
        error = `El valor debe tener al menos ${minLength} caracteres`;
      } else if (maxLength && value?.length > parseInt(maxLength)) {
        error = `El valor debe tener como máximo ${maxLength} caracteres`;
      }
    }
    return error;
  };

  return (
    <div className={`pb-3 ${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={(args) => validateField(args)}>
        {({ input, meta }) => (
          <>
            {showLabel ? (
              <div className={props?.classes?.fieldLabel}>
                <span
                  className={`mr-3 ${
                    (input.value || input.value) && meta.error ? '!text-red-600' : ''
                  }`}
                >
                  {title}
                  {((input.value || input.value) && meta.error) || (_.includes(fieldsRequired, name) && !input.value)
                    ? '*'
                    : ''}
                </span>
                {showClearBtn && input?.value ? (
                  <button
                    type="button"
                    className="px-1.5 py-0.5 font-regular text-xs bg-gray-100 text-gray-400 rounded-full hover:text-gray-800 inline-block"
                    onClick={() => input?.onChange(undefined)}
                  >
                    X
                  </button>
                ) : ''}
              </div>
            ) : ''}
            <RawInputTextId
              value={input.value}
              className={props?.classes?.fieldInput || ''}
              onChange={(value) => {
                input.onChange(value); 
                onChange && onChange(value);
              }}
              minLength={parseInt(minLength)}
              maxLength={parseInt(maxLength)}
              {...props}
            />
            {/* error */}
            <ShowFieldHelp name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} {...props} />
          </>
        )}
      </Field>
    </div>
  );
};

export default FormFieldTextId;