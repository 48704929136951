import { useEffect, useMemo, useState } from "react";
import slugify from "slugify";
import _ from "lodash";
import Model from "../../libs/ModelClass";
import { useListDoc } from "../../routes/AdminEntityCrud";
import { useEntityFullBySlug } from "../../modules/entity/Entity";
import { useModule } from "../../context/ModuleContext";


export const RawInputSelectArea = ({ 
  value,
  onChange,
  className,
  entitySlug,
  title
}) => {
  const [ query, setQuery ] = useState();
  const [ docList, setDocList ] = useState([]);

  const { isAllowed } = useModule();
  const entitySpects = useEntityFullBySlug({ entitySlug });
  const ListDoc = useListDoc({ entitySpects, isAllowed });

  const selectedDoc = useMemo(() => {
    return docList.find(doc => doc.id  === value);
  }, [docList, value]);
  
  useEffect(() => {
    !docList?.length && getAllDocs();
  }, []);
  
  const getAllDocs = async () => {
    if (docList.length) { return; }
    const ExtendedModel = Model.extend(entitySlug);
    const allDocs = await ExtendedModel.getAll();
    let filteredDocs = [];
    allDocs.forEach((doc) => {
      if (!doc.data.deleted && doc.data.available) {
        doc.data.nameSlug = slugify(doc.data.name, { lower: true, replacement: ' ' });
        filteredDocs.push(doc);
      }
    });
    filteredDocs = _.sortBy(filteredDocs, ['data.nameSlug']);
    setDocList(filteredDocs);
  };

  const setQueryData = (e) => {
    setQuery(e.target.value);
  };

  const filterList = () => {
    if (query) {
      return docList.filter(doc => doc.data.nameSlug.includes( slugify(query, { lower: true, replacement: ' ' }) ));
    }
    return docList;
  };

  return (
    <div className="">
      {/* input */}
      <div className="mb-4">
        <input
          type="text"
          value={query}
          onChange={setQueryData}
          className="shadow appearance-none text-center border rounded w-full font-normal py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Buscar por nombre"
        />
      </div>
      {/* list */}
      <div className="h-[40vh] overflow-y-scroll rounded-md border border-gray-300 flex flex-col gap-4 p-2">
        {filterList().map((doc, index) => (
          <ListDoc doc={doc} key={index} onClick={() => onChange(doc.id)} isSelected={selectedDoc?.id === doc.id} />
        ))}
      </div>
    </div>
  );
}