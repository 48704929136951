// import { IonIcon } from "@ionic/react";
// import { 
//   logoGoogle
// } from 'ionicons/icons';
// import { useAuth } from "../../user/AuthContext";
// import { useLoginUtils } from "../../user/Login";
// import BtnLoading from "../../../components/ui/BtnLoading";

import ContactBTN from "../../../components/ContactBTN";
import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import BtnSignUpModal from "../../../components/ui/BtnSignUpModal";
import { lineBreaks } from "../../../libs/utils";



export default function () {
  return {
    type: 'cta',
    variation: 'hero',
    template: CtaAHero
  };
};

export function CtaAHero({ data, history }) {
  // const { user, loginWithGoogleAndVerify } = useAuth();
  // const { redirectOnLogin, handleGoogleSignin } = useLoginUtils(user, loginWithGoogleAndVerify, history);

  return (
    <div className="py-32 xs:py-40 lg:py-44 px-4 xs:px-10 md:px-20 bg-gradient-to-br from-brand-primary to-brand-secondary bg-fixed"> 
      <div className="text-center px-4">
        <AnimationAddClassWhenVisible classToAdd="animate-fadeIn">
          <div className="text-center mx-auto sm:max-w-sm">
            {data.title ? (<h3 className="text-4xl md:text-4xl font-bold font-brand-main text-brand-primary-contrast">{lineBreaks(data.title)}</h3>) : null}
            {data.subtitle ? (<h3 className="text-lg lg:text-xl font-bold font-brand-main text-brand-primary-contrast md:mb-2">{lineBreaks(data.subtitle)}</h3>) : null}
            {data.content ? (
              <p className="mt-4 mx-auto max-w-xs px-4 pb-4 text-lg font-brand-main">{lineBreaks(data.content)}</p>
            ) : null}
          </div>
        </AnimationAddClassWhenVisible>
        {data.ctaType === "whatsapp" ? (
          <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
            <div className="mt-4">
              <ContactBTN className={'mx-auto'} label={lineBreaks(data.ctaLabel)} design="large" />
            </div>
          </AnimationAddClassWhenVisible>
        ) : null}
        {data.ctaType === "signup" ? (
          <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
            <div className="mt-4">
              <BtnSignUpModal strings={data} history={history} design="large" classes={{btnButton: 'mx-auto'}} />
            </div>
          </AnimationAddClassWhenVisible>
        ) : null}
        {/* <BtnLoading
          label="Acceder con Gmail"
          icon={<IonIcon icon={logoGoogle} className="!-mb-1 w-5 h-5 text-red-600" />}
          onClickAsync={handleGoogleSignin}
          className="block mx-auto !items-start !justify-start px-3 py-2 w-[190px] !bg-white !text-gray-900 shadow-md !no-underline hover:underline"
          colorClass="text-gray-400 hover:text-gray-700"
        /> */}
      </div>
    </div>
  );
}