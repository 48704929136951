import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import dayjs from 'dayjs';
import { IonIcon } from '@ionic/react';
import {
  gridOutline
} from 'ionicons/icons';
import { useEntityFullBySlug } from '../entity/Entity';
import { getImageURL, priceFormat } from '../../libs/utils';
import { EntityDocListView } from '../../components/EntityDocView';
import ContactBTN from '../../components/ContactBTN';
import CopyBtn from '../../components/CopyBtn';
import { FieldViewSelectButtonsWithColors } from '../../components/Form/FormFieldSelectButtonsWithColors';
import BadgeLoading from '../../components/ui/BadgeLoading';
import config from '../../config';


export default function () {
  return {
    type: 'cartView',
    variation: 'public',
    template: CartView,
    params: {
      filterPath: {
        type: 'string',
        defaults: '/m/products/filter'
      },
      viewPath: {
        type: 'string',
        defaults: '/m/products/view'
      },
      imgProportion: {
        type: 'string',
        options: ['vertical-square', 'vertical-wide'],
        defaults: 'vertical-square'
      },
      classes: {
        type: 'object',
        defaults: {}
      }
    }
  };
};

const bagId = 0;

export const getCartMessage = (cartId) => {
  return `Hola! este es mi enlace de pedido ${config.getURLprefix()}/m/carts/view/id/${cartId}`;
};

const Content = (props) => {
  let {
    cartId,
    config,
    Model,
    history,
    viewPath
  } = props;
  const cartsEntitySlug = config.modules.cart.cartsEntitySlug;
  const variantsEntitySlug = config.modules.cart.cartItemVariantsEntitySlug;
  const [ bagItemsDetails, setBagItemsDetails ] = useState([]);
  const [ cartDoc, setCartDoc ] = useState();
  const entitySpects = useEntityFullBySlug({ entitySlug: variantsEntitySlug });

  const colorsTaxonomyType = entitySpects?.taxonomyTypesDocList?.find((taxonomyType) => taxonomyType.data.nameSlug === 'colors');

  useEffect(() => {
    fetchCartDetailsData();
  }, []);

  const fetchCartDetailsData = async () => {
    const CartModel = Model.extend(cartsEntitySlug);
    const VariantsModel = Model.extend(variantsEntitySlug);
    const cartDoc = await CartModel.findById(cartId);
    if (!cartDoc) { return; }
    const itemsInBag = cartDoc?.data?.itemsInBags ? cartDoc.data.itemsInBags[bagId].bagItems : [];
    const populatedItemsInBag = [];
    for (const itemInBag of itemsInBag) {
      const itemDoc = await VariantsModel.findById(itemInBag.params.variantId);
      populatedItemsInBag.push({
        ...itemInBag,
        itemDoc
      });
    }
    setBagItemsDetails(populatedItemsInBag);
    setCartDoc(cartDoc);
  };

  const goToItemByParams = (mainId, variantId, params) => {
    history.push(`${viewPath}/main/${mainId}/variant/${variantId}`);
  };

  if (!cartDoc) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-primary" />
      </div>
    );
  }

  return (
    <div className="py-12">

      {/* title */}
      <div className="text-center">
        <h3 className="text-lg">
          <span className="text-black">Pedido</span>
          <span className="ml-3 px-3 py-1.5 font-mono bg-black text-white rounded-md">{cartDoc.id}</span>
        </h3>
        <div className={`mt-2 py-0.5 text-sm text-black`}>{dayjs(cartDoc?.data?.createdAt).format('LLLL')}</div>
      </div>

      {/* details */}
      {/* whatsapp */}
      <div className="mt-10 max-w-xl mx-auto p-5 flex flex-col bg-gray-100 border border-black rounded-lg text-center text-stone-500 shadow-lg">
        <h3 className="text-2xl font-semibold text-gray-800 shaked-cascade-1">Avisá a la tienda</h3>
        <span className="text-sm text-stone-500 shaked-cascade-2">Directamente por WhatsApp o copia y pega el enlace de pedido manualmente</span>
        {/* btns */}
        <div className="flex flex-col md:flex-row place-items-center w-full">
          <div className="w-full sm:w-1/2">
            <ContactBTN
              className="mt-4 mx-auto"
              message={getCartMessage(cartDoc.id)} 
              label="Abrir WhatsApp"
            />
          </div>
          <div className="w-full sm:w-1/2">
            <CopyBtn className="bg-white" text={getCartMessage(cartDoc.id)} />
          </div>
        </div>
      </div>

      <div className="mt-10 px-3 max-w-3xl mx-auto">

        <div className="text-center mb-8">
          <h3 className="text-md font-semibold text-gray-500">Total del pedido</h3>
          <div className="text-lg font-semibold">{priceFormat(cartDoc?.data?.total)}</div>
        </div>

        <h3 className="mb-4 text-md font-semibold text-center text-gray-500">
          Productos seleccionados
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-5 items-center cursor-default">
          {bagItemsDetails.map((itemInBag, index) => {
            const variantDoc = itemInBag.itemDoc;
            return (
              <EntityDocListView
                key={index}
                {...entitySpects}
                doc={variantDoc}
                classes={{
                  fieldLabel: "hidden"
                }}
                render={({ ViewData, mainAttr, mainImgAttr }) => (
  
                  <div className="flex flex-row border border-gray-300 rounded-xl overflow-hidden text-center relative grow">
                    {/* image xs */}
                    <img src={variantDoc?.data && getImageURL(variantDoc.data['variantImgs'][0], 'md')} 
                      className="h-64 w-44 object-cover cursor-pointer"
                      onClick={() => goToItemByParams(itemInBag.id, variantDoc.id, itemInBag.params)}
                    /> 
                    {/* data */}
                    <div className="bg-white flex-1 py-2 px-1 cursor-pointer flex flex-col gap-3 place-content-center" 
                      onClick={() => goToItemByParams(itemInBag.id, variantDoc.id, itemInBag.params)}
                    >
                      <div className="">
                        <ViewData field="name" classes={{ fieldContainer: 'pb-0.5 px-1 md:text-lg lg:text-xl font-semibold leading-tight underline' }} />
                        <ViewData field="price" classes={{ fieldContainer: 'text-sm sm:text-md md:text-lg text-gray-600' }} decorator={priceFormat} />
                      </div>
                      {/* params */}
                      <div className="flex flex-row gap-2 place-content-center items-center">
                        <FieldViewSelectButtonsWithColors {...{ taxonomyType: colorsTaxonomyType, value: itemInBag.params.color }} showLabel={false} />
                        <div className="font-bold text-black uppercase text-md">
                          {itemInBag.params.size}
                        </div>
                      </div>

                      <div className="">
                        <h3 className="text-xs uppercase font-semibold text-gray-500">Cantidad</h3>
                        <div className="text-sm uppercase font-semibold">{itemInBag.qty}</div>
                      </div>

                      <div className="">
                        <h3 className="text-xs font-semibold text-gray-500">Total</h3>
                        <div className="text-sm uppercase font-semibold">{priceFormat(itemInBag.qty * itemInBag.price)}</div>
                      </div>
                    </div>
                  </div>
                  
                )}
              />
            );
          })}
        </div>
      </div>

    </div>
  );
};

function CartView(props) {
  let {
    data,
    history,
    parsedParams,
    // entitySlug,
    // action,
    // config,
    user,
    isAllowed,
    Model,
    isMinBreakpointActive,
    location,
    filterMenuSlug,
    filterPath,
    viewPath,
    cartPath,
    showFilters,
    imgProportion,
    classes = {}
  } = props;

  return (
    <div className={`pt-16 md:pt-20 pb-4 px-4 container-width-wide ${classes.blockContainer}`}>
      
      <Link to={filterPath} className="flex flex-row items-center gap-2 text-left text-sm text-brand-primary font-semibold underline" >
        <IonIcon icon={gridOutline} className="text-xl text-brand-primary"></IonIcon>
        Ir a la Tienda
      </Link>

      <hr className="border-gray-200 my-2 md:my-4" />

      {parsedParams.id ? (<>
        <Content cartId={parsedParams.id} {...props} />
      </>) : ''}
    </div>
  );
}