import BlockStack from "../../modules/blockStack/BlockStack";
import { LayoutHome } from "../../modules/layoutHome/LayoutHome";


export function RouteLayoutBlock (props) {
  return (
    <LayoutHome {...props} specDesign={props.specDesign}>
      <BlockStack specStack={props.specStack} {...props} />
    </LayoutHome>
  );
}

export function routePageStack ({ specDesign, specStack }) {
  return function RouteLayoutBlock (props) {
    return (
      <LayoutHome {...props} specDesign={specDesign}>
        <BlockStack specStack={specStack} {...props} />
      </LayoutHome>
    );
  }
}