import { Link } from "react-router-dom";
import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import ContactBTN from "../../../components/ContactBTN";
import BtnSignUpModal from "../../../components/ui/BtnSignUpModal";
import { lineBreaks } from "../../../libs/utils";


export default function () {
  return {
    type: 'ctaMenu',
    variation: 'grid',
    template: CtaMenuAGrid
  };
};

export function CtaMenuAGrid({ data, history }) {
  return (
    <div className="py-32 xs:py-40 lg:py-44 px-4 xs:px-10 md:px-20 bg-gradient-to-br from-brand-secondary to-brand-primary bg-fixed"> 
      <div className="container-width text-left">
        <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[100ms]">
          <div className="">
            <h2 className="text-white font-brand-main font-semibold text-4xl md:text-6xl mb-2">
              {lineBreaks(data?.title)}
            </h2>
          </div>
        </AnimationAddClassWhenVisible>
        <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[200ms]">
          <div className="mb-8">
            <h2 className="text-white font-brand-main font-semibold text-xl md:text-2xl">
              {lineBreaks(data?.subtitle)}
            </h2>
            <div className="mt-4">
              {data.ctaType === "whatsapp" ? (
                <ContactBTN className={'mx-auto'} label={lineBreaks(data.ctaLabel)} design="small" />
              ) : null}
              {data.ctaType === "signup" ? (
                <BtnSignUpModal strings={data} history={history} design="small" />
              ) : null}
            </div>
          </div>
        </AnimationAddClassWhenVisible>

        <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[400ms]">
          <div className="grid grid-cols-2 gap-2 xs:gap-4">
            {data?.items.map((item, index) => (
              <Link to={item.ctaLink} className="bg-white rounded-md shadow-lg p-2 xs:p-4 hover:shadow-xl hover:-translate-y-1 hover:scale-105 transition-transform" key={index}>
                <img src={item.image} alt={item.title} className="w-full h-32 md:h-44 lg:h-48 object-cover" />
                <h3 className="text-black font-brand-main font-semibold underline text-2xl my-2">
                  {lineBreaks(item.title)}
                </h3>
                <p className="text-gray-500 font-brand-secondary font-normal text-base xs:text-lg">
                  {lineBreaks(item.content)}
                </p>
              </Link>
            ))}
          </div>
        </AnimationAddClassWhenVisible>
      </div>
    </div>
  );
}