import { useEffect, useState } from 'react';
import _ from 'lodash';
import { priceFormat } from "../../libs/utils";
import { FaCartPlus } from 'react-icons/fa';
import BtnLoading from '../../components/ui/BtnLoading';


export const QtyButtons = ({ itemDoc, itemInBag, bagId, itemId, setItemToBag, params, price, onChange = _.noop, showIncludedLabel = true }) => {
  const [ qtyInBag, setQtyInBag ] = useState( itemInBag?.qty || 1 );
  
  const isEditing = itemInBag?.qty !== qtyInBag && params?.size !== 'none' && params?.color !== 'none';

  useEffect(() => {
    setQtyInBag(itemInBag?.qty || 1);
  }, [itemInBag?.qty]);

  const restOne = () => {
    if (qtyInBag > 1) {
      setQtyInBag( qtyInBag - 1 );
    }
  };

  const addOne = () => {
    setQtyInBag( qtyInBag + 1 );
  };

  const setVal = (e) => {
    let newVal = parseInt(e.target.value);
    if (newVal > 1) {
      setQtyInBag( newVal );
    }
  };

  return (
    <>
      <div className="">
        <h3 className="uppercase font-semibold text-xs">Cantidad</h3>
        <div className="mx-auto w-28 rounded-sm cursor-point border-2 border-gray-200 flex flex-row">
          {/* remove */}
          <button type="button" className="p-0.5 bg-gray-200 text-gray-900 font-semibold text-xl w-8"
          disabled={qtyInBag < 1}
          onClick={restOne}>
            <div className="-mt-1">-</div>
          </button>
          <input type="number" min={1} className="py-0.5 pl-3 text-gray-900 font-semibold text-base text-center w-full"
          value={qtyInBag} onChange={setVal} />
          {/* add */}
          <button type="button" className="p-0.5 bg-gray-200 text-gray-900 font-semibold text-xl w-8"
          onClick={addOne}>
            <div className="-mt-1">+</div>
          </button>
        </div>
      </div>

      <div className="">
        <h3 className="-mb-0.5 uppercase font-semibold text-xs">Total</h3>
        <div className={`text-lg ${isEditing ? 'text-amber-500' : 'text-gray-500' }`}>{priceFormat(qtyInBag * price)}</div>
      </div>
      
      <div className="">
        {itemInBag ? (<>
          {isEditing ? (
            <BtnLoading label="Modificar pedido"
              icon={<FaCartPlus className="ml-2 h-5 w-5 inline"/>}
              onClickAsync={async () => { await setItemToBag(bagId, itemId, qtyInBag, params, itemDoc); await onChange(); }} 
              className={`mb-2 py-2 px-4 block mx-auto text-md text-white bg-amber-600 content-center`} 
            />
          ) : (
            showIncludedLabel ? (
              <div className={`
                w-40 py-2 px-3 mb-2 mx-auto text-sm text-center rounded
              text-amber-800 bg-amber-50 content-center
              `} >
                Incluído en el pedido
              </div>
            ) : null
          )}
          <BtnLoading label="Quitar del pedido"
            onClickAsync={async () => { await setItemToBag(bagId, itemId, 0, params, itemDoc); await onChange(); }} 
            className={`py-1 px-4 block mx-auto text-xs text-red-700 bg-white hover:bg-red-100 content-center`} 
            spinClass="text-red-700"
          />
        </>) : (
          isEditing ? (
            <BtnLoading label="Agregar al pedido"
              icon={<FaCartPlus className="ml-2 h-5 w-5 inline"/>}
              onClickAsync={async () => { await setItemToBag(bagId, itemId, qtyInBag, params, itemDoc); await onChange(); }} 
              className={`mt-6 py-2 px-4 block mx-auto text-md bg-blue-100 text-blue-700 content-center`}
              colorClass="text-blue-700"
            />
          ) : null
        )}
      </div>
    </>
  );
};