import _ from 'lodash';
import { processRenderProps } from './dataTypeUtils';
import {
  FormFieldListItems,
  FormFieldSelect,
  FormFieldMultiSelect,
  FormFieldSelectButtons
} from '../Form';
import { useAuth } from '../../modules/user/AuthContext';
import { CountriesModel, extractCountryDetails } from '../Form/RawInputCurrency';


export default {
  Render(props) {
    const {
      onChange,
      fieldsRequired
    } = props;
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);
    const { user } = useAuth();

    const getDefaultValue = async () => {
      let countryDoc;
      if (param?.typesToShow?.amount) {
        countryDoc = await CountriesModel.findOneBy('countryId', user?.userDoc?.data?.city?.country);
      }
      return _.cloneDeep(param?.options).map(option => {
        if (param?.typesToShow?.amount && countryDoc) {
          option.amount.country = extractCountryDetails(countryDoc);
        }
        return option;
      });
    };

    return (
      <FormFieldListItems 
        name={_fieldName}
        title={title}
        placeholder={title}
        fieldsRequired={fieldsRequired}
        getDefaultValue={getDefaultValue}
        {...param}
        {...style}
        {...props}
      />
    );
  },

  RenderInputParams: ({ values, param }) => (<>
    <FormFieldSelectButtons
      name="param.typesToShow"
      multiple={true}
      title="Tipos de datos a incluir"
      placeholder="Tipos de datos a incluir"
      options={[
        { value: 'value', label: 'ID de texto' },
        { value: 'color', label: 'Selector de Color' },
        { value: 'amount', label: 'Monto de dinero' },
        { value: 'timeRange', label: 'Rango de tiempo' },
      ]}
    />
    <FormFieldListItems
      name="param.options"
      title="Items por defecto"
      placeholder="Items por defecto" 
      typesToShow={param?.typesToShow}
    />
  </>),
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
  
  RenderShowParams: ({ values }) => (null),
  
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => (
    <FormFieldListItems {...formFieldProps} />
  ),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}