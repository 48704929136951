import React, { useEffect, useState, useMemo } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Circle, GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import config from '../config';
import { accuracyLevels } from './DataTypes/gps';


export const getLocalityOfPlace = (place) => {
  return place.address_components.find(component =>
    component.types.includes('locality')
  );
};

const ViewGPS = ({
  value,
  classes,
  accuracyType,
  accuracyLevel,
  includePlace,
  mapZoom = 11
}) => {
  const center = useMemo(() => (config.defaultLocationGPS), []);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.googleMapApiKey,
  });

  useEffect(() => {
    if (value && value.lat && value.lng) {
      if (map) {
        const center = { lat: value.lat, lng: value.lng };
        map.panTo(center);
        createOrUpdateMarker(value);
      }
    }
  }, [value, map]);

  const initializeMap = (map) => {
    setMap(map);
    createOrUpdateMarker(value);
  };

  const createOrUpdateMarker = (position) => {
    if (marker) {
      marker.setPosition(position);
    } else {
      const newMarker = new window.google.maps.Marker({
        position,
        map,
        draggable: false,
      });
      setMarker(newMarker);
    }
  };

  const handleOpenGoogleMaps = () => {
    const { lat, lng } = value;
    const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(url, '_blank');
  };

  return (
    <div className={classes?.mapContainer}>
      <div className="relative mb-2">
        {isLoaded && (
          <GoogleMap
            mapContainerClassName={`w-full rounded-md ${classes?.mapRender} ${classes?.mapHeight || 'h-64'}`}
            center={center}
            options={{
              zoom: mapZoom,
              zoomControl: true,
              mapTypeControl: false,
              fullscreenControl: false,
              streetViewControl: false,
              rotateControl: false,
              scaleControl: false
            }}
            onLoad={initializeMap}
          >
            <Marker position={value} />
            {/* Circle para representar la precisión */}
            {accuracyType === 'zone' && accuracyLevel && (
              <Circle
                center={value}
                radius={accuracyLevels.find(level => level.value === accuracyLevel)?.distanceMeters} 
                options={{
                  fillColor: config.modules.panel?.colors['brand-secondary'],
                  fillOpacity: 0.2,
                  strokeColor: config.modules.panel?.colors['brand-secondary'],
                  strokeOpacity: 1,
                  strokeWeight: 1,
                  clickable: false,
                  draggable: false,
                  editable: false,
                  visible: true,
                }}
              />
            )}
          </GoogleMap>
        )}

        <button
          type="button"
          onClick={handleOpenGoogleMaps}
          className="absolute bottom-7 left-2 bg-white rounded border border-gray-300 shadow-md px-4 py-2 flex flex-row gap-2 items-center"
        >
          <FaMapMarkerAlt />
          Ver en Google Maps
        </button>
      </div>

      {includePlace && value?.place ? (
        <div className="">
          <span className="px-2.5 py-1 rounded-full bg-gray-200 space-x-1.5 text-xs text-gray-700">
            <span className="font-semibold">Ciudad</span>
            <span className="">{getLocalityOfPlace(value.place).short_name}</span>
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default ViewGPS;
