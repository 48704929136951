import { FaFacebook, FaWhatsapp, FaInstagram } from 'react-icons/fa';
import ContactBTN from '../../../components/ContactBTN';


export default function CtaImgFullBlock({ data }) {
  return (
    <div className="py-20 bg-cover bg-center relative" 
      style={{ backgroundImage: `url(${data.backgroundImageUrl})` }}> 

      {/* overlay */}
      <div className="absolute inset-0 bg-black opacity-20"></div>

      {/* cta */}
      <div className="flex flex-col relative z-10 container-width text-center">
        <img src={data.iconUrl} width={85} height={85} className="self-center mb-4" alt="queensfitwear" />
        <a href={data.urlDest} className="text-white font-semibold text-xl md:text-2xl cursor-pointer font-brand-secondary rounded-sm">
          {data.title}
        </a>
      </div>

      {/* links */}
      <div className="relative z-20 container-width text-white text-center mt-4">
      <span className="text-lg lg:text-xl">Encontranos en: </span>
        <div className="flex justify-center space-x-4 lg:space-x-6 text-4xl mt-3 mb-8">
          <a target='_blank' href="https://www.facebook.com/people/Queens-Fit-Wear/100066997566879/" rel="noreferrer"><FaFacebook /></a>
          <a target='_blank' href="https://wa.me/595985506001?text=Hola,%20Queens%20Fit%20Wear" rel="noreferrer"><FaWhatsapp/></a>
          <a target='_blank' href="https://www.instagram.com/queensfitwear/reels/" rel="noreferrer"><FaInstagram/></a>
        </div>
        <span className="border-t lg:text-lg p-2"> © Queens Fit Wear 2023 - Paraguay</span>
      </div>
      
      {/* fixed footer */}
      <footer className="fixed bottom-0 right-0 z-50 mb-4 mr-8">
        <ContactBTN className={'ml-2 mb-1 lg:mb-2 bg-brand-dark'} />
      </footer>
    </div>
  )
}
