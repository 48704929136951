import { Field } from 'react-final-form';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { required, RequiredByFieldsList, ShowFieldHelp } from './formParts';
import RawInputOptionListForm from './RawInputOptionListForm';


const FormFieldListItems = (props) => {
  let {
    name,
    title,
    className,
    defaultValue,
    typesToShow,
    fieldsRequired = null,
    showLabel = true,
    showClearBtn = true,
  } = props;
  return (
    <div className={`pb-3 ${className}`}>
      <Field name={name}>
        {({ input, meta }) => (<div>
          {showLabel && title ? (
            <div className={props?.classes?.fieldLabel}>
              <span
                className={`mr-3 ${
                  meta.touched && meta.error
                    ? 'text-red-600'
                    : ''
                }`}
              >
                {title}
                {meta.touched && meta.error ? '*' : ''}
              </span>

              {showClearBtn && input?.value?.length ? (
                <button
                  type="button"
                  className="px-1.5 py-0.5 font-regular text-xs bg-gray-100 text-gray-400 rounded-full hover:text-gray-800 inline-block"
                  onClick={() => input?.onChange([])}
                >
                  X
                </button>
              ) : ''}
            </div>
          ) : ''}
          <RawInputOptionListForm 
            {...props}
            items={input.value || []}
            defaultValue={defaultValue}
            onChange={(newItems) => {
              input.onChange(newItems);
            }} 
            typesToShow={typesToShow || { color: true, value: true } }
          />
          {/* Render error if any */}
          {meta.touched && meta.error && <div className="text-red-600" style={{ fontSize: 12 }}>{meta.error}</div>}
          {/* error */}
          <ShowFieldHelp name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} {...props} />
        </div>)}
      </Field>
    </div>
  );
};

export default FormFieldListItems;