import { useEffect, useState } from 'react';
import _ from 'lodash';
import { IonDatetime, IonIcon } from '@ionic/react';
import { 
  chevronUpOutline,
  chevronDownOutline,
  timeOutline,
  arrowForwardOutline
} from 'ionicons/icons';


const RawInputTime = (props) => {
  const {
    value,
    onChange
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    !_.isString(value) && onChange('00:00');
  }, [value]);
  
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <div className="relative inline-flex text-left place-content-stretch h-[32px]">
      <button
        onClick={toggleDropdown}
        className={`rounded inline-flex items-center gap-1 text-sm px-3 py-1 text-center text-gray-800 bg-gray-200 shadow`}
        type="button"
      >
        {value ? (
          <span className="">{value}</span>
        ) : (
          <span className="">{props?.placeholder || "Especificar hora"}</span>
        )}
        <IonIcon slot="icon-only" size='small' icon={timeOutline} />
        <IonIcon slot="icon-only" icon={isOpen ? chevronUpOutline : chevronDownOutline} />
      </button>
      {isOpen && (
        <div className="z-10 absolute left-0 mt-10 bg-white divide-y rounded-lg shadow w-auto max-w-[80vw] sm:max-w-[450px] flex flex-row gap-1">
          <div className="text-center px-4 py-2">
            {/* <label className="w-full text-xs font-semibold uppercase text-gray-600"></label> */}
            <IonDatetime
              presentation="time"
              value={'2024-12-01T'+value+':00'}
              onIonChange={e => onChange(e.target.value?.split('T')[1].slice(0, -3))}
            ></IonDatetime>
          </div>
        </div>
      )}
    </div>
  );
};

export default RawInputTime;