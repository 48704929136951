import React from 'react';
import BtnLoading from './BtnLoading';

export default function ModalAlert({
  text,
  title,
  subtitle,
  onConfirm,
  onCancel,
  confirmLabel = 'Confirmar',
  cancelLabel = 'Cancelar',
  confirmClass = 'bg-brand-secondary',
}) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-[1000]">
      <div className="fixed inset-0 bg-black opacity-50"></div> {/* Overlay */}
      <div className="bg-white rounded-lg p-6 shadow-md relative mx-5 max-w-sm text-center">
        {(title || text) ? (
          <p className="mt-6 mb-10 text-lg font-normal text-black">{title || text}</p>
        ) : null}
        {subtitle ? (
          <p className="mb-4 text-sm text-gray-500">{subtitle}</p>
        ) : null}
        <div className="flex place-content-between">
          {onConfirm ? (
            <BtnLoading
              label={confirmLabel}
              onClickAsync={onConfirm}
              className={`py-1.5 px-3 ${confirmClass} text-sm hover:scale-105 shadow-lg ${onCancel ? '' : 'mx-auto'}`}
              colorClass="text-black"
            />
          ) : null}
          {onCancel ? (
            <button
              onClick={onCancel}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 py-1.5 px-3 rounded"
            >
              {cancelLabel}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
