import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { 
  cog
} from 'ionicons/icons';
import _ from 'lodash';
import Model from "../../libs/ModelClass";
import { usePartOfModule } from "../../components/Module/PartOfModule";


export const useModuleSettings = ( entitySlug ) => {
  const moduleSettingsFn = usePartOfModule({
    type: 'crud',
    entitySlug,
    action: 'settings',
    param: {}
  });
  const moduleSettings = _.defaults((moduleSettingsFn && moduleSettingsFn()) || {}, {
    title: false,
    reorder: false,
    showBtnAdd: true,
    showBtnShow: true,
    showBtnDelete: true,
    showBtnUpdate: true,
    showBtnMove: true,
    // addButtonPermissionAction,
    // addButtonLabel,    
    redirectOnSave: true
  });

  return {
    moduleSettings
  };
}

const importDataOfEntityFromFile = async () => {
  const ExtendedModel = Model.extend('cartItemVariants_cloned');
  const data = await fetch('/cartItemsVariants-data.json').then(response => response.json());
  console.log('data', data);
  const chunks = [_.chunk(data, 50)[0]];
  // const modifierFn = (docData) => {
  //   Reflect.deleteProperty(docData, 'id');
  // };
  for (let i = 0; i < chunks.length; i++) {
    const chunk = chunks[i];
    // _.forEach(chunk, modifierFn);
    const savedItems = await ExtendedModel.createManyBatch(chunk);
    console.log('savedItems', savedItems);
  }
}

const updateItemsVariants = async () => {
  const ExtendedModel = Model.extend('cartItemVariants');
  const allDocs = await ExtendedModel.getAll();
  console.log('allDocs', allDocs);
  const chunks = _.chunk(allDocs, 50);
  const modifierFn = (doc) => {
    return {
      sizes: _.isObject(doc.data.sizes) ? _.keys(doc.data.sizes) : doc.data.sizes
    };
  };
  for (let i = 0; i < chunks.length; i++) {
    const chunk = chunks[i];
    const savedItems = await ExtendedModel.updateManyBatch(chunk, modifierFn);
    console.log('savedItems', savedItems);
  }
}

export const AdminConfigAfterContent = ({ isAllowed, user, history }) => {
  return (<>
    <div className="">
      {isAllowed('entities', ['list']) ? (
        <IonItem routerLink="/a/config/entity-creator-list" routerDirection="forward">
          <IonIcon icon={cog}></IonIcon>
          <IonLabel className="ml-2">Entidades</IonLabel>
        </IonItem>
      ) : null}
    </div>

    {/* <div className="">
      {isAllowed('entities', ['list']) ? (
        <IonBtnLoading 
          label="Import custom"
          onClickAsync={importDataOfEntityFromFile} 
        />
      ) : null}
    </div>

    <div className="">
      {isAllowed('entities', ['list']) ? (
        <IonBtnLoading 
          label="Update itemsVariants"
          onClickAsync={updateItemsVariants} 
        />
      ) : null}
    </div> */}
  </>
  );
};

export default function ({ setPermissions }) {
  return {
    name: 'Entity',
    slug: 'entity',
    permissions: [
      setPermissions({ slug: 'entities', label: 'Entities', type: 'system', actionsToAdd: ['export', 'clone'] }),
      setPermissions({ slug: 'taxonomyTypes', label: 'TaxonomyTypes', type: 'system' }),
      setPermissions({ slug: 'filterMenu', label: 'FilterMenu', type: 'system' })
    ],
    components: {
      main: {
        AdminConfigAfterContent
      },
    }
  };
};