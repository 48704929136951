import _ from 'lodash';
import { typeConfigs } from "../DataTypes";
import { getSelectedQuery } from '.';

const valueFormatter = (value, taxonomyType) => {
  const typeConfig = typeConfigs[taxonomyType?.type];
  
  if (!typeConfig || !typeConfig.valueFormatter) {
    return null;
  }

  return typeConfig.valueFormatter(value);
};

const filterDataToQueryFormatter = (filterData, filterMenuTaxonomyTypes) => {
  const filterQuery = {};
  filterMenuTaxonomyTypes?.forEach(filterPopulated => {
    let taxonomyType = filterPopulated?.taxonomyType;
    let filter = _.assign({}, taxonomyType?.filter, filterPopulated?.filter);
    let isSelected = filterPopulated?.id && filterData.hasOwnProperty(taxonomyType?.nameSlug);
    let filterValue = isSelected ? filterData[taxonomyType?.nameSlug] : null;
    
    if (isSelected) {
      let newValue = getSelectedQuery({
        filterValue,
        filter,
        taxonomyType,
        valueFormatter
      });
      filterQuery[taxonomyType?.nameSlug] = _.size(newValue) ? newValue : filterValue;
    }
  });
  return filterQuery;
}

export default filterDataToQueryFormatter;