import { useState } from "react";
import ModalAlert from "../../components/ui/ModalAlert";
import toast from "react-hot-toast";


export const useDeleteDoc = (props) => {
  let {
    selectedDoc, setSelectedDoc, onDelete, showToast
  } = props;
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const DeleteModal = () => (
    showDeleteConfirmation && (
      <ModalAlert
        text="¿Estás seguro de que deseas eliminar este elemento?"
        onConfirm={confirmedDelete}
        onCancel={cancelDelete}
        confirmClass="bg-brand-danger text-white"
      />
    )
  );

  const handleDelete = (doc) => {
    setShowDeleteConfirmation(true);
    setSelectedDoc(doc);
  };

  const confirmedDelete = async () => {
    try {
      onDelete && onDelete(selectedDoc);
      await selectedDoc.delete();
      setShowDeleteConfirmation(false);
      setSelectedDoc(null);
      showToast && toast.error('Se ha eliminado el documento');
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setSelectedDoc(null);
  };

  return {
    DeleteModal,
    handleDelete,
    confirmedDelete,
    cancelDelete
  };
};