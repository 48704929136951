import { useMemo } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Model from "../libs/ModelClass";
import { useModule } from "../context/ModuleContext";
import { usePartOfModule } from "../components/Module/PartOfModule";
import { parseAttrValParams } from '../libs/utils';
import useBreakpoints from '../libs/useBreakpoints';
import config from "../config";
import { useAuth } from "../modules/user/AuthContext";
import { useModuleModels } from "../libs/useModuleModels";
import { Page401, Page404 } from "../modules/panel/ErrorPages";


export function AdminModulePage({ history, match }) {
  const { isAllowed } = useModule();
  const { isMinBreakpointActive } = useBreakpoints();
  const location = useLocation();
  const { user } = useAuth();
  const { ModuleModel } = useModuleModels();

  const { entitySlug, action, 0: attrValParams } = match.params;
  const parsedParams = useMemo(() => parseAttrValParams( attrValParams ), [location]);
  const routeOfModule = usePartOfModule({
    type: 'routeAdmin',
    entitySlug,
    action
  });

  return (
    isAllowed(
      routeOfModule?.permission?.resource, 
      routeOfModule?.permission?.action // intersection comparison
    ) ? (
      (routeOfModule?.Content) ? (
        <routeOfModule.Content {...{ 
          parsedParams,
          entitySlug, 
          action,
          config,
          user,
          isAllowed, 
          Model,
          ModuleModel,
          isMinBreakpointActive, 
          location, 
          history,
          match
         }} />)
      : (
        <Page404 />
      )
    ) : (
      <Page401 />
    )
  );
}
