import { useEffect, useState } from 'react';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import slugify from 'slugify';
import { Fragment } from 'react';
import { ChromePicker } from 'react-color';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import RawInputCurrency, { extractCountryDetails } from './RawInputCurrency';
import { useAuth } from '../../modules/user/AuthContext';
import RawInputTimeRange from './RawInputTimeRange';
import { IonIcon } from '@ionic/react';
import { 
  colorFillOutline
} from 'ionicons/icons';
import Model from '../../libs/ModelClass';
import BtnLoading from '../ui/BtnLoading';
import IonBtnLoading from '../ui/IonBtnLoading';

const CountriesModel = Model.extend('i18nCountries');

const ItemForm = ({
  item,
  typesToShow,
  onChange,
  onDelete,
  moveUp,
  moveDown,
  slideMin,
  slideMax
}) => {
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const { user } = useAuth();
  
  useEffect(() => {
    if (typesToShow?.amount && !(item?.amount?.country?.countryId || item?.amount?.country?.code)) {
      fetchAndPopulateAmountCountry();
    }
  }, [item?.amount, user]);
  
  const fetchAndPopulateAmountCountry = async () => {
    const countryIdToPopulate = item?.amount?.country?.countryId || user?.userDoc?.data?.city?.country;
    // populate
    if (countryIdToPopulate) {
      const countryDoc = await CountriesModel.findOneBy('countryId', countryIdToPopulate);
      if (countryDoc) {
        handleAmountChange({
          ...item?.amount,
          country: extractCountryDetails(countryDoc)
        });
      } else {
        console.error("Couldn't find country", countryIdToPopulate);
      }
    }
  };

  const handleLabelChange = (e) => {
    const label = e.target.value;
    const value = slugify(label, { lower: true });
    let obj = { ...item, label };
    typesToShow?.value && (
      obj.value = value
    );
    onChange(obj);
  };

  const handleValueChange = (e) => {
    const value = e.target.value;
    onChange({ ...item, value });
  };

  const handleColorChange = (color) => {
    onChange({ ...item, color: color.hex });
  };

  const handleAmountChange = (amount) => {
    onChange({ ...item, amount });
  };

  const handleTimeRangeChange = (timeRange) => {
    onChange({ ...item, timeRange: timeRange });
  };

  const handleNumberSliderChange = (e) => {
    const number = parseInt(e.target.value);
    onChange({ ...item, number });
  };

  const handleDelete = () => {
    onDelete(item);
  };

  const toggleColorPicker = () => {
    setColorPickerVisible(!colorPickerVisible);
  };

  return (
    <div className="flex flex-wrap gap-2 items-center my-2 p-2 rounded-md shadow border border-gray-300">
      <button type="button" className="text-gray-500 text-xs" onClick={() => moveUp(item)}>
        ▲
      </button>
      <button type="button" className="text-gray-500 text-xs" onClick={() => moveDown(item)}>
        ▼
      </button>

      {typesToShow?.label ? (
        <input
          className="border-gray-200 border px-2 py-1 rounded-md flex-grow w-1/3"
          type="text"
          placeholder="Texto"
          value={item.label}
          onChange={handleLabelChange}
        />
      ) : null}
      
      {typesToShow?.color ? (
        <div
          // Mostrar el círculo con el color de fondo
          className="w-8 h-8 rounded-full cursor-pointer flex items-center place-content-center"
          style={{ backgroundColor: item.color || '#ffffff' }}
          onClick={toggleColorPicker}
        >
          <IonIcon slot="icon-only" size='small' icon={colorFillOutline} />
        </div>
      ) : null}
      
      {typesToShow?.value ? (
        <input
          className="border-gray-200 border text-sm px-2 py-1 rounded-md w-1/3"
          type="text"
          placeholder="Texto"
          value={item.value}
          // disabled
          onChange={handleValueChange}
        />
      ) : null}

      {typesToShow?.amount ? (
        <div className="w-auto">
          <RawInputCurrency
            className="border-gray-200 border text-sm px-2 py-1 rounded-md"
            value={item?.amount}
            // disabled
            onChange={handleAmountChange}
          />
        </div>
      ) : null}

      {typesToShow?.timeRange ? (
        <div className="">
          <RawInputTimeRange
            className="border-gray-200 border text-sm px-2 py-1 rounded-md"
            value={item?.timeRange}
            // disabled
            onChange={handleTimeRangeChange}
          />
        </div>
      ) : null}

      {typesToShow?.number ? (
        <input
          className="border-gray-200 border text-sm px-2 py-1 rounded-md w-1/3"
          type="number"
          placeholder="Número"
          value={item.number || 0}
          // disabled
          onChange={handleNumberSliderChange}
        />
      ) : null}

      {typesToShow?.numberSlider ? (
        <div className="w-36 bg-white p-2 rounded-md flex flex-row items-center gap-2">
          <p className="text-center w-6">
            <span className="font-semibold text-brand-secondary">{item?.number}</span>
          </p>
          <div className="">
            <input
              type="range"
              min={slideMin}
              max={slideMax}
              value={item?.number || 0}
              // disabled
              onChange={handleNumberSliderChange}
              className="w-full h-2 bg-gray-200 rounded-full outline-none appearance-none"
            />
            <div className="flex justify-between text-xs font-bold">
              <span>{slideMin}</span>
              <span>{slideMax}</span>
            </div>
          </div>
        </div>
      ) : null}

      <button type="button" onClick={handleDelete} className="font-bold text-red-700">
        x
      </button>

      {/* Mostrar u ocultar el color picker según la visibilidad */}
      {colorPickerVisible && (
        <div className="w-full mb-4">
          <ChromePicker
            color={item.color || '#ffffff'} // Color predeterminado o el color actual
            onChange={handleColorChange}
            disableAlpha={true}
          />
        </div>
      )}
    </div>
  );
};

const RawInputOptionListForm = (props) => {
  let { 
    items: initialItems, 
    defaultValue,
    getDefaultValue,
    onChange,
    typesToShow
  } = props;
  const [items, setItems] = useState(initialItems);
  const [listId] = useState(nanoid());

  useEffect(() => {
    // clear
    if (!initialItems?.length && items?.length) {
      setItems(null);
    }
    if (!props?.values?.id && !items?.length) {
      // add defaults to new doc
      handleAddDefaultItems();
    }
  }, [initialItems]);

  const handleAddItem = () => {
    const newItem = {
      label: '',
      value: ''
    }; // Color predeterminado
    const newItems = [...(items || []), newItem];
    setItems(newItems);
    onChange(newItems);
  };

  const handleAddDefaultItems = async () => {
    let defaults = defaultValue;
    if (!defaults && getDefaultValue) {
      defaults = await getDefaultValue();
    }
    if (defaults) {
      const newItems = [ ...(items || []), ...defaults ];
      setItems(newItems);
      onChange(newItems);
    }
  };

  const handleItemChange = (index, newItem) => {
    const updatedItems = [...items];
    updatedItems[index] = newItem;
    setItems(updatedItems);
    onChange(updatedItems);
  };

  const handleItemDelete = (itemToDelete) => {
    const updatedItems = items.filter((item) => item !== itemToDelete);
    setItems(updatedItems);
    onChange(updatedItems);
  };

  // const handleDragEnd = async (result) => {
  //   if (!result.destination) return;
  //   const newOrder = Array.from(items);
  //   const [movedItem] = newOrder.splice(result.source.index, 1);
  //   newOrder.splice(result.destination.index, 0, movedItem);
  //   setItems(newOrder);
  // };

  const moveUp = (item) => {
    const newOrder = Array.from(items);
    const originalIndex = newOrder.indexOf(item);
    if (originalIndex !== -1) {
      const [movedItem] = newOrder.splice(originalIndex, 1);
      newOrder.splice(originalIndex - 1, 0, movedItem);
      setItems(newOrder);
      onChange(newOrder);
    }
  };    

  const moveDown = (item) => {
    const newOrder = Array.from(items);
    const originalIndex = newOrder.indexOf(item);
  
    if (originalIndex !== -1 && originalIndex < newOrder.length - 1) {
      const [movedItem] = newOrder.splice(originalIndex, 1);
      newOrder.splice(originalIndex + 1, 0, movedItem);
      setItems(newOrder);
      onChange(newOrder);
    }
  };    

  return (
    <div>
      {items?.map((item, index) => (
        <Fragment key={`${listId}-${index}`}>
          <ItemForm
            {...props}
            typesToShow={typesToShow}
            item={item}
            onChange={(newItem) => handleItemChange(index, newItem)}
            onDelete={handleItemDelete}
            moveUp={moveUp}
            moveDown={moveDown}
          />
        </Fragment>
      ))}
      {/* <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="options">
          {(provided) => (
            <div className="" {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable key={index} draggableId={`${listId}-${index}`} index={index}>
                  {(provided) => (
                    <div id={`${listId}-${index}`} ref={provided.innerRef} {...provided.draggableProps}>
                      <ItemForm
                        key={`${listId}-${index}`}
                        item={item}
                        onChange={(newItem) => handleItemChange(index, newItem)}
                        onDelete={handleItemDelete}
                        dragHandleProps={provided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext> */}

      <div className="space-x-2 flex items-center">
        <label className="uppercase inline-block text-xs">
          Agregar
        </label>
        <IonBtnLoading
          label="Nuevo"
          onClick={handleAddItem}
          color="secondary"
          size="small"
          fill="solid"
          className="text-xs"
        />
        {(defaultValue || getDefaultValue) ? (
          <IonBtnLoading
            label="Predeterminados"
            onClickAsync={handleAddDefaultItems}
            color="secondary"
            size="small"
            fill="solid"
            className="text-xs"
          />
        ) : null}
      </div>
    </div>
  );
};

export default RawInputOptionListForm;
