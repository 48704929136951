import _ from 'lodash';
import config from "../../config";
import Model from "../../libs/ModelClass";


const accountManagerEntitySlug = config.modules.accountManager.accountManagerEntitySlug;
const AccountManagersModel = Model.extend(accountManagerEntitySlug);

// only update qty when affiliateId is not specified
export const getNextManagerAndAssignToUserDoc = async (userDoc, affiliateId) => {
  let managerDoc;
  if (affiliateId) {
    managerDoc = await AccountManagersModel.findById(affiliateId);
    if (managerDoc) {
      userDoc.data.accountManager = managerDoc.id;
    }
  }
  if (!managerDoc) {
    const accountManagers = await AccountManagersModel.filterByAttributes({ active: true });
    const sortedByQty = _.sortBy(accountManagers, ['assignedQty', 'createdAt']);
    managerDoc = sortedByQty[0];
    if (!_.isNumber(managerDoc.data.assignedQty)) {
      managerDoc.data.assignedQty = 0;
    }
    ++managerDoc.data.assignedQty;
    await managerDoc.save();
    userDoc.data.accountManager = managerDoc.id;
  }
  return managerDoc;
};

export default function ({ setPermissions }) {
  return {
    slug: 'accountManagers',
    label: 'Gestores de Cuentas',
    entities: {},
    permissions: [],
    components: {}
  };
};
