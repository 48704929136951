import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import { lineBreaks } from "../../../libs/utils";


export default function () {
  return {
    type: 'textGrid',
    variation: 'extended',
    template: TextGridAExtended
  };
};

export function TextGridAExtended({ data }) {
  return (
    <div className="px-8 my-40 bg-white text-left">
      <div className="container-width">
        <div className="flex flex-wrap gap-24 lg:gap-44">
          {data.items.map((item, index) => (
            <div key={index}> 
              <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[100ms]">
                <div>
                  <h2 className="text-black font-brand-main font-semibold text-4xl lg:text-5xl mb-2">
                    {lineBreaks(item?.title)}
                  </h2>
                  {/* <h3 className="text-gray-400 font-brand-main font-semibold text-5xl md:text-6xl mb-8">
                    {lineBreaks(item?.subtitle)}
                  </h3> */}
                  <p className="text-2xl lg:text-4xl text-gray-400 font-semibold font-brand-main">{lineBreaks(item?.content)}</p>
                </div>
              </AnimationAddClassWhenVisible>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
