import _ from "lodash";
import { routePageStack } from "../layout/RouteLayoutBlock";
import config from "../../config";
import { specDesign, stringsLayout } from "../specSite";
import CtaContactUsBlock from "./Blocks/CtaContactUs";
import CtaImgFullBlock from "./Blocks/CtaImgFull";
import HeaderMiniBlock from "./Blocks/HeaderMini";


const entitySlug = config.modules.cart.cartItemVariantsEntitySlug;

const specStack = [
  {
    strings: stringsLayout.headerMini,
    template: HeaderMiniBlock
  },
  {
    type: 'productGrid',
    variation: 'withFilters',
    // strings: blocks.headerHero,
    params: {
      filterMenuSlug: "search",
      filterPath: "/m/products/filter",
      viewPath: "/m/products/view",
      cartPath: "/m/carts/view",
      imgProportion: 'vertical-wide'
    }
  },
  // {
  //   strings: stringsLayout.contactCta,
  //   template: CtaContactUsBlock
  // },
  // {
  //   strings: stringsLayout.footer,
  //   template: CtaImgFullBlock
  // },
];

export const RouteProductFilter = routePageStack({ specDesign, specStack });