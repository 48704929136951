import { useEffect } from 'react';
import _ from 'lodash';


// Función para obtener las variables de URL guardadas
export const getUrlVariables = () => {
  return JSON.parse(localStorage.getItem('urlVariables')) || {};
};

function useURLVariables() {
  useEffect(() => {
    // Obtiene los parámetros actuales de la URL
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};

    // Itera sobre los parámetros de la URL y los guarda en un objeto
    for (const [key, value] of urlParams.entries()) {
      params[key] = value;
    }

    // Obtiene los valores previamente guardados en localStorage
    const storedVariables = getUrlVariables();
    
    // Compara cada valor nuevo con el valor previo y actualiza localStorage si es necesario
    _.forEach(params, (value, key) => {
      if (!_.isUndefined(value) && value !== storedVariables[key]) {
        storedVariables[key] = value;
      }
    });

    // Guarda los valores actualizados en localStorage
    localStorage.setItem('urlVariables', JSON.stringify(storedVariables));
  }, []); // Ejecuta este efecto solo una vez, al montar el componente

  return getUrlVariables;
}

export default useURLVariables;
