import { FaMapMarkerAlt, FaWhatsapp, FaRegClock } from "react-icons/fa";
import { Carousel } from 'react-responsive-carousel';


export default function CtaContactUsBlock({ data }) {
  return (
    <div id="contactos" className="text-black text-center md:grid md:grid-cols-2 ">
      {/* Left / Top*/}
      <div className="py-10 px-8 sm:px-14 md:px-8 lg:px-14 bg-zinc-200">
        <div className="container-width pt-12 lg:pt-20">
          <div className="">
            <h3 className="pb-2 text-3xl lg:text-4xl md:pb-4 font-brand-secondary text-brand-slate font-bold">{data.title}</h3>
            <p className="pb-4 text-base lg:text-lg font-brand-secondary">{data.content}</p>
          </div>
          
          <div className="grid gap-3 grid-cols-1 font-brand-secondary text-base ml-2 md:ml-4 lg:ml-6">

            <div className="flex items-center text-center place-content-start align-top py-1 md:py-0">
              <div className="mr-1.5">
                <FaWhatsapp size={25}></FaWhatsapp>
              </div>
              <div className="font-semibold">Teléfono:</div>
              <div className="text-sm md:text-base ml-2">
                {data.phoneNumber}
              </div>
            </div>

            <div className="flex items-center text-center place-content-start align-top">
              <div className="mr-1.5">
                <FaRegClock size={25}></FaRegClock>
              </div>
              <div className="font-semibold">Horarios:</div>
              <div className="text-sm text-left md:text-base lg:text-base ml-2">
                Lunes a Sábados <br className="md:hidden" /> de 9:00 a 18:00hs
              </div>
            </div>

            <div className="flex items-center text-center place-content-start align-top">
              <div className="mr-1.5">
                <FaMapMarkerAlt size={25}></FaMapMarkerAlt>
              </div>
              <div className="font-semibold">Ubicación:</div>
              <div className="text-sm text-left md:text-base lg:text-base ml-2">
                {data.address}
              </div>
            </div>

          </div>
        </div>
      </div>

      {/*Right / Bottom*/}
      <Carousel showThumbs={false} showStatus={false} infiniteLoop={true} autoPlay={true}>
        {data?.items?.map((item, index) => (
          <div key={index}>
            <img 
            src={item.imageSrc} 
            alt={item.title}
            className=" h-[40vh] sm:h-[50vh] md:h-[65vh] lg:h-[72vh] xl:h-[80vh] object-cover "
            />
          </div>
        ))}
      </Carousel>

    </div>
  );
}
