import { useEffect } from "react";
import { useAuth } from "../../modules/user/AuthContext";
import { LayoutPublic } from "../panel/LayoutPublic";
import { Capacitor } from "@capacitor/core";
import BadgeLoading from "../../components/ui/BadgeLoading";
import config from "../../config";
import BtnLoading from "../../components/ui/BtnLoading";
import { IonIcon } from "@ionic/react";
import { 
  logoGoogle
} from 'ionicons/icons';
import toast from "react-hot-toast";
import ContactBTN from "../../components/ContactBTN";
import { usePartOfModule } from "../../components/Module/PartOfModule";


export const useLoginUtils = (user, loginWithGoogleAndVerify, history) => {
  const userConditionToLogin = usePartOfModule({
    type: 'main',
    action: 'userConditionToLogin'
  });
  
  const redirectOnLogin = async (userWithCredentials) => {
    // existe el usuario
    if (userWithCredentials) {
      // verify user conditions to login
      const hasCustomerData = userConditionToLogin ? (await userConditionToLogin(userWithCredentials, history)) : true;
      // redirect to dashboard of profile
      if (hasCustomerData) {
        const urlParams = new URLSearchParams(window?.location?.search);
        const redirect = urlParams.get('redirect');
        if (redirect) {
          return window.location.href = redirect;
        }
        history.push(config?.modules?.panel?.mainPath || '/a/');
      } 
      // redirect to define customer data
      else {
        history.push('/a/user/set-data');
      }
    } 
  };

  useEffect(() => {
    if (
      (!!user?.userDoc?.data?.name || !!user?.userDoc?.data?.phone || !!user?.userDoc?.data?.email)
      && user?.credentialDoc?.data?.isActive === true
    ) {
      redirectOnLogin(user);
    }
  }, [user]);

  const handleGoogleSignin = async () => {
    try {
      await loginWithGoogleAndVerify();
    } catch (error) {
      if (error.message.indexOf("12501") >= 0) {
        toast.error("Debe seleccionar un Gmail.");
        return;
      }
      else if (error.message.indexOf("closed") >= 0) {
        toast.error("Ha cerrado la ventana de selección de Gmail.");
        return;
      } else {
        toast.error(error.message);
      }
    }
  };

  return {
    redirectOnLogin,
    handleGoogleSignin
  };
}

export function Login({ history }) {
  const { user, loginWithGoogleAndVerify } = useAuth();
  const { redirectOnLogin, handleGoogleSignin } = useLoginUtils(user, loginWithGoogleAndVerify, history);

  return (
    <LayoutPublic showGoBack={!Capacitor.isNativePlatform()} defaultHref={Capacitor.isNativePlatform() ? null : "/"}>
      {!user ? (
        <div className="pt-14 flex place-content-center content-center items-center">
          <BadgeLoading className="text-brand-primary" />
        </div>
      ) : (
        <div className="ion-padding flex flex-col items-center">
          <h1 className="mt-10 block text-black text-sm uppercase font-bold mb-2">Iniciar sesión</h1>

          <p className="mt-6 text-sm text-center">
            <BtnLoading
              label="Iniciar con Gmail"
              icon={<IonIcon icon={logoGoogle} className="!-mb-1 w-5 h-5 text-red-600" />}
              onClickAsync={handleGoogleSignin}
              className="block !items-start !justify-start px-3 py-2 w-[230px] !bg-gray-100 !text-gray-900 shadow-md !no-underline hover:underline"
              colorClass="text-gray-400 hover:text-gray-700"
            />
          </p>

          <div className="absolute bottom-16">
            <ContactBTN label="Escribínos" message="Hola WorkFood!" />
          </div>
          
          <div className="absolute bottom-4 font-normal text-xs text-center mt-14 text-gray-400">{config.version}</div>
        </div>
      )}
    </LayoutPublic>
  );
}

