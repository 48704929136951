import { useEffect, useState } from 'react';
import _ from 'lodash';
import { LayoutAdmin } from "../modules/panel/LayoutAdmin";
import SectionCrudForm from "../components/SectionCrudForm";
import Entity from '../modules/entity/Entity';
import { Content as AdminEntityTaxonomy } from './AdminEntityCreatorTaxonomy';
import { Content as AdminEntityFilterMenu } from './AdminEntityCreatorFilterMenu';

import { 
  FormField,
  FormFieldCheckboxToggle,
  FormFieldSelect
} from '../components/Form';
import { sortDocsByField } from '../libs/utils';

export function Content({ history, match }) {
  const { entityId } = match.params;
  const [selectedEntity, setSelectedEntity] = useState({});
  const [entitiesDocList, setEntitiesDocList] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      if (entityId === 'new') {
        setSelectedEntity({});
      } else {
        const entity = await Entity.findById(entityId);
        setSelectedEntity(entity);
      }
    };
    fetch();
  }, [entityId]);
  
  useEffect(() => {
    const fetch = async () => {
      if (!entitiesDocList) {
        let entityList = await Entity.getAll();
        entityList = sortDocsByField(entityList, 'sort');
        entityList = entityList.filter(entity => !entity?.data?.deleted);
        setEntitiesDocList(entityList);
      }
    };
    fetch();
  }, [entitiesDocList]);

  const handleSave = async (formValues) => {
    if (!formValues.nameSlug) {
      formValues.nameSlug = _.camelCase(formValues.name || '');
    }
    // Lógica para guardar la entidad
    const savedItem = await Entity.createOrUpdate(formValues);
    if (entityId === 'new') {
      history.push(`/a/config/entity-creator`);
      history.push(`/a/config/entity-creator/${savedItem.id}/form`);
    }
  };

  const fieldsRequired = ['name'];

  const onValidation = (values, errors) => {
    if (!values.name) {
      errors.name = ' ';
    }
  };

  const FormInputFields = (({ form, values }) => (<>
    <FormField name="name" title="Nombre" placeholder="Nombre" fieldsRequired={fieldsRequired} />
    <FormField name="nameSlug" title="ID" placeholder={_.camelCase(values?.name || '')} fieldsRequired={fieldsRequired} 
      // onChange={(value) => {
      //   form.change('nameSlug', _.camelCase(value || ''));
      // }}
    />
    <h2 className="mt-4 pb-1 mb-2 border-b border-gray-300 uppercase text-xs text-gray-500 font-semibold">Menú</h2>
    <FormFieldCheckboxToggle name="showInMenu" title="Mostrar en el menú" placeholder="Mostrar en el menú" />
    {entitiesDocList ? (<>
      <FormFieldSelect 
        name="entityParent"
        title="Entidad superior"
        fieldsRequired={fieldsRequired}
        options={entitiesDocList.map((doc) => ({
          value: doc?.id,
          label: doc?.data?.name
        }))}
      />
      <h2 className="mt-4 pb-1 mb-2 border-b border-gray-300 uppercase text-xs text-gray-600 font-semibold">Datos relacionados</h2>
      <p className="mb-2 text-xs text-gray-500 font-normal">ID de entidades relacionadas separadas por coma.</p>
      <div className="grid grid-cols-2 gap-2">
        <div className="">
          <FormField name="relSlugsBelongsTo" title="belongsTo" classes={{fieldLabel: 'text-xs font-normal'}} />
          <FormField name="relSlugsHasOne" title="hasOne" classes={{fieldLabel: 'text-xs font-normal'}} />
        </div>
        <div className="">
          <FormField name="relSlugsBelongsToMany" title="belongsToMany" classes={{fieldLabel: 'text-xs font-normal'}} />
          <FormField name="relSlugsHasMany" title="hasMany" classes={{fieldLabel: 'text-xs font-normal'}} />
        </div>
      </div>
    </>) : null}
  </>));


  return (
    <div className="">
      {/* Entity Form */}
      <div className="p-3 rounded-md border border-gray-200 shadow-md hover:shadow-lg ">
        <SectionCrudForm
          doc={selectedEntity}
          onSave={handleSave}
          editStyle="route"
          // validatiion
          fieldsRequired={fieldsRequired}
          onValidation={onValidation}
          FormInputFields={FormInputFields}
        />
      </div>

      {entityId !== 'new' ? (
        <div className="mt-5 mb-10 p-3 rounded-md border border-gray-200 shadow-md hover:shadow-lg">
          {/* FilterMenu */}
          <AdminEntityFilterMenu entityId={entityId} entitySlug={selectedEntity?.data?.nameSlug} />
          <hr className='my-5 border border-gray-100' />
          {/* Taxonomy */}
          <AdminEntityTaxonomy entityId={entityId} entitySlug={selectedEntity?.data?.nameSlug} />
        </div>
      ) : ''}
    </div>
  );
}

export function AdminEntityCreatorForm(props) {
  return (
    <LayoutAdmin 
      history={props.history} 
      defaultHref={`/a/config/entity-creator`}
      breadcrumbs={[{
        url: '/a/config',
        title: 'Config'
      }, {
        url: '/a/config/entity-creator-list',
        title: 'Entidades'
      }, {
        title: "Formulario"
      }]}
    >
      <div className="ion-padding">
        <Content {...props} />
      </div>
    </LayoutAdmin>
  );
}
