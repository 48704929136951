import { processRenderProps } from './dataTypeUtils';
import {
  FormField,
  FormFieldSelect
} from '../Form';
import {
  // Number
  FilterRenderNumber,
  FilterCreatorNumber,
} from '../Filters';


const valueFormatter = (value) => {
  if (typeof value === 'undefined' || value === null || isNaN(value)) {
    return null; // or return another appropriate default value when the input is undefined, null, or not a valid number.
  }

  try {
    const parsedValue = parseInt(value, 10); // Convert the value to an integer.
    return isNaN(parsedValue) ? null : parsedValue; // Return the parsed value if it is a valid number, or another default value if the parsing fails.
  } catch (error) {
    console.error('Error occurred while formatting value:', error); // Log the error for debugging purposes.
    return null; // or return another appropriate default value if an error occurs during parsing.
  }
};

export default {
  Render(props) {
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);
    param.formatter = valueFormatter;

    return (
      <FormField
        name={_fieldName}
        type="number"
        {...param}
        {...style}
        {...props}
      />
    );
  },
  
  RenderFilter(props) {
    return <FilterRenderNumber {...props} DataTypeRender={this.Render} />
  },

  RenderFilterParam(props) {
    return <FilterCreatorNumber {...props} />
  },

  valueFormatter,

  RenderInputParams: ({ values, param }) => (
    <>
      <FormField
        name="param.minValue"
        title="Valor mínimo"
        type="number"
        maxValue={values.param && parseInt(values.param.maxValue)}
      />
      <FormField
        name="param.maxValue"
        title="Valor máximo"
        type="number"
        minValue={values.param && parseInt(values.param.minValue)}
      />
    </>
  ),

  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (
    <>
      <FormFieldSelect
        name="show.number.operator"
        title="Operador"
        placeholder="Operador"
        options={[
          { value: 'eq', label: 'es igual' },
          { value: 'gt', label: 'es mayor' },
          { value: 'lt', label: 'es menor' }
        ]}
      />
      {values.show?.number?.operator ? (
        <FormField
          type="number"
          name="show.number.value"
          title="Valor"
          placeholder="valor"
        />
      ) : ''}
    </>
  ),

  RenderShowParams: ({ values }) => (null),

  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => (
    <FormField {...formFieldProps} type="number" />
  )
}