import { useEffect, useRef } from 'react';

export function usePrevious(value) {
  const ref = useRef( value );

  // Almacenar el valor actual en ref.current antes de la próxima renderización
  useEffect(() => {
    ref.current = value;
  }, [value]);
  
  // Devuelve el valor previo (que está almacenado en ref.current)
  return ref.current;
}
