import { Link } from "react-router-dom";
import _ from "lodash";
import HeaderHeroBlock from "./Blocks/HeaderHero";
import TextGridWithTitleBlock from "./Blocks/TextGridWithTitle";
import CtaAccordionBlock from "./Blocks/CtaAccordion";
// import SuperOutstandingProduct from "./Blocks/SuperOutstandingProduct";
// import GridOutstandingProductsBlock from "./Blocks/GridOutstandingProducts";
import CtaContactUsBlock from "./Blocks/CtaContactUs";
import CustomerReviewsBlock from "./Blocks/CustomerReviews";
import CtaOverlapImgAndContent from "./Blocks/CtaOverlapImgAndContent";
import FrecuentAnswersBlock from "./Blocks/FrecuentAnswers";
import CtaImgFullBlock from "./Blocks/CtaImgFull";
import { blocks } from "./stringsHome";
import { Link as ScrollLink } from 'react-scroll';
import { LayoutHome } from "../../modules/layoutHome/LayoutHome";
import { stringsLayout } from "../specSite";


const HeaderMenu = ({ menuHeight }) => (<>
  {/* <ScrollLink
    className="pl-10 md:px-3 hover:text-gray-400 text-white hover:underline hover:underline-offset-4 md:text-base xl:text-lg text-sm cursor-pointer"
    to="inicio"
    smooth={true}
    duration={500}
    spy={true}
    offset={-menuHeight}
  >
    Inicio
  </ScrollLink> */}
  <Link
    to="/m/products/filter"
    title="Explorar catálogo"
    className="pl-10 md:px-3 hover:text-gray-400 text-white hover:underline hover:underline-offset-4 md:text-base xl:text-lg text-sm cursor-pointer"
  >
    Explorar catálogo
  </Link>
  {/* <ScrollLink
    className="pl-10 md:px-3 hover:text-gray-400 text-white hover:underline hover:underline-offset-4 md:text-base xl:text-lg text-sm cursor-pointer"
    to="contactos"
    smooth={true}
    duration={500}
    spy={true}
    offset={-menuHeight}
  >
    Contactos
  </ScrollLink> */}
</>)

export function RouteHome () {  

  // menuFooter: {}
  
  return (
    <LayoutHome mainSectionClass="px-0 pt-14 lg:pt-18" HeaderMenu={HeaderMenu} fullMenuHeader={true}>
      {/* Header Hero */}
      <HeaderHeroBlock data={blocks.headerHero} />
      
      <TextGridWithTitleBlock data={blocks.textGridWithTitle} />
      
      {/* <SuperOutstandingProduct data={blocks.mainOutstandingProduct} /> */}

      {/* <GridOutstandingProductsBlock data={blocks.gridOutstandingProducts} /> */}

      <CtaAccordionBlock data={blocks.ctaAccordion} />

      <CtaContactUsBlock data={stringsLayout.contactCta} />

      <CustomerReviewsBlock data={blocks.customerReviews} />

      <CtaOverlapImgAndContent data={blocks.ctaOverlapImgAndContent} />

      <FrecuentAnswersBlock data={blocks.frecuentAnswers} />
      
      {/* Footer */}

      <CtaImgFullBlock data={stringsLayout.footer} />
    </LayoutHome>
  );
}