import _ from 'lodash';
import { assignDeep } from './libs/utils';


export const env = 'prod'; // window.location.hostname === 'localhost' ? 'dev' : 'prod',

let config = {
  env,
  version: '1.0.0',

  /////////////////////////////////////////////////////
  //
  // BRAND
  //
  /////////////////////////////////////////////////////
  // assigned in last LayoutHome call
  
  /////////////////////////////////////////////////////
  //
  // PLATFORM
  //
  /////////////////////////////////////////////////////

  // reCaptcha
  reCaptchaSiteKey: "",
  // Maps
  googleMapApiKey: "AIzaSyBRUThzCEHvObmG_l6gvVvClI2lM58PGqU",
  // Storage
  imgFolder: 'assetsImages',
  getImgPrefix: function (fileName, folder) { return `https://firebasestorage.googleapis.com/v0/b/${this.projectSlug}.appspot.com/o/${folder || this.imgFolder}%2F${fileName}?alt=media` },
  imageDimensions: [
    { width: 2000, height: 2000, suffix: '' },   // full
    { width: 1300, height: 1300, suffix: '-xl' }, // xl
    { width: 800, height: 800, suffix: '-md' },   // md
    { width: 300, height: 300, suffix: '-xs' },   // xs
  ],
  // Filter url
  urlSeparator: '---',

  /////////////////////////////////////////////////////
  //
  // Modules
  //
  /////////////////////////////////////////////////////
  modules: {
    panel: {
      mainPath: '/a/entity',
      configPath: '/a/config',
      panelSystemVersionEntitySlug: 'systemVersions',
      colors: { 
        // same as tailwind.config
        // TODO extends from statis config
        'brand-secondary': '#1ac3ff'
      },
      gps: {
        defaultDistanceForInput: 5,
        defaultDistanceMin: 3,
        defaultDistanceMax: 25,
      },
      scrollYtoShowToTopBtn: 300,
      scrollYtoShowFixedNavbar: 450
    },

    user: {
      usersEntitySlug: "usersProfiles",
      userDefaultRoleSlug: "customer",
      userTopRoleSlug: "super-admin",
      fieldCity: 'city'
    },

    delivery: {
      packagesEntitySlug: "packagesToSend",
      deliveryEntitySlug: "deliveryOrders",
    },
    
    verification: {
      verificationModeratorRoleSlug: "super-admin",
      types: {
        publicProfiles: {
          entitySlug: 'verificationsOfProfiles'
        }
      }
    },

    publicProfile: {
      publicProfileEntitySlug: 'publicProfiles',
      conectionsEntitySlug: 'conections'
    },

    accountManager: {
      accountManagerEntitySlug: 'accountManagers'
    },

    cart: {
      cartsEntitySlug: 'carts',
      cartItemEntitySlug: 'cartItems',
      cartItemVariantsEntitySlug: 'cartItemVariants',
      cartItemCategoriesEntitySlug: 'cartItemCategories',
      cartItemTypesEntitySlug: 'cartItemTypes',

      labelAddCartBtn: 'Agregar al pedido',
    }
  },

  // LEGACY
  // youtube video tutorial embed id
  embedId: null,
  // Cart
  getURLprefix: function () { return `${this.protocol}://${this.domain}` },
  whatsAppTextBTN: function () { return `Hola ${this?.siteName}! me interesa saber más de sus productos.` },
};

export const getConfig = () => {
  return config;
};

export const setConfig = (newConfig = {}) => {
  config = assignDeep(config, newConfig);
};

export default config;