import toast from 'react-hot-toast';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import config from '../config';


export default function CopyBtn({ text, productId, className }) {
  let textToCopy = "";
  if ( text ) {
    textToCopy = text;
  }
  else if (productId) {
    textToCopy = config.getProductLink(productId);
  }

  return (
    <CopyToClipboard 
    text={textToCopy}
    onCopy={() => toast('Enlace copiado al portapapeles', { icon: 'ℹ️' })}>
      <button type="button" 
      className={`mt-3 flex flex-row gap-2 place-items-center mx-auto leading-5 font-regular hover:scale-105 
      px-4 py-2 rounded-lg text-base tracking-wide transition duration-400 ease-in-out 
      bg-stone-100 text-stone-600 shadow shadow-stone-300 hover:bg-stone-200 ${className}`}>
          <svg className="fill-current h-5" viewBox="0 0 15 15"><path d="M5 2V1H10V2H5ZM4.75 0C4.33579 0 4 0.335786 4 0.75V1H3.5C2.67157 1 2 1.67157 2 2.5V12.5C2 13.3284 2.67157 14 3.5 14H11.5C12.3284 14 13 13.3284 13 12.5V2.5C13 1.67157 12.3284 1 11.5 1H11V0.75C11 0.335786 10.6642 0 10.25 0H4.75ZM11 2V2.25C11 2.66421 10.6642 3 10.25 3H4.75C4.33579 3 4 2.66421 4 2.25V2H3.5C3.22386 2 3 2.22386 3 2.5V12.5C3 12.7761 3.22386 13 3.5 13H11.5C11.7761 13 12 12.7761 12 12.5V2.5C12 2.22386 11.7761 2 11.5 2H11Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
          <div>Copiar enlace</div>
      </button>
    </CopyToClipboard>
  )
}