import _ from 'lodash';
import ViewGPS from './ViewGPS';
import SliderGallery from './ui/SliderGallery';
import EntityGetOneDocument from './EntityGetOneDocument';
import Entity, { useEntityFullBySlug } from '../modules/entity/Entity';
import { stackClasses, getImageURL, numberFormat } from '../libs/utils';
import PartOfModule from './Module/PartOfModule';
import ReactMarkdown from 'react-markdown';
import { ShowRelatedListMaterials } from './Form/RawInputRelatedListMaterials';
import { useAuth } from '../modules/user/AuthContext';
import config from '../config';
import { Link } from 'react-router-dom';
import { useModule } from '../context/ModuleContext';
import dayjs from 'dayjs';
import { FieldViewSelectButtons } from './Form/FormFieldSelectButtons';
import { FieldViewSelectButtonsWithColors } from './Form/FormFieldSelectButtonsWithColors';


const getComponent = (props) => {
  let {
    user,
    entitySlug,
    doc,
    field,
    viewType,
    classes,
    parts,
    decorator,
    DecoratorRender,
    outstandingOnly,
    force,
    taxonomyTypesDocList,
    isAllowed
  } = props;

  if (!doc) return null;
  
  if (field === 'mainAttr') {
    let mainAttr = Entity.getMainAttr(taxonomyTypesDocList);
    field = mainAttr;
  }
  if (field === 'mainImgAttr') {
    let mainImgAttr = Entity.getMainImgAttr(taxonomyTypesDocList);
    field = mainImgAttr;
  }
  
  const taxonomyType = taxonomyTypesDocList?.find(taxonomyType => taxonomyType.data?.nameSlug === field);
  if (!taxonomyType) {
    return null;
  }
  if (taxonomyType.data.deleted || taxonomyType.data.hiddeInViews) { return null; }

  if (user?.rolesDoc?.data.nameSlug !== config.modules.user.userTopRoleSlug) {
    let omit = false;
    // restrict by owners only
    if (
      taxonomyType.data.forOwner
      && !(
        user?.userDoc?.id === doc?.data?.owner
        || user?.userDoc?.id === doc?.data?.userId
      )
    ) {
      omit = true;
    }
    // restrict by moderators only
    if (
      taxonomyType.data.forModerator
      && user?.userDoc && user?.rolesDoc
      && config.modules.verification.verificationModeratorRoleSlug !== user.rolesDoc?.data.nameSlug
    ) {
      omit = true;
    }
    if (omit) {
      return null;
    }
  }

  const { type, name, nameSlug, outstanding, param, style } = taxonomyType.data;

  const displayedValue = decorator ? decorator(doc.data[nameSlug]) : getDisplayedValue(
    doc.data[nameSlug],
    type,
    taxonomyType
  );

  const FieldLabel = () => (
    <span className={`pr-2 font-semibold ${classes?.fieldLabel}`}>
      {name}
    </span>
  );

  if (!force && outstandingOnly && !outstanding) {
    // Si se requieren solo elementos destacados y este no es destacado, no mostrarlo
    return null;
  }
  if (type === 'gps') {
    let params = { ...taxonomyType.data.param };
    if (taxonomyType.data.param?.accuracyType === 'zone') {
      params.accuracyLevel = params.accuracyLevel - 1;
    }
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          <ViewGPS
            value={displayedValue}
            classes={classes}
            {...params}
          />
        </div>
      </div>
    ) : null;
  }

  if (type === 'gallery') {
    if (!displayedValue || !_.isArray(displayedValue)) {
      return null;
    }
    const slidersData = displayedValue.map(filePath => {
      return {
        key: filePath,
        title: filePath,
        imageUrl: getImageURL(filePath, 'xl', taxonomyType.data.param?.folder),
        thumbUrl: getImageURL(filePath, 'xs', taxonomyType.data.param?.folder)
      }
    });
    return displayedValue ? (
      slidersData && slidersData.length ? (
        <div key={nameSlug} className={classes?.fieldContainer}>
          <FieldLabel />
          <div className={classes?.fieldValue}>
            {taxonomyType.data.param?.imgProportion === 'vertical-wide' ? (
              <SliderGallery
                slidersData={slidersData}
                // showSliderAtInit={showSliderAtInit}
                // gridPlace={gridPlace}
                heightProportion={1.5}
                showGrid={true}
                gridClass="images-grid xs vertical"
                thumbBtnClass="image-card"
              >
                {({ InnerSlider, Grid }) => (
                  <div className="flex flex-row w-full">
                    <div className="pr-1 w-4/5">
                      {InnerSlider}
                    </div>
                    <div className="pl-1 w-1/5">
                      {Grid}
                    </div>
                  </div>
                )}
              </SliderGallery>
            ) : (
              <SliderGallery
                slidersData={slidersData}
                // showSliderAtInit={showSliderAtInit}
                // gridPlace={gridPlace}
                heightProportion={1}
                showGrid={true}
                gridClass="images-grid xs mt-3"
                thumbBtnClass="image-card">
              </SliderGallery>
            )}
          </div>
        </div>
      ) : null
    ) : null;
  }

  if (type === 'selectOneEntityDocument') {
    const entitySlug = param?.entitySlug || param?.entityNameSlug; // retrocompatible
    return displayedValue ? (
      <EntityGetOneDocument
      key={nameSlug}
      entitySlug={entitySlug}
      docId={doc?.data[ nameSlug ]}>
        {({ doc, mainAttr, mainImgAttr }) => {
          return (<>
            <div className={classes?.fieldContainer}>
              <FieldLabel />
              <div className={classes?.fieldValue}>
                {DecoratorRender ? (
                  <DecoratorRender doc={doc} />
                ) : (
                  <>
                    {(isAllowed && isAllowed(entitySlug, ['list'])) ? (
                      <Link to={`/a/entity/${entitySlug}/${doc?.id}`} className="border-b border-black">
                        {doc.data[mainAttr]}
                      </Link>
                    ) : (
                      <span className="">{doc.data[mainAttr]}</span>
                    )}
                  </>
                )}
                {doc.data.deleted ? (<span className="px-1 py-0.5 ml-2 text-xs text-red-600 bg-red-100 rounded-full">eliminado</span>) : null}
              </div>
            </div>
          </>);
        }}
      </EntityGetOneDocument>
    ) : null;
  }

  if (type === 'textArea') {
    // TODO: agregar parser de markdown al mostrar, botones WYSIWYG para markdown en FormFieldTextArea
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          {style?.design === 'simple' ? (
            displayedValue
          ) : null}
          {style?.design === 'richMarkdown' ? (
            <div className="prose lg:prose-sm">
              <ReactMarkdown>{displayedValue}</ReactMarkdown>
            </div>
          ) : null}
          {style?.design === 'richWYSIWYG' ? (
            displayedValue
          ) : null}
        </div>
      </div>
    ) : null;
  }

  if (type === 'coded') {
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          <PartOfModule
            type="codedDataType"
            fieldName={nameSlug}
            action={viewType === 'list' ? 'RenderShowList' : 'RenderShow'}
            entitySlug={entitySlug}
            param={{ displayedValue, doc, field, entitySlug, taxonomyTypeData: taxonomyType.data }} 
          />
        </div>
      </div>
    ) : null;
  }

  if (type === 'relatedListMaterials') {
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          <ShowRelatedListMaterials relatedListId={displayedValue} />
        </div>
      </div>
    ) : null;
  }

  if (type === 'boolean') {
    return (<div key={nameSlug} className={classes?.fieldContainer}>
      <FieldLabel />
      <div className={classes?.fieldValue}>
        {!!doc?.data[nameSlug] ? (
          <span className={classes?.fieldValueTrue || 'text-gray-900'}>{param?.textTrue}</span>
        ) : (
          <span className={classes?.fieldValueFalse || 'text-gray-400'}>{param?.textFalse}</span>
        )}
      </div>
    </div>);
  }

  if (type === 'select' || type === 'multiselect') {
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          {style?.design === 'buttons' ? (
            <FieldViewSelectButtons {...{ taxonomyType, value: doc.data[nameSlug], classes, parts }} showLabel={false} />
          ) : null}
          {style?.design === 'buttonsColors' ? (
            <FieldViewSelectButtonsWithColors {...{ taxonomyType, value: doc.data[nameSlug], classes, parts }} showLabel={false} />
          ) : null}
        </div>
      </div>
    ) : null;
  }

  if (type === 'list') {
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          {displayedValue?.map((item, index) => (
            <div className={classes?.fieldValueItem || ''} key={index}>
              <div className={classes?.fieldValueItemLabel}>
                {item.label}
              </div>
              {taxonomyType.data.param.typesToShow?.timeRange ? (
                <div className={classes?.fieldValueItemTimeRange}>
                  {item.timeRange.start} - {item.timeRange.end}
                </div>
              ) : null}
              {taxonomyType.data.param.typesToShow?.amount ? (
                <div className={classes?.fieldValueItemCurrency}>
                  <span className="">{item.amount?.qty}</span>
                  <span className="">{item.amount?.country?.symbol}</span>
                  <span className="react-tel-input pl-1 space-x-1 text-xs">
                    <div className={`flag inline-block -mt-1 ${item.amount?.country?.iso2?.toLowerCase()}`}></div>
                    <span className="">{item.amount?.country?.code}</span>
                  </span>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    ) : null;
  }

  if (type === 'numberAmount') {
    if (!displayedValue?.qty || !displayedValue?.country) {
      return null;
    }
    return displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          <div className={classes?.fieldValueItemCurrency}>
            <span className="mr-1">{displayedValue?.qty}</span>
            <span className="mr-1">{displayedValue?.country?.symbol}</span>
            <span className="react-tel-input pl-1 space-x-1 text-xs">
              <div className={`flag inline-block -mt-1 ${displayedValue?.country?.iso2?.toLowerCase()}`}></div>
              <span className="">{displayedValue?.country?.code}</span>
            </span>
          </div>
        </div>
      </div>
    ) : null;
  }

  if (type === 'selectCity') {
    return doc.data[nameSlug + 'Labels']?.length ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          {doc.data[nameSlug + 'Labels'].reverse().join(', ')}
        </div>
      </div>
    ) : null;
  }

  return (
    displayedValue ? (
      <div key={nameSlug} className={classes?.fieldContainer}>
        <FieldLabel />
        <div className={classes?.fieldValue}>
          {displayedValue}
        </div>
      </div>
    ) : null
  );
}

/**
 * Obtiene el valor de visualización de una taxonomía según su tipo y valor almacenado.
 *
 * @param {any} value - El valor almacenado de la taxonomía.
 * @param {string} type - El tipo de taxonomía.
 * @param {object} taxonomyType - El objeto que representa el tipo de taxonomía.
 * @returns {string} - El valor de visualización correspondiente.
 */
const getDisplayedValue = (value, type, taxonomyType) => {
  if (type === 'number') {
    return numberFormat(value);
  }
  if (type === 'date' && value) {
    return dayjs(value).format(taxonomyType.data?.show?.format || 'ddd, DD - MMMM - YYYY');
  }
  return value;
};

const EntityDocView = (props) => {
  let { entitySlug, render } = props;
  let { taxonomyTypesDocList, mainAttr, mainImgAttr } = useEntityFullBySlug({ entitySlug });
  let { user } = useAuth();
  let { isAllowed } = useModule();

  return (
    taxonomyTypesDocList?.length
    ? render({
      ViewData({ field, classes, decorator, DecoratorRender, ...viewProps }) {
        return taxonomyTypesDocList?.length ? getComponent({
          ...props,
          ...viewProps,
          taxonomyTypesDocList,
          field,
          classes: stackClasses(props?.classes, classes),
          decorator,
          DecoratorRender,
          user,
          isAllowed
        }) : null;
      },

      doc: props?.doc,
      taxonomyTypesDocList, mainAttr, mainImgAttr
    })
    : null
  );
};

export const EntityDocListView = (props) => {
  let { render, taxonomyTypesDocList, mainAttr, mainImgAttr } = props;
  let { user } = useAuth();
  let { isAllowed } = useModule();
  return (
    taxonomyTypesDocList?.length
    ? render({
      ViewData({ field, classes, decorator, DecoratorRender, ...viewProps }) {
        return taxonomyTypesDocList?.length ? getComponent({
          ...props,
          ...viewProps,
          taxonomyTypesDocList,
          field,
          classes: stackClasses(props?.classes, classes),
          decorator, 
          DecoratorRender,
          user,
          isAllowed
        }) : null;
      },

      doc: props?.doc,
      taxonomyTypesDocList, mainAttr, mainImgAttr
    })
    : null
  );
};

export default EntityDocView;
