import { Field } from 'react-final-form';
import { ShowFieldHelp } from './formParts';


const FormFieldFilterRangeCreator = (props) => {
  let {
    FilterRangeForm,
    name,
    title,
    className,
    fieldsRequired = null
  } = props;
  return (
    <div className={`pb-3 ${className}`}>
      <Field name={name}>
        {({ input, meta }) => (<>
          <label position="floating" className="block pb-2 border-b border-gray-200">
            <span className={`mr-3 ${meta.touched && meta.error ? 'text-red-600' : ''}`}>
              {title}
              {meta.touched && meta.error ? '*' : ''}
            </span>
          </label>
          <FilterRangeForm 
            items={input.value || []} 
            onChange={(newItems) => {
              input.onChange(newItems);
            }} 
          />
          {/* Render error if any */}
          {meta.touched && meta.error && <div className="text-red-600" style={{ fontSize: 12 }}>{meta.error}</div>}
          {/* error */}
          <ShowFieldHelp name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} {...props} />
        </>)}
      </Field>
    </div>
  );
};

export default FormFieldFilterRangeCreator;