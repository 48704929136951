import config from '../../config';
import { openWhatsApp } from '../../libs/utils';
import { siteIdentity } from '../specSite';


export const blocks = {
  headerHero: {
    items: [
      {
        imageSrc: "/images/header-1.jpg",
        title: "Ropa",
        subtitle: "Deportiva",
        ctaButtons: [
          {
            ctaTitle: "Ir a la Tienda",
            ctaType: "link",
            ctaUrl: "/m/products/filter"
          }
        ]
      },
      {
        imageSrc: "/images/header-3.jpg",
        title:"Sentite",
        subtitle:"Queen",
        ctaButtons: [
         {
           ctaTitle: "Ir a la Tienda",
           ctaType: "link",
           ctaUrl: "/m/products/filter"
         }
        ] 
       },
       {
        imageSrc: "/images/header-2.jpg",
        title:"Visitanos en",
        subtitle:"nuestro local",
        ctaButtons: [
         {
          ctaTitle: "Contactános",
          ctaType: "whatsapp",
          ctaUrl: openWhatsApp({ number: siteIdentity.number, message: "Hola, tengo una consulta sobre los productos fitness de Queens Fit Wear" }, true),
          ctaUrlTarget: '_blank' 
         },
         {
          ctaTitle: "Ubicación",
          ctaType: "link",
          ctaUrl: "https://maps.app.goo.gl/W2ixgoq2zadPxKtg8",
          ctaUrlTarget: '_blank'
        }
        ] 
       },
    ]
  },
  textGridWithTitle: {
    title: "Descubre nuestra colección de ropa fitness",
    items: [
      {
        title: "Leggings Deportivos",
        content: "Encuentra una amplia selección de leggings deportivos diseñados para brindar comodidad y soporte durante tus entrenamientos.",
        ctaType: "link",
        ctaUrl: "/m/products/filter/itemCategory/5nFXktlkzoCSg6O3hOxD"
      },
      {
        title: "Tops y Sujetadores Deportivos",
        content: "Descubre nuestros tops y sujetadores deportivos que te ofrecen sujeción y estilo mientras te ejercitas.",
        ctaType: "link",
        ctaUrl: "/m/products/filter/itemCategory/Lu85i8mJbiNZZVpWHTKv"
      },
      {
        title: "Conjuntos Fitness",
        content: "Combina estilo y funcionalidad con nuestros conjuntos fitness, ideales para cualquier actividad física.",
        ctaType: "link",
        ctaUrl: "/m/products/filter/itemCategory/v9tI1FQXRwPECL7Ns9T3"
      }
    ]
  },
  ctaAccordion: {
    title: "¿En qué podemos ayudarte?",
    subtitle: "Explora nuestras opciones",
    items: [
      {
        title: "Productos",
        content: "Descubre nuestra amplia variedad de ropa fitness para mujeres, diseñada para elevar tu rendimiento en el gimnasio.",
        ctaTitle: "Ver Productos",
        ctaType: "link",
        ctaUrl: "/m/products/filter"
      },
      {
        title: "Envíos",
        content: "Ofrecemos envíos rápidos y seguros a nivel nacional para que recibas tus prendas en tiempo récord.",
        ctaTitle: "Consultar Delivery",
        ctaType: "whatsapp",
        ctaUrl: "https://wa.me/595985506001?text=Hola,%20tengo%20una%20consulta%20sobre%20su%20delivery"
      },
      {
        title: "Contacto",
        content: "Si tienes alguna consulta o necesitas asesoramiento, no dudes en contactarnos. Estamos aquí para ayudarte.",
        ctaTitle: "Contactarnos",
        ctaType: "whatsapp",
        ctaUrl: "https://wa.me/595985506001?text=Hola,%20tengo%20una%20consulta%20sobre%20los%20productos%20fitness%20de%20Queens%20Fit%20Wear"
      },
      {
        title: "Únete a nuestro equipo",
        content: "Forma parte de nuestra familia y únete como distribuidora de Queens Fit Wear. Obtén beneficios exclusivos.",
        ctaTitle: "Ver Oportunidades",
        ctaType: "whatsapp",
        ctaUrl: "https://wa.me/595985506001?text=Hola,%20estoy%20interesada%20en%20unirme%20como%20distribuidora%20de%20Queens%20Fit%20Wear"
      }
    ]
  },
  mainOutstandingProduct: {
    title: "Producto Destacado",
    subtitle: "Leggings PowerFlex - Máximo Confort y Flexibilidad"
  },
  gridOutstandingProducts: {
    title: "Productos Destacados",
    subtitle: "Descubre nuestras mejores ofertas"
  },
  customerReviews: {
    title: "Opiniones de nuestras clientas",
    subtitle: "Descubre lo que dicen",
    content: "Nuestras clientas aman la comodidad y estilo de nuestras prendas fitness. Aquí tienes algunas de sus opiniones.",
    items: [
      {
        title: "¡Amo mis leggings!",
        content: "Los leggings PowerFlex son increíbles, me dan libertad de movimiento y son muy cómodos."
      },
      {
        title: "Gran calidad",
        content: "Toda la ropa de Queens Fit Wear está hecha con materiales de alta calidad, definitivamente volveré a comprar."
      },
      {
        title: "Estilo y funcionalidad",
        content: "Me encanta cómo me veo y me siento cuando uso la ropa de Queens Fit Wear en el gimnasio."
      },
      {
        title: "Recomendado para todas",
        content: "Estas prendas son ideales para mujeres activas que buscan comodidad y estilo en sus entrenamientos."
      }
    ]
  },
  ctaOverlapImgAndContent: {
    frontImageUrl: "/images/foto-superpuesta-2.jpg",
    backImageUrl: "/images/foto-superpuesta-1.jpg",
    title: "/images/icono-lema-dorado.png",
    content: "Explora nuestra colección de ropa fitness para mujeres, diseñada para elevar tu rendimiento en cada entrenamiento.",
    ctaButton: {
        ctaTitle: "Ir a la tienda",
        ctaType: "link",
        ctaUrl: "/m/products/filter"
      },
  },
  frecuentAnswers: {
    title: "Preguntas frecuentes",
    items: [
      {
        title: "¿Cómo elegir la talla adecuada?",
        content: "Te recomendamos revisar nuestra guía de tallas para asegurarte de seleccionar la talla perfecta para ti."
      },
      {
        title: "¿Puedo lavar la ropa en lavadora?",
        content: "Sí, la mayoría de nuestras prendas son aptas para lavadora. Sin embargo, sigue las instrucciones de lavado en la etiqueta para un cuidado óptimo."
      },
      {
        title: "¿Hacen envíos internacionales?",
        content: "Actualmente, realizamos envíos solo a nivel nacional. Estamos trabajando en expandirnos internacionalmente pronto."
      },
      {
        title: "¿Qué ventajas obtengo como distribuidora?",
        content: "Nuestras distribuidoras disfrutan de precios especiales, acceso anticipado a nuevas colecciones y entrenamiento exclusivo para potenciar sus ventas."
      }
    ]
  }
};
