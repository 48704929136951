import { useState } from 'react';
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import { processRenderProps } from './dataTypeUtils';
import {
  FormField,
  FormFieldSelectOneEntityDocument
} from '../Form';
import {
  // Select
  FilterRenderSelectOneEntityDocument,
  FilterCreatorSelectOneEntityDocument,
} from '../Filters';


export default {
  Render(props) {
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);

    return (
      <FormFieldSelectOneEntityDocument 
        name={_fieldName}
        {...param}
        {...style}
        {...props}
        entitySlug={param.entitySlug} // foreign entity
        disabled={taxonomyTypeData.readOnly}
      />
    );
  },

  RenderFilter(props) {
    return <FilterRenderSelectOneEntityDocument {...props} DataTypeRender={this.Render} isFilter={true} />
  },

  RenderFilterParam(params) {
    return <FilterCreatorSelectOneEntityDocument {...params} />
  },

  RenderFilterPreview({ values }) {
    return <FilterRenderSelectOneEntityDocument values={values} DataTypeRender={this.Render} />
  },

  RenderInputParams: ({ values, param }) => {
    const [ tab, setTab ] = useState(values?.param?.fromJson ? 'json' : 'entity');

    return (<>
      <IonSegment value={tab}>
        <IonSegmentButton value="entity" onClick={() => setTab('entity') }>
          <IonLabel>Entidad</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="json" onClick={() => setTab('json') }>
          <IonLabel>JSON</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      <br />
      {tab === 'entity' ? (
        <FormFieldSelectOneEntityDocument
          name="param.entityNameSlug" // TODO migrate entitySlug to entityNameSlug
          entitySlug="entities"
          title="Entidad"
          optionsMapper={
            (docs) => 
              docs.map((option) => ({
                value: option?.data?.nameSlug,
                label: option?.data?.name,
              }))
          }
        />
      ) : null}
      {tab === 'json' ? (<>
        <FormFieldSelectOneEntityDocument
          name="param.entityNameSlug" // TODO migrate entitySlug to entityNameSlug
          entitySlug="entities"
          title="Entidad"
          optionsMapper={
            (docs) => 
              docs.map((option) => ({
                value: option?.data?.nameSlug,
                label: option?.data?.name,
              }))
          }
        />
        <FormField name="param.fromJson" title="JSON estático" />
        <FormField name="param.fieldValue" title="Atributo value" />
        <FormField name="param.fieldLabel" title="Atributo label" />
      </>) : null}
    </>);
  },
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
  
  RenderShowParams: ({ values }) => (null),
  
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => {
    const entitySlug = values?.param?.entitySlug || values?.param?.entityNameSlug; // retrocompatible
    return (
      <FormFieldSelectOneEntityDocument
        entitySlug={entitySlug}
        {...formFieldProps}
      />
    );
  }
}