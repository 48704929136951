import { useEffect } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Capacitor } from "@capacitor/core";

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import "dayjs/locale/es";

import { addLogEvent } from "./firebase";
import { ModuleProvider } from "./context/ModuleContext";
import { AuthProvider } from "./modules/user/AuthContext";
import { RouteHome } from "./customModules/pages/RouteHome";
import { RoutePublicModulePage } from "./customModules/layout/RoutePublicModulePage";
import { ScrollUtilsProvider } from "./modules/panel/useScrollUtils";
// import { Register } from "./routes/Register";
import { Login } from "./modules/user/Login";

////////////////////////////////////////////////////////////////
// FOR ONE USE ONLY
////////////////////////////////////////////////////////////////
import { Install } from "./modules/panel/Install";
////////////////////////////////////////////////////////////////

import AppPanel from "./AppPanel";
import { LayoutHomeProvider } from "./modules/layoutHome/useLayoutHome";


setupIonicReact();

dayjs.extend(isSameOrBefore)
dayjs.extend(utc);
dayjs.extend(localizedFormat)
dayjs.locale('es');

function LogLocation ({ children }) {
  let location = useLocation();
  
  // log page
  useEffect(() => {
    setTimeout(() => {
      addLogEvent('page_view', {
        // page_location: location.href,
        page_path: location.pathname,
        page_title: document.title,
      }); // analytics
    }, 1000);
  }, [location]);

  return children;
}

function App() {
  return (
    <div className="text-black h-screen flex font-brand-main">
      <IonApp>
        <AuthProvider>
        <ModuleProvider>
        <LayoutHomeProvider>
        <ScrollUtilsProvider>
        <LogLocation>
        <IonReactRouter>
          <IonRouterOutlet>
            <Switch>
              <Route name="home" path="/" exact render={props => {
                if (Capacitor.isNativePlatform()) {
                  return <Login {...props} />
                } else {
                  return (
                    <RouteHome {...props} />
                  );
                }
              }}/>
              {/* Module custom routes */}
              <Route path="/m/:entitySlug/:action/*" component={RoutePublicModulePage} />
              <Route path="/m/:entitySlug/:action" component={RoutePublicModulePage} />

              <Route name="login" path="/login" component={Login} />
              {/* <Route path="/login-with-phone" component={LoginWithPhone} /> */}
              {/* <Route path="/register" component={<Register />} /> */}
              {/* <Route path="/install" component={Install} /> */}

              <Route path="/cart/:cartId" render={({ match }) => {
                return <Redirect to={`/m/carts/view/id/${match.params.cartId}`} />
              }} />
              <Route path="/cartItemVariants/:mainItemId/:itemId" render={({ match }) => {
                return <Redirect to={`/m/products/view/main/${match.params.mainItemId}/variant/${match.params.itemId}`} />
              }} />

              {/* Admin Panel */}
              <AppPanel />
            </Switch>
          </IonRouterOutlet>
        </IonReactRouter>
        </LogLocation>
        </ScrollUtilsProvider>
        </LayoutHomeProvider>
        </ModuleProvider>
        </AuthProvider>
      </IonApp>
      <Toaster 
        toastOptions={{
          duration: 8000,
          position: "bottom-center",
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            fontFamily: 'Lato'
          }
        }}
      />
      <div className="hidden">
        <div className="
          bg-gray-300 text-gray-300 bg-blue-700 ring-blue-700 border-blue-700 text-blue-700 bg-amber-600 ring-amber-600 border-amber-600 text-amber-600 bg-stone-400 text-stone-400
          opacity-80 cursor-not-allowed
        "></div>
      </div>
    </div>
  );
}

export default App;
