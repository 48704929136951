import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Model from "../../libs/ModelClass";
import { getConfig } from "../../config";
import { parseAttrValParams } from '../../libs/utils';
import useBreakpoints from '../../libs/useBreakpoints';
import { stringsLayout } from "../specSite";
import { animateScroll as scroll } from 'react-scroll';
import { usePartOfModule } from "../../components/Module/PartOfModule";
import { Page404 } from './ErrorPages';
import { useModule } from "../../context/ModuleContext";
import { PageHeaderHeroStatic } from '../../modules/blockStack/blocks/BlockPageHeaderAHero';
import { PageFooterALinksFixedCta } from '../../modules/blockStack/blocks/BlockPageFooterALinksFixedCta';
import { LayoutHome } from "../../modules/layoutHome/LayoutHome";


export function RoutePublicModulePage(props) {
  let { history, match } = props;
  const config = getConfig();
  const { user, isAllowed } = useModule();
  const { isMinBreakpointActive } = useBreakpoints();
  
  const { entitySlug, action, 0: attrValParams } = match.params;
  const parsedParams = parseAttrValParams( attrValParams );

  const routeOfModule = usePartOfModule({
    type: 'routePublic',
    entitySlug,
    action
  });

  const params = { 
    parsedParams,
    entitySlug, 
    action,
    config,
    user,
    isAllowed, 
    Model,
    isMinBreakpointActive, 
    match, 
    history, 
  };

const HeaderMenu = () => (<>
    <Link
      to="/"
      title={config.siteName}
      className="ml-1 py-2 px-2 mr-2 md:px-6 text-gray-200 hover:text-gray-400 md:text-lg lg:text-xl text-sm cursor-pointer"
      onClick={() => scroll.scrollToTop()}
    >
      Inicio
    </Link>
  </>);

  if (routeOfModule?.PageStack) {
    return (
      <routeOfModule.PageStack {...props} {...params} />
    )
  }

  return (
    routeOfModule?.Content ? (
      <LayoutHome {...props} mainSectionClass="px-0" HeaderMenu={HeaderMenu}>
        {/* <MetaTags>
          <title>{getPageTitle(config.projectTitle)}</title>
          <meta name={getPageTitle()} content={config.projectTitle} />
        </MetaTags> */}

        <PageHeaderHeroStatic data={stringsLayout.header} history={history} />

        <routeOfModule.Content {...props} {...params} />

        <PageFooterALinksFixedCta data={stringsLayout.footer} showCTA={true} showMadeWithLove={true} />
      </LayoutHome>
    ) : (
      <Page404 />
    )
  );
}
