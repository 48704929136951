import { memo, useMemo } from 'react';
import { useEntityFullBySlug } from '../modules/entity/Entity';

const EntityGetOneDocument = ({
  entitySlug,
  docId,
  docWhere,
  children,
  placeholder = null
}) => {
  let param = useEntityFullBySlug({ docId, docWhere, entitySlug });

  const _placeholder = placeholder || <span className="rounded-full bg-gray-100 text-gray-100 text-xs px-1.5">cargando</span>

  if (typeof children !== 'function') {
    return null;
  } 
  else if (param?.entityDoc && param?.doc) {
    return children(param);
  } 
  else if (!param?.entityDoc) {
    return  _placeholder;
  }
  else if (param?.doc === null) {
    return null;
  }
};

export const EntityGetOneDocumentMemo = memo(EntityGetOneDocument, (prevProps, nextProps) => {
  return prevProps.docId === nextProps.docId;
});


export default EntityGetOneDocument;
