import { useState } from "react";
import { IonIcon } from "@ionic/react";
import ReactMarkdown from "react-markdown";
import { 
  globeOutline,
  logoGoogle
} from 'ionicons/icons';
import BtnLoading from "./BtnLoading";
import Modal from "./Modal";
import { useAuth } from "../../modules/user/AuthContext";
import { useLoginUtils } from "../../modules/user/Login";
import { stackClasses } from "../../libs/utils";


export default function BtnSignUpModal(props) {
  let {
    design,
    strings,
    history
  } = props;
  const { user, loginWithGoogleAndVerify } = useAuth();
  const { redirectOnLogin, handleGoogleSignin } = useLoginUtils(user, loginWithGoogleAndVerify, history);
  let [ isOpenModal, setOpenModal ] = useState(false);

  let classes = {
    btnIcon: '!-mb-1 w-7 h-7',
    btnButton: '!text-xl px-4 py-2 !rounded-lg'
  };
  if (design === 'small') {
    classes = {
      btnIcon: '!-mb-1 w-5 h-5',
      btnButton: '!text-base px-3 py-1.5 !rounded-md'
    };
  }
  classes = stackClasses(classes, props.classes);
  classes.hoverColors = 'hover:!bg-brand-tertiary hover:!text-brand-primary-contrast';

  return (<>
    <BtnLoading
      label={strings?.ctaLabel}
      icon={<IonIcon icon={globeOutline} className={`text-brand-primary-contrast ${classes.btnIcon}`} />}
      onClick={() => setOpenModal(true)}
      className={`
        !bg-brand-primary !text-brand-primary-contrast shadow-md !no-underline
        transition-transform duration-300 hover:scale-105
        ${classes.btnButton}
        ${classes.hoverColors}
      `}
    />

    {isOpenModal ? (
      <Modal
        title={strings?.modalTitle}
        open={isOpenModal}
        setOpen={setOpenModal}
      >
        <div className="p-4 markdown prose lg:prose-sm text-left">
          <ReactMarkdown>{strings?.modalContent}</ReactMarkdown>
        </div>

        <div className="p-4 bg-brand-secondary-shade">
          <BtnLoading
            label="Acceder con Gmail"
            icon={<IonIcon icon={logoGoogle} className="!-mb-1 w-5 h-5 text-red-600" />}
            onClickAsync={handleGoogleSignin}
            className="block mx-auto !items-start !justify-start px-3 py-2 w-[190px] !bg-white !text-gray-900 shadow-md !no-underline hover:underline transition-transform duration-300 hover:scale-105"
            colorClass="text-gray-400 hover:text-gray-700"
          />
        </div>
      </Modal>
    ) : null}
  </>);
}