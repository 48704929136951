import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonBackButton
} from "@ionic/react";
import { 
  arrowBack
} from 'ionicons/icons';
import config from "../../config";
import { LayoutLogo } from "./LayoutLogo";
import { stackClasses } from "../../libs/utils";
import { useLayoutHome } from "../layoutHome/useLayoutHome";
import { specDesign } from "../../customModules/specSite";
import { useEffect } from "react";


export function LayoutPublic({
  Title,
  mainSectionClass,
  ToolbarLeft,
  defaultHref,
  forceBack = false,
  showGoBack = true,
  children,
  history
}) {
  const { layoutDesign, setLayoutDesign } = useLayoutHome();

  useEffect(() => {
    setLayoutDesign(specDesign);
  }, [specDesign]);

  return (
    <IonPage>
      {/* header */}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {showGoBack ? (
              forceBack ? (
                <IonButton onClick={()=> { history.push(defaultHref); }} >
                  <IonIcon slot="icon-only" icon={arrowBack} />
                </IonButton>
              ) : (
                <IonBackButton defaultHref={defaultHref}></IonBackButton>
              )
            ) : null}
            {ToolbarLeft ? (
              <ToolbarLeft />
            ) : null}
          </IonButtons>

          <IonTitle>
            <div className="header-title-logo">
              <LayoutLogo classes={stackClasses(layoutDesign?.logo, layoutDesign?.mainMenu)} />
            </div>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      {/* content */}
      <IonContent className="">
        {children}
      </IonContent>
    </IonPage>
  );
}