import _ from 'lodash';
import { useState } from 'react';
import { Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { withRouter } from 'react-router-dom';
import Modal from './ui/Modal';
import IonBtnLoading from './ui/IonBtnLoading';
import { FormField } from '../components/Form';


const SectionCrudForm = ({
  history,
  doc, // typeof Model
  editStyle, // onsite modal route
  onClose,
  onSave,
  fieldsRequired,
  onValidation = (() => null),
  FormInputFields, // ({form, values, handleSubmit, submitting, fieldsRequired}) => FormFields inputs
  showTitle = true,
  showToast = true,
  showCloseButton = true,
  saveBtnLabel,
  closeBtnLabel,
  classes = {}
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  FormInputFields = FormInputFields || ((props) => (<>
    <FormField name="name" title="Nombre" placeholder="Nombre" {...props} />
  </>));

  const handleSubmit = async (values) => {
    setIsSaving(true);
    await onSave(values);
    setIsSaving(false);
    showToast && toast.success('Se han guardado los datos');
  };

  const validateForm = (values) => {
    const errors = {};
    if (fieldsRequired && fieldsRequired.find(field => field === 'name')) {
      if (!values.name) {
        errors.name = ' ';
      }
    }
    onValidation(values, errors);
    setHasErrors(Object.keys(errors).length > 0);
    return errors;
  };

  const handleClose = () => {
    if (editStyle === 'modal' || editStyle === 'onsite') {
      onClose();
    } 
    else if (editStyle === 'route') {
      history.go(-1);
    }
  };

  const renderContent = ({ form, values, errors, handleSubmit, submitting }) => {    
    return (
      <form onSubmit={(event) => {
        // TODO: prevent submit on pressing enter on a field
        event.preventDefault(); // No funciona
      }}>
        {FormInputFields ? (<FormInputFields {...{form, values, handleSubmit, submitting, fieldsRequired}} />) : ''}
  
        <div className={`mt-8 pt-4 border-t border-gray-200 grid grid-cols-2 gap-4 ${!showCloseButton ? '' : ''}`}>
          {showCloseButton ? (
            <IonBtnLoading
              label={closeBtnLabel || 'Cerrar'}
              size="small"
              fill="solid"
              color="light"
              onClick={handleClose}
            />
          ) : (
            <div />
          )}
          <IonBtnLoading
            label={saveBtnLabel || 'Guardar'}
            size="small"
            fill="solid"
            color="primary"
            onClickAsync={handleSubmit}
            disabled={submitting || isSaving || hasErrors}
          />
        </div>
      </form>
    );
  }
  
  _.defaults(classes, {
    formTitle: 'text-xl font-semibold mb-4 mr-3'
  });

  return (
    <>
      {editStyle === 'modal' ? (
        <Modal title={doc && doc.id ? 'Editar' : 'Agregar'} open={true} setOpen={onClose}>
          <Form
            onSubmit={handleSubmit}
            initialValues={doc ? (doc.data || doc) : {}}
            validate={validateForm}
            render={renderContent}
          />
        </Modal>
      ) : (
        <div>
          {showTitle ? (
            <h2 className={classes.formTitle}>
              {doc?.id === 'new' ? 'Agregar' : 'Editar'}
            </h2>
          ) : null}
          <Form
            onSubmit={handleSubmit}
            initialValues={doc ? (doc.data || doc) : {}}
            validate={validateForm}
            render={renderContent}
          />
        </div>
      )}
    </>
  );
};

export default withRouter(SectionCrudForm);
