import { Field } from 'react-final-form';
import _ from 'lodash';
import { FieldLabel, ShowFieldHelp } from './formParts';
import Select from 'react-select'


const FormFieldSelect = (props) => {
  let {
    name,
    // title,
    options,
    // placeholder,
    fieldsRequired = null,
    validate = null,
    disabled = false,
    // showLabel = true,
    // showClearBtn = true,
    onSelect = (() => null),
    multiple = false
  } = props;

  const validateField = (value) => {
    let error;

    if (fieldsRequired?.includes(name)) {
      if (multiple) {
        if (!value || value.length === 0) {
          error = 'Debes seleccionar al menos una opción';
        }
      } else {
        if (value === undefined || value === '') {
          error = 'Este campo es obligatorio';
        }
      }
    }

    if (!error && validate && typeof validate === 'function') {
      error = validate(value);
    }

    return error;
  };

  return (
    <div className={`pb-3 ${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={(args) => validateField(args)}>
        {({ input, meta }) => {
          const onChange = (value) => {
            if (multiple) {
              const selectedValues = value?.length ? _.map(value, 'value') : null;
              input.onChange(selectedValues);
              onSelect(selectedValues);
            } else {
              input.onChange(value?.value);
              onSelect(value?.value);
            }
          };

          let value;
          if (multiple) {
            //// array
            value = _.map(input.value, (value, index) => {
              return options.find(option => option.value === value);
            });
            //// true map
            // value = _.map(input.value, (trueVal, field) => {
            //   return options.find(option => option.value === field);
            // });
          }
          else {
            value = input?.value ? (options.find(opt => opt.value === input.value)) : null;
          }

          return (<>
            <FieldLabel {...{...props, input, meta}} />
            <div className="">
              <Select
                options={options}
                isMulti={multiple}
                placeholder={`Seleccionar`}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: 'lightgray',
                    primary: 'darkgray',
                  },
                })}
                value={value}
                onChange={onChange}
                isDisabled={disabled}
              />
            </div>
            {/* error */}
            <ShowFieldHelp name={input?.name} input={input} meta={meta} fieldsRequired={fieldsRequired} />
          </>)
        }}
      </Field>
    </div>
  );
};

export default FormFieldSelect;
