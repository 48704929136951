import { LayoutAdmin } from "./LayoutAdmin";
import { useModule } from "../../context/ModuleContext";
import PartOfModule from "../../components/Module/PartOfModule";
import config from "../../config";


export const AdminHomeContent = ({ isAllowed, user, history }) => {
  return (<>
    <div className="p-2 sm:pt-4">
      
    </div>
  </>);
};

export function AdminTabHome(props) {
  const { isAllowed, user } = useModule();

  return (
    <LayoutAdmin 
      history={props.history}
      title="Panel"
      defaultHref={config.modules.panel.mainPath}
    >
      <div className="ion-padding">
        <PartOfModule
          type="main"
          action="AdminHomeBeforeContent"
          param={{ isAllowed, user }}
        />

        <PartOfModule
          type="main"
          action="AdminHomeContent"
          param={{ isAllowed, user }}
        />

        <PartOfModule
          type="main"
          action="AdminHomeAfterContent"
          param={{ isAllowed, user }}
        />
      </div>
    </LayoutAdmin>
  );
}