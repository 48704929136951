import { processRenderProps } from './dataTypeUtils';
import {
  FormField,
  FormFieldSelect,
} from '../Form';
import {
  // Text
  FilterRenderText,
  FilterCreatorText,
} from '../Filters';


const valueFormatter = (value) => {
  if (typeof value === 'undefined' || value === null) {
    return;
  }
  return value.toString();
};

export default { 
  Render(props) {
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);
    param.formatter = valueFormatter;

    return (
      <FormField
        name={_fieldName}
        type="text"
        {...param}
        {...style}
        {...props}
      />
    );
  },
  
  RenderFilter(props) {
    return <FilterRenderText {...props} DataTypeRender={this.Render} />
  },

  RenderFilterParam(props) {
    return <FilterCreatorText {...props} />
  },

  valueFormatter,

  RenderInputParams: ({ values, param }) => (
    <>
      <FormField
        name="param.minLength"
        title="Longitud mínima"
        type="number"
        maxValue={values.param && parseInt(values.param.maxLength)}
      />
      <FormField
        name="param.maxLength"
        title="Longitud máxima"
        type="number"
        minValue={values.param && parseInt(values.param.minLength)}
      />
    </>
  ),
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (
    <>
      <FormFieldSelect
        name="show.text.operator"
        title="Operador"
        placeholder="Operador"
        options={[
          { value: 'hasLength', label: 'Tiene contenido' },
          { value: 'isEmpty', label: 'Está vacío' },
          { value: 'eq', label: 'es igual' },
        ]}
      />
      {values.show?.text?.operator === 'eq' ? (
        <FormField
          name="show.text.value"
          title="Valor"
          placeholder="valor"
        />
      ) : ''}
    </>
  ),
  
  RenderShowParams: ({ values }) => (null),
  
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => (
    <FormField {...formFieldProps} type="text" />
  ),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}