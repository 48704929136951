
import { Link } from 'react-router-dom';
import { AnimationAddClassWhenVisible } from '../../../components/ui/AnimationAddClassWhenVisible';
import ContactBTN from '../../../components/ContactBTN';
import { LayoutLogo } from '../../panel/LayoutLogo';
import { useLayoutHome } from '../../layoutHome/useLayoutHome';
import { lineBreaks, stackClasses } from '../../../libs/utils';
import BtnSignUpModal from '../../../components/ui/BtnSignUpModal';


export default function () {
  return {
    type: 'pageHeader',
    variation: 'heroStatic',
    template: PageHeaderHeroStatic,
    params: {
      design: {
        type: 'string',
        options: ['mini', 'full'],
        default: 'full'
      }
    }
  };
};

export function PageHeaderHeroStatic({ data, history, design }) {
  const { layoutDesign } = useLayoutHome();

  return ( 
    <header id='header' className=" bg-cover bg-right-top bg-no-repeat" style={{ backgroundImage: `url('${lineBreaks(data?.backgroundImageUrl)}')` }}>
      <div className="bg-gradient-to-br from-brand-primary to-brand-tertiary bg-fixed">
        {/* intro */}
        <div className="mx-auto pt-6 px-6 pb-32 xs:pb-32 xs:pt-10 xs:px-10 md:px-20 mb:pb-44 lg:pb-48 md:max-w-4xl xl:max-w-6xl">
          {/* logo */}
          <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[100ms]">
            <div className="mt-24 md:mt-28">
              <Link to={`/`}>
                <LayoutLogo classes={stackClasses(
                  {
                    logoContainer: 'md:gap-10',
                    // logoIcon: 'h-[60px] md:h-[70px] xl:h-[80px]',
                    // logoText: 'h-[40px] md:h-[60px] xl:h-[75px]'
                  },
                  layoutDesign?.logo,
                  layoutDesign?.header
                )} />
              </Link>
            </div>
          </AnimationAddClassWhenVisible>
          {/* content*/}
          {design !== 'mini' ? (
            <div className="mt-12 md:mt-16 xl:mt-20 pr-4 xs:pr-10 sm:pr-20 lg:pr-32 max-w-sm md:max-w-md lg:max-w-2xl">
              <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
                <h1 className='mb-2 text-4xl lg:text-5xl xl:text-5xl text-white font-brand-main font-bold'>
                  {lineBreaks(data?.title)}
                </h1>
              </AnimationAddClassWhenVisible>
              <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                <h2 className='text-2xl md:text-3xl lg:text-3xl text-white/80 font-brand-main font-medium leading-snug'>
                  {lineBreaks(data?.subtitle)}
                </h2>
              </AnimationAddClassWhenVisible>
              <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[700ms]">
                <div className="mt-8">
                  {data.ctaType === "whatsapp" ? (
                    <ContactBTN className={'mx-auto'} label={data.ctaLabel} design="small" />
                  ) : null}
                  {data.ctaType === "signup" ? (
                    <BtnSignUpModal strings={data} history={history} design="small" />
                  ) : null}
                </div>
              </AnimationAddClassWhenVisible>
            </div>
          ) : null}
        </div>
      </div>
    </header>
  )
}