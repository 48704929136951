import { processRenderProps } from './dataTypeUtils';
import PartOfModule from '../Module/PartOfModule';
import {
  FormFieldCustom
} from '../Form';

export default {
  Render(props) {
    const {
      entitySlug,
      onChange,
      fieldsRequired
    } = props;
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);
    
    return (
      <FormFieldCustom
        name={_fieldName}
        title={title}
        placeholder={title}
        fieldsRequired={fieldsRequired}
        Render={({ value, onChange }) => (
          <PartOfModule
            type="codedDataType"
            fieldName={_fieldName}
            action="RenderForm"
            entitySlug={entitySlug}
            param={{ value, onChange, title, taxonomyTypeData, param, props }} 
          />
        )}
        {...param}
        {...props}
      />
    );
  },

  RenderInputParams: (props) => {
    let { values, taxonomyTypeDoc, entitySlug } = props;
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);

    return (<>
      <PartOfModule
        type="codedDataType"
        fieldName={_fieldName}
        action="RenderInputParams"
        entitySlug={entitySlug}
        param={{ values, title, taxonomyTypeData, param, formApi: props?.formApi }} 
      />
    </>);
  },
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
  
  RenderShowParams: ({ values }) => (null),
  
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => (null),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}