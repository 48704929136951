import { useEffect, useMemo, useState } from "react";
import slugify from "slugify";
import _ from "lodash";
import Model from "../../libs/ModelClass";


/**
 * @param {*} param0 
 * @returns isAvailable, alreadyOwned
 */
export const verifyOnModelTextId = async ({ textId, field, entitySlug, docId }) => {
  let doc = await Model.extend(entitySlug).findOneBy(field, textId);
  if (doc) {
    if (docId && docId === doc.id) {
      return { isAvailable: true, alreadyOwned: true };
    }
    return { isAvailable:  false };
  }
  return { isAvailable: true };
};

const RawInputTextId = (props) => {
  let { 
    name,
    value,
    onChange,
    className,
    entitySlug,
    title,
    placeholder,
    values,
    minLength,
    maxLength
  } = props;
  const [ textId, setTextId ] = useState(value);
  const [ verifyResult, setVerifyResult ] = useState();

  useEffect(() => {
    checkTextId();
  }, [textId]);
  
  const checkTextId = async () => {
    if (textId?.length > minLength && textId?.length <= maxLength) {
      const verifyResult = await verifyOnModelTextId({ textId, field: name, entitySlug, docId: values?.id });
      setVerifyResult(verifyResult);
      if (!verifyResult.isAvailable) {
        onChange('');
      }
    } else {
      setVerifyResult();
    }
  }

  const setValue = (value) => {
    const slug = slugify(value, { 
      lower: true,
      remove: /[*+~_.()'"!:@]/g,
      trim: false
    });
    setTextId(slug);
    onChange(slug);
  };

  return (
    <div className="space-y-2">
      {/* input */}
      <div className="">
        <input
          type="text"
          value={textId}
          onChange={(e) => setValue(e.target.value)}
          className="text-left border rounded w-full font-normal py-2 px-3"
          placeholder={placeholder}
        />
      </div>
      {/* message */}
      <div className="text-sm">
        {textId && verifyResult && !verifyResult.isAvailable ? (
          <div className="text-red-700">
            ID no disponible.
          </div>
        ) : null}
        {textId && verifyResult && verifyResult.isAvailable && !verifyResult.alreadyOwned ? (
          <div className="text-green-700">
            ID disponible.
          </div>
        ) : null}
        {textId && verifyResult && verifyResult.isAvailable && verifyResult.alreadyOwned ? (
          <div className="text-gray-700">
            ID sin cambios.
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default RawInputTextId;