import React, { useRef } from 'react';
import { IonIcon } from '@ionic/react';
import { copyOutline } from 'ionicons/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';


function ClipboardInput({ value }) {
  const inputRef = useRef(null);

  const seleccionarTexto = () => {
    inputRef.current && inputRef.current.select();
  };

  const showAlert = () => {
    toast.success('Enlace copiado al portapapeles');
  };

  return (
    <CopyToClipboard text={value}>
      <div className="inline-flex flex-row border border-gray-200 rounded-md overflow-hidden" onClick={showAlert}>
        <input
          type="text"
          className="outline-none basis-auto p-2 font-brand-secondary"
          defaultValue="¡Texto que quieres copiar!"
          ref={inputRef}
          onClick={seleccionarTexto}
          value={value}
        />
        <button className="bg-gray-600 text-white rounded-md px-2 pt-1.5 basis-8" type="button">
          <IonIcon slot="start" icon={copyOutline} className="w-6 h-6" />
        </button>
      </div>
    </CopyToClipboard>
  );
}

export default ClipboardInput;