import { processRenderProps } from './dataTypeUtils';
import {
  FormField,
  FormFieldCustom,
  RawInputTime
} from '../Form';


const valueFormatter = (value) => {
  return value;
}

export default {
  Render(props) {
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);
    param.formatter = valueFormatter;

    return (
      <FormFieldCustom
        Render={RawInputTime}
        name={_fieldName}
        {...param}
        {...style}
        {...props}
      />
    );
  },

  RenderInputParams: ({ values, param }) => (
    <>
      <FormField
        name="param.placeholder"
        title="Placeholder"
        placeholder="Placeholder"
        showLabel={true}
      />
    </>
  ),
  
  RenderShowParams: ({ values }) => (
    <>
      {/* <FormField
        name="show.format"
        title="Formato dayjs().format()"
        placeholder="Formato dayjs().format()"
        showLabel={true}
      /> */}
    </>
  ),

  RenderShowPreview: ({ values }) => (null),
  
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => (
    <FormFieldCustom
      Render={RawInputTime}
      {...formFieldProps}
    />
  ),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}