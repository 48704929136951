import _ from 'lodash';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import { useAuth } from '../../modules/user/AuthContext';
import { useEffect, useState } from 'react';
import { IonButton, IonLabel } from '@ionic/react';
import { getConfig } from '../../config';
import { DocCard } from '../../routes/AdminEntityCrudShow';
import { useEntityFullBySlug } from '../entity/Entity';


export function Content() {
  const config = getConfig();
  const { user } = useAuth();
  const [ userDocRefreshed, setUserDoc ] = useState(user.userDoc);
  const entitySpects = useEntityFullBySlug({ entitySlug: config.modules.user.usersEntitySlug });

  useEffect(() => {
    setUserDoc(user.userDoc);
  }, [user.userDoc.data]);

  return (
    <div>
      {userDocRefreshed ? (
        <DocCard
          doc={userDocRefreshed}
          entitySpects={entitySpects}
        />
      ) : null}

      <div className="flex place-content-center mt-10">
        <IonButton routerLink="/a/user/update-data" routerDirection="forward" color="primary" size="small">
          <IonLabel className="ml-2 text-brand-primary-contrast">
            Actualizar datos
          </IonLabel>
        </IonButton>
      </div>
    </div>
  );
}


export function AdminUserCustomerData(props) {
  const { user } = useAuth();
  const config = getConfig();

  return (
    <LayoutAdmin 
      history={props.history} 
      defaultHref={config.modules.panel.mainPath}
      title="Mis datos"
    > 
      <div className="ion-padding">
        {user?.userDoc?.id ? <Content/> : null}
      </div>
    </LayoutAdmin>
  );
}
