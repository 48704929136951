import { processRenderProps } from './dataTypeUtils';
import {
  FormFieldRelatedList
} from '../Form';


export default {
  Render(props) {
    const {
      values,
      fieldsRequired
    } = props;
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);

    
    return (
      <FormFieldRelatedList
        name={_fieldName}
        title={title}
        placeholder={title}
        fieldsRequired={fieldsRequired}
        values={values}
        {...param}
        {...style}
        {...props}
        type="materials"
        entitySlug={param?.entityNameSlug || props?.entitySlug} // retrocompatible
      />
    );
  },

  RenderFilter: (props) => (null),
  RenderFilterParam: (props) => (null),
  RenderFilterPreview: ({ values }) => (null),
  RenderInputParams: ({ values, param }) => (null),
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
  RenderShowParams: ({ values }) => (null),
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => (null),
  getDefaultTaxonomyTypeDesign: () => ('default')
}