import { registerBlocks } from "./BlockStack";
import BlockPageHeaderAHero from "./blocks/BlockPageHeaderAHero";
import BlockPageFooterALinksFixedCta from "./blocks/BlockPageFooterALinksFixedCta";
import BlockFrecuentAnswersAColumn from "./blocks/BlockFrecuentAnswersAColumn";
import BlockTextGridAExtended from "./blocks/BlockTextGridAExtended";
import BlockTextGridBCompact from "./blocks/BlockTextGridBCompact";
import BlockCtaMenuAGrid from "./blocks/BlockCtaMenuAGrid";
import BlockReviewsAGrid from "./blocks/BlockReviewsAGrid";
import BlockContactCta from "./blocks/BlockContactCtaACentered";
import BlockCtaAHero from "./blocks/BlockCtaAHero";
import BlockTextGridCExtendedCarousel from "./blocks/BlockTextGridCExtendedCarousel";
import BlockCtaMenuBAccordionHero from "./blocks/BlockCtaMenuBAccordionHero";


registerBlocks([
  BlockPageHeaderAHero,
  BlockPageFooterALinksFixedCta,
  BlockFrecuentAnswersAColumn,
  BlockTextGridAExtended,
  BlockTextGridBCompact,
  BlockTextGridCExtendedCarousel,
  BlockCtaMenuAGrid,
  BlockCtaMenuBAccordionHero,
  BlockReviewsAGrid,
  BlockContactCta,
  BlockCtaAHero
]);

export default function ({ setPermissions }) {
  return {
    slug: 'blockStack',
    label: 'Block stack',
    entities: {},
    permissions: [],
    components: {}
  };
};