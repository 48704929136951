import { Capacitor } from "@capacitor/core";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import toast from 'react-hot-toast';
import _ from 'lodash';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import {
  // createUserWithEmailAndPassword,
  // signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCredential,
  // sendPasswordResetEmail,
  getAuth
} from "firebase/auth";
import { auth, addUserProperties } from "../../firebase";
import Model from "../../libs/ModelClass";
import config from "../../config";

const authContext = createContext();

const errorCodes = {
  userNotFound: 'No está registrado en el sistema',
  userNotAccess: 'No tiene acceso al sistema',
  userNotRoles: 'No tiene roles válidos',

  roleNotFound: 'No existe el rol',
  credentialNotFound: 'No existe la credencial',
}

export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) throw new Error("There is no Auth provider");
  return context;
};

/**
 * @param email
 * @param phone
 * @returns userDoc, credentialDoc, rolesDoc
 */
export const readUserCredentials = async ({ email, phone, throwErrors }) => {
  if (!email && !phone) {
    return false;
  }
  const UserProfile = Model.extend('usersProfiles');
  const Credential = Model.extend('credentials');
  let userDoc;
  if (email) {
    userDoc = await UserProfile.filterByAttributes({ email: email });
    userDoc = userDoc.filter(userDoc => !userDoc?.data?.deleted);
    userDoc = userDoc[0];
    if (!userDoc?.data?.email && throwErrors) {
      throw new Error(errorCodes.userNotFound);
    }
  }
  else if (phone) {
    userDoc = await UserProfile.filterByAttributes({ phone });
    userDoc = userDoc.filter(userDoc => !userDoc?.data?.deleted);
    userDoc = userDoc[0];
    if (!userDoc?.data?.phone && throwErrors) {
      throw new Error(errorCodes.userNotFound);
    }
  }
  let rolesDoc;
  let credentialDoc = await Credential.filterByAttributes({ profile: userDoc?.id, isActive: 'true' });
  credentialDoc = credentialDoc.filter(credentialDoc => !credentialDoc?.data?.deleted);
  credentialDoc = credentialDoc[0];
  if (!credentialDoc?.data?.isActive && throwErrors) {
    throw new Error(errorCodes.userNotAccess);
  }
  if (credentialDoc?.data?.roles) {
    const Role = Model.extend('roles'); 
    rolesDoc = await Role.findById(credentialDoc?.data?.roles);
    if (!rolesDoc?.data && throwErrors) {
      throw new Error(errorCodes.userNotRoles);
    }
  }
  const fireUser = getAuth()?.currentUser;
  return { userDoc, credentialDoc, rolesDoc, fireUser: fireUser?.user ? fireUser.user : fireUser };
};

export const createCustomerUserWithCredentials = async ({ email, phone }) => {
  if (!email && !phone) {
    return false;
  }
  const UserProfile = Model.extend('usersProfiles');
  const Credential = Model.extend('credentials');
  const Role = Model.extend('roles');
  const userDoc = await UserProfile.create({
    email, phone
  });
  const rolesDoc = await Role.filterByAttributes({
    nameSlug: config.modules.user.userDefaultRoleSlug
  });
  if (!rolesDoc.length) {
    throw new Error(errorCodes.roleNotFound);
  }
  const credentialDoc = await Credential.create({
    profile: userDoc.id,
    roles: rolesDoc[0]?.id,
    isActive: true
  });
  const fireUser = getAuth()?.currentUser;
  return { userDoc, credentialDoc, rolesDoc: rolesDoc[0], fireUser: fireUser?.user ? fireUser.user : fireUser };
};

export const updateCredentialsOfUserByRoleSlug = async ({ roleSlug, userId }) => {
  const Credential = Model.extend('credentials');
  const Role = Model.extend('roles');
  const rolesDoc = await Role.findOneBy('nameSlug', roleSlug);
  if (!rolesDoc) {
    throw new Error(errorCodes.roleNotFound);
  }
  const credentialDoc = await Credential.findOneBy('profile', userId);
  if (!credentialDoc) {
    throw new Error(errorCodes.credentialNotFound);
  }
  credentialDoc.data.roles = rolesDoc.id;
  await credentialDoc.save();
};

export function AuthProvider({ children }) {
  const location = useLocation();
  const [user, setUser] = useState(null); // become {} ance loaded

  // const signup = (email, password) => {
  //   return createUserWithEmailAndPassword(auth, email, password);
  // };

  // const login = (email, password) => {
  //   return signInWithEmailAndPassword(auth, email, password);
  // };

  const loginWithGoogleAndVerify = async () => {
    let signedResult;
    if (Capacitor.isNativePlatform()) {
      // Create credentials on the native layer
      const result = await FirebaseAuthentication.signInWithGoogle();
      // Link on the web layer using the id token
      const credential = GoogleAuthProvider.credential(result.credential?.idToken);
      const auth = getAuth();
      signedResult = await signInWithCredential(auth, credential);
    } else {
      const googleProvider = new GoogleAuthProvider();
      signedResult = await signInWithPopup(auth, googleProvider);
    }
    if (signedResult?.user?.email || signedResult?.user?.phoneNumber) {
      const userWithCredentials = await getOrCreateUserDataByEmailOrPhone({ 
        email: signedResult?.user?.email, 
        phone: signedResult?.user?.phoneNumber
      });
      setUser(userWithCredentials || {}); // mark as loaded but without user
    }
  };

  /**
   * Obtiene los datos del usuario según el *email* ó *phone number*
   * si no existe entonces genera un *customer account* 
   * @param phone
   * @returns userDoc, credentialDoc, rolesDoc
   */
  const getOrCreateUserDataByEmailOrPhone = async ({ email, phone }) => {
    try {
      const userWithCredentials = await readUserCredentials({ email, phone });
      if (userWithCredentials) {
        return userWithCredentials;
      } else {
        return false;
      }
    } catch (error) {
      if (error.message === errorCodes.userNotFound) {
        // create customer account
        try {
          const userWithCredentials = await createCustomerUserWithCredentials({ email, phone });
          return userWithCredentials;
        } catch (error) {
          console.error(error);
          return false;
        }
      }
      else if (error.message === errorCodes.userNotAccess || error.message === errorCodes.userNotRoles) {
        toast.error(errorCodes.userNotAccess);
        return false;
      }
      else {
        console.error(error);
        return false;
      }
    }
  };

  const logout = async () => {
    if (Capacitor.isNativePlatform()) {
      // Sign out on the native layer
      await FirebaseAuthentication.signOut();
    }
    // Sign out on the web layer
    const auth = getAuth();
    await signOut(auth);
    // remove user related data
    setUser({}); // empty object to mark as loaded anyway
    window.localStorage.clear();
  };

  // const resetPassword = async (email) => sendPasswordResetEmail(auth, email);

  useEffect(() => {
    if (user?.userDoc?.data) {
      return user.userDoc.onSnapshot(newDoc => {
        setUser({ ...user, userDoc: newDoc });
      });
    }
  }, [user?.userDoc?.id]);

  useEffect(() => {
    if (user?.credentialDoc?.data) {
      return user.credentialDoc.onSnapshot(async newDoc => {
        if (user.credentialDoc.data.roles !== newDoc.data.roles) {
          window.location.reload();
        }
      });
    }
  }, [user?.credentialDoc?.id]);

  useEffect(() => {
    if (user?.rolesDoc?.data) {
      return user.rolesDoc.onSnapshot(newDoc => {
        setUser({ ...user, rolesDoc: newDoc });
      });
    }
  }, [user?.rolesDoc?.id]);

  ////////////////////////////////
  //
  // Cuando cambia el authState 
  // se busca el user o se crea el user
  // 
  ////////////////////////////////
  // log user params analytics
  // set user data state
  useEffect(() => {
    onAuthStateChanged(auth, async (currentUser) => {
      // attach funnel param to log
      let params = {
        'role': currentUser ? 'logged' : 'guest'
      };
      const searchParams = new URLSearchParams( location.search.split('?')[1] );
      params.funnel = searchParams.has('funnel') 
        ? searchParams.get('funnel')
        : 'none';
      addUserProperties(params); // analytics
      // set user data
      try {
        const userAuth = await readUserCredentials({ 
          email: currentUser?.email, 
          phone: currentUser?.phoneNumber,
          throwErrors: false
        });
        if (
          userAuth?.userDoc?.id
        ) {
          setUser(userAuth || {}); // mark as loaded but without user
        }
        else {
          setUser({});
        }
      } catch (e) {
        kickOut(e);
      }
    });
  }, []);

  const kickOut = (e) => {
    if (_.includes(location.pathname, "/a")) {
      window.location.href = '/'; // force go out
      e?.message && toast.error(e.message);
    }
  };
  
  return (
    <authContext.Provider
      value={{
        // signup,
        // login,
        user,
        logout,
        loginWithGoogleAndVerify,
        getOrCreateUserDataByEmailOrPhone,
        // resetPassword,
      }}
    >
      {children}
    </authContext.Provider>
  );
}