import { useEntityFullBySlug } from '../modules/entity/Entity';
import { typeConfigs } from "../components/DataTypes";
import { assignDeep } from '../libs/utils';
import { useMemo } from 'react';


const Render = (props) => {
  const typeConfig = typeConfigs[props?.taxonomyType?.data?.type];

  if (!typeConfig || !typeConfig.Render) {
    return null;
  }

  return typeConfig.Render(props);
};

export const EntityTaxonomyForm = (props) => {
  const {
    taxonomyTypesDocList,
    only,
    exclude
  } = props;

  return (
    <div className={props?.classes?.renderListContainer}>
      {taxonomyTypesDocList?.map((taxonomyTypeDoc) => {
        const { id, data } = taxonomyTypeDoc;
        if (data?.deleted || data?.hiddeInForms) { return null; }
        if (only?.length && !only?.includes(data.nameSlug)) { return null; }
        if (exclude?.length && exclude?.includes(data.nameSlug)) { return null; }
        let overrideParams = { ...props.overrideParams };
        if (props.paramsByFieldSlug && props.paramsByFieldSlug[data.nameSlug]) {
          overrideParams = assignDeep({}, overrideParams, props.paramsByFieldSlug[data.nameSlug]);
        }
        return (
          <div key={id} className={props?.classes?.renderItemContainer}>
            <Render  
              {...props}
              taxonomyType={taxonomyTypeDoc}
              overrideParams={overrideParams}
            />
          </div>
        );
      })}
    </div>
  );
};

const useEntityTaxonomyForm = ({ entitySlug, fieldsRequired=[], setFieldsRequired }) => {
  const onFetch = ({ taxonomyTypesDocList }) => {
    const addFieldsRequired = [];
    taxonomyTypesDocList.forEach((taxonomyType) => {
      if (taxonomyType.data.required && !taxonomyType.data.deleted) {
        addFieldsRequired.push(taxonomyType.data.nameSlug);
      }
    });
    setFieldsRequired([ ...fieldsRequired, ...addFieldsRequired ]);
  }
  
  const { taxonomyTypesDocList, ...fns } = useEntityFullBySlug({ entitySlug, onFetch });
  const EntityTaxonomyFormMemo = useMemo(() => {
    return (props) => (
      <EntityTaxonomyForm 
        entitySlug={entitySlug}
        taxonomyTypesDocList={taxonomyTypesDocList} 
        fieldsRequired={fieldsRequired} 
        {...props}
      />
    );
  }, [taxonomyTypesDocList]);

  return {
    taxonomyTypesDocList,
    ...fns,
    EntityTaxonomyForm: EntityTaxonomyFormMemo
  };
}

export default useEntityTaxonomyForm;