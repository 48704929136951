import _ from "lodash";
import VisibilitySensor from 'react-visibility-sensor';
import { useEffect, useMemo, useState } from "react";
import { useAsyncMemo } from 'use-async-memo';
import { Pagination } from "../../components/ui/Pagination";
import filterDataToQueryFormatter from "../../components/Filters/filterDataToQueryFormatter";
import getModelQueryCache from "../../libs/ModelQueryCache";
import Model from "../../libs/ModelClass";
import toast from "react-hot-toast";


export const PaginationActions = ({ paginationLibs }) => {
  let {
    nextPage,
    currentPage,
    totalPages,
    setPage
  } = paginationLibs;
  // return null;
  return (
    <VisibilitySensor
      //minTopValue={500} // Ajusta esta distancia según tu necesidad
      partialVisibility={true}
      offset={{ bottom: 10 }}
      delayedCall={false} // Activa el sensor tan pronto como se alcance la distancia mínima
      onChange={nextPage}
    >
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setActive={setPage}
        classes={{ paginationContainer: 'mt-8' }}
      />
    </VisibilitySensor>
  );
};

export const useFilterPagination = (props) => {
  const {
    entitySpecs = {},
    queryParams,
    isEnabled
  } = props;
  const entitySlug = props.entitySlug || entitySpecs.entitySlug;
  const { filterMenuTaxonomyTypes, taxonomyTypesDocList } = entitySpecs;
  const [ currentPage, setPage ] = useState(1);
  const [ perPage, setPerPage ] = useState(10);
  const [ lastQueryString, setLastQueryString ] = useState(null);
  const [ isLoadingFilters, setLoading ] = useState(true);
  const [ pagesData, setPagesData ] = useState(null);
  const [ isEmptyResults, setIsEmptyResults ] = useState(false);
  const [ ItemQueryLibrary, setItemQueryLibrary ] = useState(null);
  const [ totalCount, setTotalCount ] = useState();

  useEffect(() => {
    if (!ItemQueryLibrary) {
      const ModelExtended = Model.extend(entitySlug);
      setItemQueryLibrary(getModelQueryCache(ModelExtended, true));
    }
  }, [entitySlug]);

  const fetchFilteredDocs = async (queryParams = {}, page) => {
    // return;
    if (!isEnabled) { return; }
    try {
      setLoading(true);
      const filterQuery = filterDataToQueryFormatter(queryParams, filterMenuTaxonomyTypes);
      setLastQueryString(JSON.stringify(filterQuery));
      let fetchedPages = await ItemQueryLibrary.filterAndGetPages(filterQuery, {
        page: page,
        limit: perPage,
        orderBy: { field: 'createdAt', direction: 'desc' },
      });
      fetchedPages = fetchedPages.map(page => (
        page.filter(doc => {
          return !doc.data?.deleted;
        })
      ));
      setIsEmptyResults(fetchedPages.length === 1 && fetchedPages[0].length === 0);
      setPagesData(fetchedPages);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.message?.includes('requires an index')) {
        toast.error('Se requiere crear índices');
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filterMenuTaxonomyTypes?.length && ItemQueryLibrary && !isLoadingFilters) {
      // scrollTo(blockId, 0, 300);
      setTimeout(() => {
        fetchFilteredDocs(queryParams, currentPage);
      }, 300);
    }
  }, [currentPage]);

  useEffect(() => {
    if (filterMenuTaxonomyTypes?.length && ItemQueryLibrary) {
      setPage(1); // Reset to first page when queryParams change
      fetchFilteredDocs(queryParams, 1);
    }
  }, [entitySlug, queryParams, filterMenuTaxonomyTypes, ItemQueryLibrary]);

  const totalPages = useAsyncMemo(() => {
    const calcTotalPages = async (lastQueryString) => {
      const ModelExtended = Model.extend(entitySlug);
      const count = await ModelExtended.filterByAttributesCount(JSON.parse(lastQueryString) || {});
      setTotalCount(count);
      return count / perPage;
    };
    return calcTotalPages(lastQueryString);
  }, [lastQueryString]);

  const nextPage = () => {
    if (isLoadingFilters) { return; }
    if (currentPage < totalPages) {
      setPage(currentPage + 1);
    }
  }

  return {
    currentPage,
    setPage,
    isLoadingFilters,
    totalPages,
    totalCount,
    pagesData,
    isEmptyResults,
    nextPage,
    PaginationActions
  };
};