import _ from 'lodash';
import { Fragment } from "react";


export const blockLibrary = {};

export const registerBlocks = (blocks) => {
  blocks.forEach((blockGetDef) => {
    const blockDef = blockGetDef();
    if (!blockLibrary[blockDef.type]) {
      blockLibrary[blockDef.type] = {};
    }
    blockLibrary[blockDef.type][blockDef.variation] = blockDef;
  });
};

const BlockStack = (props) => {
  let {
    specStack
  } = props;

  let rendered = specStack.map(({ type, variation, strings={}, params, template }, index) => {
    if ((!type || !variation) && !template) {
      throw new Error(`The block type or variation is not defined`);
    }

    if (template) {
      return (
        <Fragment key={index}>
          {template({ data: strings, ...params, ...props })}
          {/* <template data={strings} {...params} {...props} /> */}
        </Fragment>
      );
    }

    const blockDef = blockLibrary[type][variation];
    if (!blockDef && !template) {
      throw new Error(`The block ${type} ${variation} is not defined`);
    }

    let paramsValues = {};
    _.forEach(blockDef.params, ({ type, defaults }, field) => {
      if (params?.hasOwnProperty(field)) {
        paramsValues[field] = params[field];
      }
      else {
        paramsValues[field] = defaults;
      }
    });

    return (
      <Fragment key={index}>
        <blockDef.template data={strings} {...paramsValues} {...props} />
      </Fragment>
    );
  });

  return rendered;
};

export default BlockStack;