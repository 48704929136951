import { lineBreaks } from "../../../libs/utils";

export default function HeaderMiniBlock({ data }) {
  return (
    <div id='inicio' className="relative mt-10 md:mt-12">
      <img 
        src={data.imageSrc} 
        alt={data.title}
        className="w-full h-[350px] lg:h-[450px] xl:h-[500px] object-cover object-top"
      />
      
      <div className="absolute top-0 w-full h-[350px] lg:h-[450px] xl:h-[500px] flex flex-col place-content-center">
        <div className="container-width mx-auto space-y-2 md:space-y-3 w-full text-3xl lg:text-5xl uppercase drop-shadow-[0_10px_8px_rgba(0,0,0,0.5)] text-white font-brand-main font-black italic">
          {data.title ? (<h3 className='px-4'>{lineBreaks(data.title)}</h3>) : null}
          {data.subtitle ? (<p className='px-4'>{lineBreaks(data.subtitle)}</p>) : null}
        </div>
      </div>
    </div>
  );
}
