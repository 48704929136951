import { LayoutAdmin } from "../modules/panel/LayoutAdmin";
import Model, { useStateSingleResult } from "../libs/ModelClass";
import { EntityDocListView } from "../components/EntityDocView";
import { getImageURL } from "../libs/utils";
import { useModule } from '../context/ModuleContext';
import { IonButton, IonFab, IonFabButton, IonFabList, IonIcon, IonTitle } from '@ionic/react';
import { 
  ellipsisVerticalOutline,
  createOutline
} from 'ionicons/icons';
import dayjs from 'dayjs';
import { useEntityFullBySlug } from '../modules/entity/Entity';
import { useAsyncMemo } from "use-async-memo";
import { useMemo } from "react";

export const DocCard = (props) => {
  let {
    doc,
    entitySpects,
    underList,
    only,
    exclude,
    outstandingOnly,
    showMainAttr = true
  } = props;

  if (!doc?.data) {
    return null;
  }

  return (
    <div className="p-3 rounded-md border border-gray-200 shadow-md hover:shadow-lg overflow-hidden">
      {doc?.data?.deleted ? (
        <div className="-m-3 mb-2 py-3 text-xs text-center bg-red-500 text-white">
          <span className="uppercase font-bold">Registro eliminado</span>
          {doc?.data?.deletedDate ? (
            <div className="text-xs">
              Fecha: {dayjs(doc?.data?.deletedDate).format('lll')}
            </div>
          ) : null}
        </div>
      ) : null}
      <EntityDocListView
        {...entitySpects}
        doc={doc}
        outstandingOnly={outstandingOnly}
        classes={{
          fieldContainer: 'py-1.5'
        }}
        render={({ ViewData, taxonomyTypesDocList, mainAttr, mainImgAttr }) => (
          <>
            {mainImgAttr && (doc?.data && doc.data[mainImgAttr] && doc.data[mainImgAttr].length) ? (
              <div className="flex flex-row mb-5">
                <div className=" w-[70px] shadow-md hover:shadow-xl">
                  <img src={doc?.data && doc.data[mainImgAttr] && doc.data[mainImgAttr].length && getImageURL(doc.data[mainImgAttr][0], 'xs')} 
                  className="rounded h-[70px] w-full object-cover" /> {/* image xs */}
                </div>
                {showMainAttr ? (
                  <div className="px-2 w-auto">
                    <ViewData field={mainAttr} classes={{ fieldLabel: "hidden", fieldContainer: 'text-2xl font-semibold' }} />
                  </div>
                ) : null}
              </div>
            ) : (
              showMainAttr ? <ViewData field={mainAttr} classes={{ fieldLabel: "hidden", fieldContainer: 'text-2xl font-semibold' }} /> : null
            )}

            {taxonomyTypesDocList.map((taxonomyType) => (
              (mainAttr !== taxonomyType.data.nameSlug)
              && !(
                (only?.length && !only?.includes(taxonomyType.data.nameSlug))
                || (exclude?.length && exclude?.includes(taxonomyType.data.nameSlug))
              )
              ? (
                <ViewData key={taxonomyType.data.nameSlug} field={taxonomyType.data.nameSlug} />
                // TODO: Agregar decorator y classes al TaxonomyType para View
              ) : null
            ))}
          </>
        )}
      />
      {underList ? underList : null}
    </div>
  );
};

const RelViewHasOne = ({ isAllowed, localDoc, localEntity, foreignEntity }) => {
  const entitySpects = useEntityFullBySlug({ entitySlug: foreignEntity?.data?.nameSlug });
  const relDoc = useAsyncMemo(async () => {
    if (entitySpects?.taxonomyTypesDocList) {
      // get foreign related taxonomy
      const field = entitySpects.taxonomyTypesDocList.find((taxonomyTypeDoc) => {
        let result = taxonomyTypeDoc?.data?.type === 'selectOneEntityDocument' 
          && taxonomyTypeDoc?.data.param?.entityNameSlug === localEntity?.data.nameSlug;
        return result;
      });
      // get related document
      if (field) {
        let query = {};
        query[field.data.nameSlug] = localDoc.id;
        const relDoc = await entitySpects.ExtendedModel.filterByAttributes(query);
        return relDoc?.length ? relDoc[0] : null;
      }
    }
  }, [entitySpects?.foreignEntity?.id, entitySpects?.taxonomyTypesDocList]);
  
  return (
    <div className="mt-4">
      {/* <h2 className="text-sm text-gray-600 font-semibold ">{foreignEntity?.data?.name}</h2> */}
      <IonTitle className="">{foreignEntity?.data?.name}</IonTitle>
      {relDoc?.data ? (<>
        <DocCard doc={relDoc} entitySpects={entitySpects} 
          underList={(<div>
            <div className="my-2 border-b border-gray-300"></div>
            <ListBtns {...{
              isAllowed,
              entitySlug: foreignEntity?.data?.nameSlug,
              entityId: foreignEntity?.id,
              docId: relDoc.id
            }} />
          </div>)}
        />
      </>) : null}
    </div>
  );
};

const ListBtns = ({ isAllowed, entitySlug, entityId, docId }) => {
  return (
    <div className="">
      {isAllowed(entitySlug, ['update']) ? (
        <IonButton  size="small" fill="clear" color="primary"
        routerLink={`/a/entity/${entitySlug}/${docId}/form`}>
          Editar
        </IonButton>
      ) : null}
      {/* {isAllowed(entitySlug, ['delete']) ? (
        <button type="button" onClick={() => history.push(`/a/entity/${entitySlug}/${docId}`)}
        className="px-2 py-0.5 text-sm bg-red-200 text-red-900 rounded-md">
          Eliminar
        </button>
      ) : null} */}
    </div>
  );
};

const SpeedDial = ({ isAllowed, entitySlug, entityId, docId }) => {
  return (
    <IonFab slot="fixed" vertical="bottom" horizontal="end" className="fixed xs:right-4 xs:bottom-4">
      {isAllowed(entitySlug, ['update']) ? (<>
        <IonFabButton color="warning">
          <IonIcon icon={ellipsisVerticalOutline}></IonIcon>
        </IonFabButton>
        <IonFabList side="top">
          <IonFabButton href={`/a/entity/${entitySlug}/${docId}/form`}>
            <IonIcon icon={createOutline}></IonIcon>
          </IonFabButton>
        </IonFabList>
      </>) : null}
      {/* {isAllowed(entitySlug, ['delete']) ? (
        <button type="button" onClick={() => history.push(`/a/entity/${entitySlug}/${docId}`)}
        className="px-2 py-0.5 text-sm bg-red-200 text-red-900 rounded-md">
          Eliminar
        </button>
      ) : null} */}
    </IonFab>
  );
};

export function Content({ entity, history, match }) {
  const entitySlug = entity?.data?.nameSlug;
  const { entityId, docId } = match.params;
  const { isAllowed } = useModule();
  let { doc, ...entitySpects } = useEntityFullBySlug({ entitySlug, docId });
  
  const entitiesDocList = useAsyncMemo(async () => {
    if (entitySpects?.EntityModel) {
      let records = await entitySpects.EntityModel.getAll();
      records = records?.filter((doc) => doc.data?.deleted !== true);
      return records;
    }
  }, [doc, entitySpects?.entityDoc]);

  const getRelDocs = (relType) => {
    let relSlugs = entitySpects?.entityDoc?.data['relSlugs' + relType];
    relSlugs = relSlugs?.split(', ');
    const relDocs = relSlugs?.map((entitySlug) => {
      return entitiesDocList?.find((entityDoc) => entityDoc?.data?.nameSlug === entitySlug);
    });
    return relDocs;
  };

  // const relsBelongsTo = getRelDocs('BelongsTo');
  // const relsBelongsToMany = getRelDocs('BelongsToMany');
  const relsHasOne = getRelDocs('HasOne');
  // const relsHasMany = getRelDocs('HasMany');

  return (
    <div className="pb-24">
      <SpeedDial {...{isAllowed, entitySlug, entityId, docId}} />
      {/* Entity card */}
      <IonTitle className="">{entitySpects?.entityDoc?.data?.name}</IonTitle>
      <DocCard {...{doc, entitySpects}}
        underList={(<div>
          <div className="my-2 border-b border-gray-300"></div>
          <ListBtns {...{isAllowed, entitySlug, entityId, docId}} />
        </div>)}
      />
      {/*
        Related cards
      */}
      {/* hasOne */}
      {relsHasOne?.map((entityDoc, index) => (
        <RelViewHasOne key={index} isAllowed={isAllowed} localDoc={doc} localEntity={entitySpects.entityDoc} foreignEntity={entityDoc} />
      ))}
    </div>
  );
}

export function AdminEntityCrudShow(props) {
  const { entitySlug } = props.match.params;
  const entity = useStateSingleResult({
    Model: Model.extend('entities'), 
    nameSlug: entitySlug
  });

  let breadcrumbs = [{
    url: '/a/entity',
    title: 'Listados'
  }];
  if (entity?.data?.showInMenu) {
    breadcrumbs.push({
      url: `/a/entity/${entitySlug}`,
      title: entity?.data?.name
    });
  }
  breadcrumbs.push({
    title: "Ver"
  });

  return (
    <LayoutAdmin 
      history={props.history} 
      // title="Ver"
      defaultHref={entity?.data?.showInMenu ? `/a/entity/${entitySlug}` : `/a/entity`}
      breadcrumbs={breadcrumbs}
    >
      <div className="ion-padding">
        {entity && <Content entity={entity} {...props} />}
      </div>
    </LayoutAdmin>
  );
}
