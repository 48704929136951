import _ from "lodash";
import { routePageStack } from "../layout/RouteLayoutBlock";
import { specDesign, stringsLayout } from "../specSite";
import CtaContactUsBlock from "./Blocks/CtaContactUs";
import CtaImgFullBlock from "./Blocks/CtaImgFull";


const specStack = [
  {
    type: 'cartView',
    variation: 'public',
    params: {
      filterPath: "/m/products/filter",
      viewPath: "/m/products/view",
      imgProportion: 'vertical-wide'
    },
  },
  {
    strings: stringsLayout.contactCta,
    template: CtaContactUsBlock
  },
  {
    strings: stringsLayout.footer,
    template: CtaImgFullBlock
  },
];

export const RouteCartView = routePageStack({ specDesign, specStack });
