import { FaQuoteLeft, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useState } from "react";

export default function CustomerReviewsBlock({ data }) {
  const [startIndex, setStartIndex] = useState(0);
  const testimonialsPerPage = 2;
  const totalPages = Math.ceil(data.items.length / testimonialsPerPage);

  const handleNext = () => {
    const newIndex = startIndex + testimonialsPerPage;
    setStartIndex(newIndex >= data.items.length ? 0 : newIndex);
  };

  const handlePrevious = () => {
    const newIndex = startIndex - testimonialsPerPage;
    setStartIndex(newIndex < 0 ? data.items.length - testimonialsPerPage : newIndex);
  };

  return (
    <div className="px-4 pb-40 pt-40 text-black">
      <div className="container-width">
        {/* Section description */}
        <div className="text-center">
          <h3 className="pb-2 text-xl font-brand-secondary">{data.title}</h3>
          <h3 className="pb-2 text-3xl md:text-4xl font-brand-secondary text-brand-slate font-bold">{data.subtitle}</h3>
          <p className="pb-4 text-lg font-brand-secondary">{data.content}</p>
        </div>
        {/* Testimonials */}
        <div className="mt-4">
          <div className={`grid gap-6 ${totalPages > 1 ? 'lg:grid-cols-2' : ''}`}>
            {data.items.slice(startIndex, startIndex + testimonialsPerPage).map((item, index) => (
              <div key={index} className="review-item p-4 border-brand-secondary border bg-white">
                <div className="review-title text-lg md:text-xl font-semibold flex items-center leading-tight font-brand-secondary">
                  <FaQuoteLeft className="text-xl mr-2.5 ml-7 w-7 text-brand-secondary" />
                  <h3 className="text-brand-slate">{item.title}</h3>
                </div>
                <div className="mt-4 md:text-lg font-brand-secondary">{item.content}</div>
              </div>
            ))}
          </div>
          {totalPages > 1 && (
            <div className="flex justify-center gap-4 text-xl mt-4">
              <button
                className={`text-brand-secondary hover:underline ${startIndex === 0 ? 'text-gray-300' : ''}`}
                onClick={handlePrevious}
                disabled={startIndex === 0}
              >
                <FaArrowLeft />
              </button>
              <button
                className={`text-brand-secondary text-xl hover:underline ${startIndex >= data.items.length - testimonialsPerPage ? 'text-gray-300' : ''}`}
                onClick={handleNext}
                disabled={startIndex >= data.items.length - testimonialsPerPage}
              >
                <FaArrowRight />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
