import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
// import Select from 'react-select';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import Select from 'react-select';
import Model from '../../libs/ModelClass';
import { getJson } from '../../libs/utils';


export const CountriesModel = Model.extend('i18nCountries');

export const extractCountryDetails = (countryDetail) => {
  return {
    countryId: countryDetail.data.countryId, // for population
    code: countryDetail.data.currency, // for display
    symbol: countryDetail.data.currency_symbol, // for display
    iso2: countryDetail.data.iso2, // for flag
  };
};

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 9, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

export const RawInputCountryDetails = ({
  value, onChange
}) => {
  const selectCountry = (doc) => {
    if (doc) {
      let details = extractCountryDetails(doc);
      onChange(details);
    } else {
      onChange(null);
    }
  };

  return (<>
    {/* Country Select */}
    <CountrySelector {...{
      value: value?.countryId,
      onChange: selectCountry
    }} />
  </>);
}

const CurrencyInput = ({
  inputMode = 'numeric',
  maskOptions = {},
  ...inputProps
}) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });
  const autoSelect = (e) => {
    return e.target.select();
  };
  return <MaskedInput disabled={!_.size(maskOptions)} onFocus={autoSelect} mask={currencyMask} {...inputProps} />
}

// CurrencyInput.defaultProps = {
//   inputMode: 'numeric',
//   maskOptions: {},
// }

// CurrencyInput.propTypes = {
//   inputmode: PropTypes.string,
//   maskOptions: PropTypes.shape({
//     prefix: PropTypes.string,
//     suffix: PropTypes.string,
//     includeThousandsSeparator: PropTypes.boolean,
//     thousandsSeparatorSymbol: PropTypes.string,
//     allowDecimal: PropTypes.boolean,
//     decimalSymbol: PropTypes.string,
//     decimalLimit: PropTypes.string,
//     requireDecimal: PropTypes.boolean,
//     allowNegative: PropTypes.boolean,
//     allowLeadingZeroes: PropTypes.boolean,
//     integerLimit: PropTypes.number,
//   }),
// }

const CountrySelector = (props) => {
  const {
    value,
    onChange
  } = props;
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [countrySelected, setCountrySelected] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchAndPopulate();
  }, [value]);

  const fetchAndPopulate = async () => {
    setIsLoading(true);
    const countriesOptions = await fetchCountries();
    if (value && !countrySelected) {
      // populate
      const optionSelected = countriesOptions.find(option => option.value === value);
      selectCountry(optionSelected);
    }
    // clear
    if (!value) {
      setCountrySelected(null);
    }
    setIsLoading(false);
  };

  const fetchCountries = async () => {
    if (countriesOptions?.length > 0) {
      return countriesOptions;
    }
    let countriesDoc = await getJson('/countries.json');
    countriesDoc = countriesDoc.map(json => new CountriesModel(json));
    let options = countriesDoc.map(doc => {
      return {
        value: doc.data.countryId,
        label: doc.data.name,
        doc
      };
    });
    setCountriesOptions(options);
    return options;
  };

  const selectCountry = (option) => {
    setCountrySelected(option);
    onChange(option?.doc || null);
  };

  return (
    <Select
      className="w-full shadow"
      isClearable
      isMulti={false}
      options={countriesOptions}
      placeholder="País"
      isDisabled={isLoading}
      isLoading={isLoading}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: 'lightgray',
          primary: 'darkgray',
        },
      })}
      value={countrySelected}
      onChange={option => selectCountry(option)}
    />
  );
};

// Función para procesar el valor sin formato según las opciones de máscara
function processRawValue(value, maskOptions) {
  const {
    includeThousandsSeparator,
    thousandsSeparatorSymbol,
    allowDecimal,
    decimalSymbol,
    requireDecimal
  } = maskOptions;

  let rawValue = value;

  if (includeThousandsSeparator) {
    const regex = new RegExp(`\\${thousandsSeparatorSymbol}`, 'g');
    rawValue = rawValue.replace(regex, '');
  }

  if (allowDecimal) {
    const regex = new RegExp(`\\${decimalSymbol}`, 'g');
    rawValue = rawValue.replace(regex, '.');

    if (requireDecimal && rawValue.indexOf('.') === -1) {
      // Agregar decimal si es necesario y no está presente
      rawValue += '.00';
    }
  }

  return parseFloat(rawValue);
}

const RawInputCurrency = (props) => {
  const {
    value,
    onChange
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const countryDetail = props?.country || value?.country;

  const calcMaskOptions = () => {
    if (countryDetail?.countryId && countryDetail?.code) {
      let userLang = navigator?.language || navigator?.userLanguage;
      let lang = userLang?.split('-')[0];
      const options = { style: 'currency', currency: countryDetail.code };
      const numberFormat = new Intl.NumberFormat(lang+'-'+countryDetail.iso2, options);
      const parts = numberFormat.formatToParts(1234.5);
      let thousands = _.find(parts, ({ type }) => type === 'group');
      thousands = thousands?.value;
      let decimal = _.find(parts, ({ type }) => type === 'decimal');
      decimal = decimal?.value;
      let fraction = _.find(parts, ({ type }) => type === 'fraction');
      fraction = fraction?.value ? parseInt(fraction?.value) : false;

      return {
        includeThousandsSeparator: !!thousands,
        thousandsSeparatorSymbol: thousands,
        allowDecimal: !!decimal,
        decimalSymbol: decimal,
        requireDecimal: !!fraction
      };
    }
  };

  const calcMaskOptionsMemo = useMemo(() => calcMaskOptions(), [countryDetail?.countryId]);

  const selectAmountQty = (amountQty) => {
    const rawQty = processRawValue(amountQty, calcMaskOptionsMemo);
    onChange({
      ...value,
      qty: amountQty, // masked value
      rawQty
    });
  };

  const selectCountry = (newCountryDetail) => {
    if (props?.country) {
      console.log('skip selected country, using taxonomyType default country');
      return;
    }
    if (newCountryDetail?.data) {
      // reset
      if (newCountryDetail?.data?.countryId !== countryDetail?.countryId) {
        onChange({
          qty: null,
          rawQty: 0,
          country: extractCountryDetails(newCountryDetail)
        });
      }
      setIsOpen(false);
    } else {
      onChange({ ...value, country: null });
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!_.size(value)) {
      onChange({
        qty: null,
        rawQty: 0,
        country: countryDetail || null
      });
    }
  }, [value]);

  return (
    <div className="flex flex-row gap-0">
      {/* {currencyInputMemo} */}
      <CurrencyInput {...{
          value: value?.qty || 0,
          onChange: (e) => { selectAmountQty(e.target.value); }
        }}
        maskOptions={calcMaskOptionsMemo}
        className="rounded-l px-1 py-1 h-[32px] border border-gray-200"
      />

      {/* Currency selector */}
      <div className="relative inline-flex text-left place-content-stretch h-[32px] border border-gray-300 rounded-r">
        <button
        onClick={toggleDropdown}
        className="react-tel-input rounded-r inline-flex items-center gap-1 text-sm px-3 py-1 text-center text-gray-800 bg-gray-200"
        type="button">
          <div className="">{countryDetail?.symbol}</div>
          <div className="">{countryDetail?.code}</div>
          <div className={`flag -mt-1 ${countryDetail?.iso2?.toLowerCase()}`}></div>
          <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
          </svg>
        </button>
        <div className={`z-10 absolute right-0 mt-10 bg-white divide-y rounded-lg shadow w-44 ${isOpen ? 'block' : 'hidden'}`}>
          {/* Country Select */}
          <CountrySelector {...{
            value: countryDetail?.countryId,
            onChange: selectCountry
          }} />
        </div>
      </div>
    </div>
  );
};

export default RawInputCurrency;