import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';


export function AnimationAddClassWhenVisible({ children, classToAdd, classDefault }) {
  const [isVisible, setIsVisible] = useState(false);

  const onSensor = (isVisible) => {
    if (isVisible) {
      setIsVisible(true);
    }
  };

  // Verificando si children es un único elemento React
  const child = React.Children.only(children);

  // Agregar la clase classToAdd si el elemento es visible
  const className = isVisible ? `${classDefault} ${child.props.className} ${classToAdd}` : `opacity-0 ${classDefault} ${child.props.className}`;

  return (
    <VisibilitySensor onChange={onSensor} partialVisibility>
      {React.cloneElement(child, { className })}
    </VisibilitySensor>
  );
}
