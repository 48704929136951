import _ from 'lodash';

export const required = (value) => (value ? undefined : "Requerido");

export const FieldLabel = ({
  name,
  input,
  meta,
  fieldsRequired,
  title,
  showLabel = true,
  showClearBtn = true,
  classes,
  Toolbar
}) => {
  const hasError = (meta.touched || input.value || _.includes(fieldsRequired, name)) && meta.error;

  return (
    showLabel && title ? (
      <div className={classes?.fieldLabel}>
        <span className={`mr-3 ${ hasError ? 'text-red-600' : '' }`}>
          {title}
          {hasError ? '*' : ''}
        </span>

        {Toolbar && <Toolbar />}

        {showClearBtn && input?.value ? (
          <button
            type="button"
            className="px-1.5 py-0.5 font-regular text-xs bg-gray-100 text-gray-400 rounded-full hover:text-gray-800 inline-block"
            onClick={() => input?.onChange(undefined)}
          >
            X
          </button>
        ) : ''}
      </div>
    ) : null
  );
}

export const RequiredByFieldsList = ({ input, name, fieldsRequired }) => {
  if (!fieldsRequired) return '';
  if (_.includes(fieldsRequired, name) && !input.value) {
    return (
      <div className="pt-0 text-sm text-red-600">*Requerido.</div>
    )
  }
  return ''
}

export const ShowFieldHelp = ({ input, meta, name, fieldsRequired, helpText, ...props }) => {
  return (
    <>
      {helpText ? (
        <div className="mt-2 text-gray-600 text-sm text-left">{helpText}</div>
      ) : null}
      {(input?.value || meta.touched) && meta.error !== ' ' ? (
        <div className="text-red-600" style={{fontSize: 12}}>{meta.error}</div>
      ) : ''}
      {/* <RequiredByFieldsList name={name} input={input} fieldsRequired={fieldsRequired} /> */}
      {props?.taxonomyType?.help !== ' ' ? (
        <div className="text-gray-600" style={{fontSize: 12}}>{props?.taxonomyType?.help}</div>
      ) : ''}
    </>
  )
}