import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import { IonIcon } from "@ionic/react";
import { memo, useMemo } from 'react';


const PaginationRaw = (props) => {
  let {
    currentPage,
    totalPages,
    setActive,
    classes = {}
  } = props;

  totalPages = Math.ceil(totalPages);

  const getItemProps = (index) => ({
    className: `
      transitions-all duration-200 ease-in-out relative px-2.5 py-1 rounded-lg md:px-4 md:py-2 md:rounded-lg
      ${currentPage === index ? "bg-gray-800 shadow-lg text-white" : "bg-gray-200 text-black"}
    `,
    onClick: () => setActive(index),
  });

  const next = () => {
    if (currentPage < totalPages) {
      setActive(currentPage + 1);
    }
  };

  const prev = () => {
    if (currentPage > 1) {
      setActive(currentPage - 1);
    }
  };

  const getVisiblePages = useMemo(() => {
    let pages = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <button {...getItemProps(i)} key={i}>{i}</button>
        );
      }
    } else {
      if (currentPage <= 3) {
        for (let i = 1; i <= 4; i++) {
          pages.push(
            <button {...getItemProps(i)} key={i}>{i}</button>
          );
        }
        pages.push(<span key="ellipsis1" className="px-4 py-2">...</span>);
        pages.push(
          <button {...getItemProps(totalPages)} key={totalPages}>{totalPages}</button>
        );
      } else if (currentPage >= totalPages - 2) {
        pages.push(
          <button {...getItemProps(1)} key={1}>{1}</button>
        );
        pages.push(<span key="ellipsis1" className="px-4 py-2">...</span>);
        for (let i = totalPages - 3; i <= totalPages; i++) {
          pages.push(
            <button {...getItemProps(i)} key={i}>{i}</button>
          );
        }
      } else {
        pages.push(
          <button {...getItemProps(1)} key={1}>{1}</button>
        );
        pages.push(<span key="ellipsis1" className="px-4 py-2">...</span>);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pages.push(
            <button {...getItemProps(i)} key={i}>{i}</button>
          );
        }
        pages.push(<span key="ellipsis2" className="px-4 py-2">...</span>);
        pages.push(
          <button {...getItemProps(totalPages)} key={totalPages}>{totalPages}</button>
        );
      }
    }
    return pages;
  }, [currentPage, totalPages]);

  return (
    <div className={`flex flex-row place-content-center items-center gap-4 text-xs md:text-md ${classes.paginationContainer}`}>
      <button
        className={`flex items-center ${
          currentPage === 1 ? 'text-gray-200' : ''
        }`}
        onClick={prev}
        disabled={currentPage === 1}
      >
        <IonIcon icon={chevronBackOutline} className={`text-sm md:text-xl`} /> 
        <span className="hidden md:inline ml-2">Anterior</span>
      </button>
      <div className="flex items-center gap-2">
        {getVisiblePages}
      </div>
      <button
        className={`flex items-center ${
          currentPage === totalPages ? 'text-gray-200' : ''
        }`}
        onClick={next}
        disabled={currentPage === totalPages}
      >
        <span className="hidden md:inline mr-2">Siguiente</span>
        <IonIcon icon={chevronForwardOutline} className={`text-sm md:text-xl`} />
      </button>
    </div>
  );
};

export const Pagination = memo(PaginationRaw, (prevProps, nextProps) => {
  return (
    prevProps.currentPage === nextProps.currentPage &&
    prevProps.totalPages === nextProps.totalPages &&
    prevProps.setActive === nextProps.setActive
  );
});
