import { getConfig } from "../../config";


const getContainerClasses = (classes = {}) => {
  let className = '';
  // style
  if (!classes.logoStyle || classes.logoStyle === 'transparent') {
    // nothing
  } 
  else if (classes.logoStyle === 'square') {
    className += 'shadow-lg';
  }
  else if (classes.logoStyle === 'square-rounded-sm') {
    className += 'border-4 border-brand-primary shadow-lg rounded-lg';
  }
  else if (classes.logoStyle === 'square-rounded-md') {
    className += 'border-4 border-brand-primary shadow-lg rounded-2xl';
  }
  else if (classes.logoStyle === 'square-rounded-lg') {
    className += 'border-4 border-brand-primary shadow-lg rounded-3xl';
  }
  else if (classes.logoStyle === 'circle') {
    className += 'border-4 border-brand-primary shadow-lg rounded-full';
  }
  
  className += ' ';

  // size
  if (classes.logoSize === 'xxxs') {
    className += 'h-3 lg:h-4';
  }
  else if (classes.logoSize === 'xxs') {
    className += 'h-4 lg:h-6';
  }
  else if (classes.logoSize === 'xs') {
    className += 'h-5 lg:h-6';
  }
  else if (classes.logoSize === 'sm') {
    className += 'h-6 lg:h-7';
  }
  else if (classes.logoSize === 'md' || !classes.logoSize) {
    className += 'h-10 lg:h-12';
  }
  else if (classes.logoSize === 'lg') {
    className += 'h-12 lg:h-14';
  }
  else if (classes.logoSize === 'xl') {
    className += 'h-16 lg:h-18';
  }
  else if (classes.logoSize === '2xl') {
    className += 'h-20 lg:h-24';
  }
  else if (classes.logoSize === '3xl') {
    className += 'h-24 lg:h-28';
  }  
  
  return className;
}

export function LayoutLogoDark ({ classes }) {
  const config = getConfig();
  return (
    <div className={`mx-auto flex flex-row items-center pt-1 ${classes?.logoContainer}`}>
      <img src={classes.logoDark} alt={config?.siteName} className={`${classes?.logoIcon} ${getContainerClasses(classes)}`} />
    </div>
  );
}

export function LayoutLogo (props) {
  if (props.design === 'light') {
    return (
      <LayoutLogoLight {...props} />
    );
  }
  return (
    <LayoutLogoDark {...props} />
  );
}

export function LayoutLogoLight ({ classes }) {
  const config = getConfig();
  return (
    <div className={`mx-auto flex flex-row items-center pt-1 ${classes?.logoContainer}`}>
      <img src={classes.logoLight} alt={config?.siteName} className={`${classes?.logoIcon} ${getContainerClasses(classes)}`} />
    </div>
  );
}