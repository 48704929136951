import { env } from "../config";
import specTheme from "./specTheme";

/////////////////////////////////////////
// Site identity
/////////////////////////////////////////

export const siteIdentity = {
  projectSlug: 'queens-fit-wear',
  domain: env === 'prod' ? 'queensfitwear.com.py' : 'queens-fit-wear.web.app',
  protocol: 'https',
  siteName: 'Queens Fit Wear',
  siteMoto: 'Tienda de ropa para mujeres',
  email: 'queensfitwear0@gmail.com',
  number: '595985506001',
  numberString: '+595 (985) 506 001',
  addressString: '',
  defaultLocationGPS: {
    "lat": -25.330336859118233,
    "lng": -57.508862292076586
  },
  locationLink: 'https://maps.app.goo.gl/W2ixgoq2zadPxKtg8',
  instagram: 'https://www.instagram.com/queensfitwear/reels/',
  facebook: 'https://www.facebook.com/people/Queens-Fit-Wear/100066997566879/',
  currency: 'PYG',
  phoneCountry: 'py',
  // retrocompatible
  imgFolder: 'productsImages',
  modules: {
    panel: {
      mainPath: '/a/entity'
    }
  }
};

/////////////////////////////////////////
// MAIN PARTS
/////////////////////////////////////////

export const specMainParts = {
  logo: {
    logoDark: '/logo/logo-texto-horizontal-blanco-sm.png',
    logoLight: '/logo/logo-texto-horizontal-negro-sm.png'
  },
  header: {
    logoStyle: 'transparent', // 'square', 'square-rounded-sm', 'square-rounded-md', 'square-rounded-lg', 'circle', 'transparent'
    logoSize: 'xxs' // 'xxs', 'xs', 'sm', 'md', 'lg', 'xl'
  },
  mainMenu: {
    visibility: 'visible', // 'hidden', 'auto'
    logoStyle: 'transparent', // 'square', 'square-rounded-sm', 'square-rounded-md', 'square-rounded-lg', 'circle', 'transparent'
    logoSize: 'xs', // 'xxxs', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'
    bgStyle: 'solid',
    bgColor: 'brand-primary'
  }
};

/////////////////////////////////////////
// Default strings for layout blocks
/////////////////////////////////////////

export const stringsLayout = {
  headerMini: {
    // title: "Queens Fit Wear",
    subtitle: "Descubre nuestra\ncolección de\nropa fitness",
    imageSrc: '/images/header-1.jpg'
  },
  contactCta: {
    title: "Visítanos en Nuestro Local",
    content: "Si tienes alguna consulta o necesitas más información, no dudes en contactarnos.",
    address:"Alberto N. Schenoni c/ Silvano Mosqueira 2160",
    phoneNumber: "(0985) 506 001",
    items: [
      {
        imageSrc: "/images/local-afuera-2.jpg",
        title: "Local Afuera"
      },
      {
        imageSrc: "/images/local-adentro.jpg",
        title: "Local Adentro 1"
       },
       {
        imageSrc: "/images/local-adentro-3.jpg",
        title: "Local Adentro 2"
       },
    ]
  },
  footer: {
    title: "¡Encuentra tu estilo fitness!",
    urlDest: '/',
    backgroundImageUrl: '/images/bg-textura-2.jpg',
    iconUrl: '/logo/logo-icono-dorado.png',
    address:"Alberto N. Schenoni c/ Silvano Mosqueira",
    phoneNumber: "0985 506001"
  }
};

export const specDesign = {
  /////////////////////////////////////////
  // SITE IDENTITY
  /////////////////////////////////////////
  siteIdentity: {
    ...siteIdentity,
    // pageTitle: 'custom page title'
  },
  
  /////////////////////////////////////////
  // THEME
  /////////////////////////////////////////
  fonts: {
    ...specTheme.fonts
  },
  colors: {
    ...specTheme.colors
  },
  
  /////////////////////////////////////////
  // MAIN PARTS
  /////////////////////////////////////////
  ...specMainParts
};