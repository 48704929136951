import React, { useState } from 'react';
import {
  ellipsisVertical
} from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

const ActionsDropdown = (props) => {
  let { 
    classes,
    children
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`relative inline-block text-left ${classes?.actionsContainer}`}>
      <button
      onClick={toggleDropdown}
      className="text-sm px-3 py-2 text-center text-stone-700 bg-stone-100 hover:bg-stone-200 font-medium rounded inline-flex items-center"
      type="button">
        <IonIcon icon={ellipsisVertical} size="medium"></IonIcon>
      </button>
      {isOpen && (
        <div className={`z-10 absolute right-0 mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 ${classes?.actionsPopover}`} >
          {children}
        </div>
      )}
    </div>
  );
};

export default ActionsDropdown;
