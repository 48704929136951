import { useEffect, useState, useRef, useMemo } from "react";
import { Link } from 'react-router-dom';
import { useAuth } from "../../modules/user/AuthContext";
import { animateScroll as scroll } from 'react-scroll';
import { FaBars } from 'react-icons/fa';
import { LayoutLogo } from "../../modules/panel/LayoutLogo";
import { useScrollUtils } from "../../modules/panel/useScrollUtils";
import { useLayoutHome } from "../../modules/layoutHome/useLayoutHome";
import { stackClasses } from "../../libs/utils";
import config from "../../config";


export const MenuHome = (props) => {
  let {
    HeaderLeft,
    HeaderMenu,
    fullMenuHeader,
    classes = {}
  } = props;
  const menuRef = useRef();
  const { scrollPosition, isNavbarOpen, isScrollUnderOffset } = useScrollUtils();
  const { user } = useAuth();
  const [menuHeight, setMenuHeight] = useState();
  const [navbar, setNavbar] = useState(false)
  const { layoutDesign } = useLayoutHome();

  const showFullHeader = useMemo(() => {
    return layoutDesign?.mainMenu.visibility === 'visible' || fullMenuHeader || isNavbarOpen || isScrollUnderOffset
  }, [scrollPosition]);

  useEffect(() => {
    const handleResize = () => {
      setMenuHeight(menuRef.current.getBoundingClientRect().height);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  // bg style and color
  const design = layoutDesign?.mainMenu;
  let bgClass = '';
  if (design.bgStyle === 'solid') {
    bgClass += ' bg-' + design.bgColor;
  }
  else if (design.bgStyle === 'gradient-br') {
    bgClass += ` bg-gradient-to-br from-${design.bgColors[0]} to-${design.bgColors[1]}`;
  }
  
  return (
    <header id="menu-header" 
      ref={menuRef} 
      className={`
        fixed w-full top-0 font-brand-secondary shadow-sm z-50 overflow-hidden 
        ${showFullHeader ? 'transition-opacity-full' : 'transition-opacity-none'}
        ${bgClass}
      `}
    >

      <div className={`flex justify-between place-items-center px-4 lg:px-10 py-4 w-full`}>
        {/* logos  */}
        <div className="">
          <Link
            to="/"
            title={layoutDesign?.siteName}
            onClick={() => scroll.scrollToTop()}
            className="flex flex-row items-center"
          >
            <LayoutLogo classes={stackClasses(classes, layoutDesign?.logo, layoutDesign?.mainMenu)} />
          </Link>
        </div>

        {/* HeaderLeft */}
        {HeaderLeft ? (
          <HeaderLeft menuHeight={menuHeight}></HeaderLeft>
        ) : ''}

        {/* menu desktop */}
        {HeaderMenu ? (
          <div className={`text-xs hidden sm:block`}>
            <HeaderMenu menuHeight={menuHeight}></HeaderMenu>
            {user?.userDoc?.data?.name ? (
              <Link
              className="bg-zinc-200 sm:hidden ml-2 hover:bg-zinc-300 rounded py-1.5 px-1.5 text-black text-sm"
              to={config.modules.panel.mainPath}>
                Admin
              </Link>
            ) : ''}
          </div>
        ) : ''}

        {/* menu movil hamburger */}
        {HeaderMenu ? (
          <div className="sm:hidden justify-self-end">
            <button
              className={`p-2 outline-none ${navbar ? 'text-brand-secondary' : 'text-stone-300'}`}
              onClick={() => setNavbar(!navbar)}
            >
              <FaBars size={21} />
            </button>
          </div>
        ) : null}
      </div>

      {/* menu movil expanded */}
      <div className={`sm:block md:hidden bg-transparent
        ${navbar ? "block" : "hidden"}
      `}>
        <div className="">
          {HeaderMenu ? (
            <div className="text-xs flex flex-col space-y-3 sm:hidden py-4 mb-2 border-t w-full border-gray-400">
              <HeaderMenu menuHeight={menuHeight}></HeaderMenu>
              {user?.userDoc?.data?.name ? (
                <Link
                className="bg-zinc-200 sm:hidden hover:bg-zinc-300 mx-9 rounded py-1.5 px-1.5 text-black text-sm"
                to={config.modules.panel.mainPath}>
                  Admin
                </Link>
              ) : ''}
            </div>
          ) : ''}
        </div>
      </div>

    </header>
  );
};