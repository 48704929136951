import { useState } from "react";
import Resizer from "react-image-file-resizer";
import slugify from 'slugify';
import { getConfig } from "../config";
import _ from "lodash";


export const scrollTo = (id, moreOffsetPosition=0) => {
  const menuElement = document.getElementById('menu');
  if (menuElement) {
    // calc offset
    const headerOffset = 15 + menuElement.getBoundingClientRect().height;
    const element = document.getElementById(id);
    const elementPosition = element ? element.getBoundingClientRect().top : 0;
    const offsetPosition = elementPosition + window.scroolY - headerOffset - moreOffsetPosition;
  
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
      duration: 1000,
    });
  }
}


export const useScrollTo = () => {
  const [ scrollOnceList, setScrollOnceList ] = useState([]);

  const scrollOnceTo = (elementId, offsetPosition=0) => {
    if (!scrollOnceList[elementId]) {
      setTimeout(() => { 
        scrollTo(elementId, offsetPosition); 
      }, 400);
      setScrollOnceList({ 
        ...scrollOnceList, 
        [elementId]: true
      });
    }
  }

  const scrollToDelay = (elementId, offsetPosition=0, delay=400) => {
    setTimeout(() => { 
      scrollTo(elementId, offsetPosition); 
    }, delay);
  }
  
  return { 
    scrollOnceTo,
    scrollToDelay
  };
}


export const priceFormat = num => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' Gs';
}

export const numberFormat = (number, decimals = 2) => {
  // return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parseInt(number)?.toLocaleString('es-ES', {
    style: 'decimal',
    minimumIntegerDigits: 1,
    maximumFractionDigits: 3,
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals
  });
};


const WhatsAppURL = 'https://wa.me';

export const openWhatsApp = ({ number, message }, justReturn) => {
  const config = getConfig();

  number = number || config?.number;
  number = number.replace(/[^\w\s]/gi, '').replace(/ /g, '');

  let url = `${WhatsAppURL}/${number}`;

  if (message) {
    url += `?text=${encodeURI(message)}`;
  }

  if (justReturn) {
    return url;
  }
  
  window.open(url);
}


export const resizeFile = ({ file, width, height, format }) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, width, height, format, 100, 0, 
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
}


export const getImageURL = (fullImageURL, size, folder) => {
  const config = getConfig();
  if (!fullImageURL) { 
    return null;
  }
  const [ hash, format ] = fullImageURL?.split('.');
  return config.getImgPrefix(`${hash}-${size}.${format}`, folder);
};


export const sendToLast = (list) => {
  if (list.length <= 1) { return list; }
  let newList = [ ...list ];
  newList.push(newList.shift())
  return newList;
}


export const slug = (text='') => {
  return slugify(text, { replacement: '_', lower: true, trim: true });
}

const nestedAttrSeparator = '__';

// Convertir los pares attr/val en un objeto
export function parseAttrValParams(attrValParams = '', separator = "/") {
  const attrValPairs = attrValParams.split(separator);
  const attrValObject = {};
  for (let i = 0; i < attrValPairs.length; i += 2) {
    let attr = attrValPairs[i];
    let val = attrValPairs[i + 1];
    if (val == parseInt(val, 10)) {
      val = parseInt(val, 10);
    }
    if (_.includes(val, nestedAttrSeparator)) {
      val = parseAttrValParams(val, nestedAttrSeparator);
    }
    attrValObject[attr] = val;
  }
  if (_.isEqual(attrValObject, {'': undefined})) {
    return {}; 
  }
  return attrValObject;
}

// convertir un objeto en pares attr/val en un string
export function stringifyAttrValParams(attrValObject, separator = "/") {
  const attrValPairs = [];
  for (let [attr, val] of Object.entries(attrValObject)) {
    if (!_.isArray(val) && _.isObject(val)) {
      val = stringifyAttrValParams(val, nestedAttrSeparator);
    }
    if (!_.isEmpty(val) || _.size(val) || (_.isBoolean(val) && val) || _.isString(val) || _.isNumber(val)) {
      attrValPairs.push(attr, val);
    }
  }
  return attrValPairs.join(separator);
}

/**
 * Combina los valores de cada objeto en un nuevo objeto, concatenando los valores correspondientes.
 * @param {...Object} layers - Lista variable de objetos que contienen los valores a combinar.
 * @returns {Array} - Un nuevo objeto que contiene los valores concatenados.
 *
 * @example
 * const layers = [
 *   { fieldContainer: 'text-xl', fieldLabel: 'label1' },
 *   { fieldContainer: 'p-2' },
 *   { fieldContainer: 'text-gray-200', fieldLabel: 'label2' }
 * ];
 *
 * const result = stackClasses(...layers);
 * console.log(result);
 * // Salida: { fieldContainer: 'text-xl p-2 text-gray-200', fieldLabel: 'label2' }
 */
export function stackClasses(...layers) {
  let result = {};

  for (const layer of layers) {
    for (const key in layer) {
      if (key in result) {
        result[key] += ` ${layer[key]}`;
      } else {
        result[key] = layer[key];
      }
    }
  }

  return result;
}


export const downloadJSON = (data, filename) => {
  const jsonData = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonData], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
  URL.revokeObjectURL(url);
};


export const replaceUndefinedAndNaNWithNull = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    // Si el valor no es un objeto o es nulo, simplemente devolvemos el valor.
    return obj;
  }

  // Creamos un nuevo objeto o array según el tipo de dato del objeto actual.
  const result = Array.isArray(obj) ? [] : {};

  // Recorremos las propiedades del objeto.
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      // Obtenemos el valor actual de la propiedad.
      const value = obj[key];

      // Si el valor es undefined o NaN, lo reemplazamos por null.
      if (value === undefined || Number.isNaN(value)) {
        result[key] = null;
      } else {
        // Si el valor no es undefined o NaN, realizamos una llamada recursiva para tratar sus hijos.
        result[key] = replaceUndefinedAndNaNWithNull(value);
      }
    }
  }

  return result;
}


export const calculateDistance = (latLng1, latLng2) => {
  // Convert latitude and longitude to radians
  const lat1Rad = latLng1.lat * (Math.PI / 180);
  const lng1Rad = latLng1.lng * (Math.PI / 180);
  const lat2Rad = latLng2.lat * (Math.PI / 180);
  const lng2Rad = latLng2.lng * (Math.PI / 180);

  // Earth's radius in meters
  const earthRadius = 6371000;

  // Haversine formula to calculate distance between two points on a sphere
  const dlat = lat2Rad - lat1Rad;
  const dlng = lng2Rad - lng1Rad;
  const a = Math.sin(dlat / 2) * Math.sin(dlat / 2) + Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dlng / 2) * Math.sin(dlng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Calculate distance
  const distance = earthRadius * c;

  return distance;
};


export const calculateArea = (points) => {
  if (!Array.isArray(points) || points.length < 3) {
    return 0;
  }

  const radiusOfEarthInMeters = 6371000; // Radio de la tierra en metros
  let area = 0;
  const n = points.length;

  for (let i = 0; i < n; i++) {
    const { lat: lat1, lng: lng1 } = points[i];
    const { lat: lat2, lng: lng2 } = points[(i + 1) % n];

    const x1 = lng1 * Math.cos((lat1 * Math.PI) / 180) * radiusOfEarthInMeters;
    const y1 = lat1 * radiusOfEarthInMeters;
    const x2 = lng2 * Math.cos((lat2 * Math.PI) / 180) * radiusOfEarthInMeters;
    const y2 = lat2 * radiusOfEarthInMeters;

    area += x1 * y2 - x2 * y1;
  }

  return Math.abs(area) / 2;
};


export const calculateCenter = (polygonPaths) => {
  let latSum = 0;
  let lngSum = 0;
  const numPoints = polygonPaths.length;

  for (const point of polygonPaths) {
    latSum += point.lat;
    lngSum += point.lng;
  }

  const centerLat = latSum / numPoints;
  const centerLng = lngSum / numPoints;

  return { lat: centerLat, lng: centerLng };
};


export const calculateTopCenter = (polygonPaths) => {
  // Encontrar los puntos con las latitudes más altas
  const highestLatitudes = polygonPaths.reduce((acc, curr) => {
    if (!acc.length || curr.lat > acc[0].lat) return [curr];
    if (curr.lat === acc[0].lat) return [...acc, curr];
    return acc;
  }, []);

  // Calcular el promedio de las coordenadas de los puntos con las latitudes más altas
  const numPoints = highestLatitudes.length;
  const latSum = highestLatitudes.reduce((sum, point) => sum + point.lat, 0);
  const lngSum = highestLatitudes.reduce((sum, point) => sum + point.lng, 0);

  const centerLat = latSum / numPoints;
  const centerLng = lngSum / numPoints;

  return { lat: centerLat, lng: centerLng };
};


export const parseCoordinates = (layer) => {
  return layer?.coordinates?.split(' ').map((pointStr) => {
    const [lng, lat] = pointStr.split(',');
    return { lat: parseFloat(lat), lng: parseFloat(lng) };
  });
};


export const NumberToLetter = (props) => {
  const { value, className } = props;

  // Validar que el valor sea un número entre 1 y 26
  if (typeof value !== 'number' || value < 1 || value > 26) {
    return <span>Valor no válido</span>;
  }

  // Calcular la letra correspondiente
  const letter = String.fromCharCode(64 + value);

  return <span className={className}>{letter}</span>;
}


export const chunkArray = (array, chunkSize) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}


export const getJson = (url) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);

    xhr.onload = () => {
      if (xhr.status === 200) {
        try {
          const data = JSON.parse(xhr.responseText);
          resolve(data);
        } catch (error) {
          reject(new Error("Failed to parse JSON: " + error.message));
        }
      } else {
        reject(new Error(`HTTP error ${xhr.status}: ${xhr.statusText}`));
      }
    };

    xhr.onerror = () => {
      reject(new Error("Network error"));
    };

    xhr.send();
  });
}


export const assignDeep = (target, ...sources) => {
  if (!_.isObject(target)) {
    throw new TypeError('Target must be an object');
  }

  sources.forEach(source => {
    if (!_.isObject(source)) {
      return; // Skip non-object sources
    }

    Object.keys(source).forEach(key => {
      if (_.isObject(source[key]) && _.isObject(target[key])) {
        // Recursively assign if both the target and source values are objects
        target[key] = assignDeep(target[key], source[key]);
      } else {
        // Otherwise, assign the value directly
        target[key] = source[key];
      }
    });
  });

  return target;
}

export const deepEqual = (obj1, obj2)  => {
  // Verificar si ambos parámetros son objetos
  if (typeof obj1 === 'object' && obj1 !== null &&
      typeof obj2 === 'object' && obj2 !== null) {
    // Obtener las claves de ambos objetos
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    // Verificar si la cantidad de claves es la misma
    if (keys1.length === keys2.length) {
      // Recorrer las claves y comparar recursivamente los valores
      for (let key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }
      // Si todas las claves y valores coinciden, los objetos son iguales
      return true;
    }
  }
  // Si no son objetos o tienen diferentes cantidades de claves, no son iguales
  return obj1 === obj2;
}

export const findAllUsingIn = async (Model, field, inList) => {
  if (inList?.length >= 30) {
    let results = [];
    const listChunks = _.chunk(inList, 30);
    for (const list of listChunks) {
      const docs = await Model.filterByAttributes({ [field]: { in: list } });
      results = [ ...results, ...docs ];
    }
    return results;
  } 
  else {
    return await Model.filterByAttributes({ [field]: { in: inList } });
  }
};

/**
   * Sorts an array of documents based on a specified field and field type.
   * Supports sorting in ascending (ASC) or descending (DESC) order.
   * @param {Array} docs - The array of documents to be sorted.
   * @param {string} fieldName - The name of the field to sort by. Append " DESC" or " ASC" for descending or ascending order, respectively.
   * @param {string} fieldType - The type of the field (e.g., "number", "string", "date").
   * @returns {Array} - The sorted array of documents.
   */
export const sortDocsByField = (docs, fieldName, fieldType = 'number') => {
  const sortOrder = fieldName.endsWith(' DESC') ? 'DESC' : 'ASC';
  const actualFieldName = fieldName.replace(/( DESC| ASC)$/, '');

  return docs && docs.sort((a, b) => {
    const valueA = a?.data[actualFieldName];
    const valueB = b?.data[actualFieldName];
    // number
    if (fieldType === 'number') {
      const sortA = typeof valueA === 'number' ? valueA : Number.MAX_VALUE;
      const sortB = typeof valueB === 'number' ? valueB : Number.MAX_VALUE;

      if (sortA === 0 && sortB === 0) {
        return 0;
      } else if (sortA === 0) {
        return sortOrder === 'ASC' ? -1 : 1;
      } else if (sortB === 0) {
        return sortOrder === 'ASC' ? 1 : -1;
      }

      return sortOrder === 'ASC' ? sortA - sortB : sortB - sortA;
    } 
    // string || dateString
    else if (fieldType === 'string' || fieldType === 'dateString') {
      const sortA = typeof valueA === 'string' ? valueA : '';
      const sortB = typeof valueB === 'string' ? valueB : '';

      return sortOrder === 'ASC' ? sortA.localeCompare(sortB) : sortB.localeCompare(sortA);
    } 
    // date
    else if (fieldType === 'date') {
      const sortA = valueA instanceof Date ? valueA : new Date(0);
      const sortB = valueB instanceof Date ? valueB : new Date(0);

      return sortOrder === 'ASC' ? sortA - sortB : sortB - sortA;
    } else {
      // Handle other field types or default to no sorting
      return 0;
    }
  });
}

export function cssToRgb(cssColor, asObject) {
  // Remover el '#' si está presente
  if (cssColor.charAt(0) === '#') {
      cssColor = cssColor.substr(1);
  }

  // Extraer los valores de los componentes de color
  var r = parseInt(cssColor.substr(0, 2), 16);
  var g = parseInt(cssColor.substr(2, 2), 16);
  var b = parseInt(cssColor.substr(4, 2), 16);

  if (asObject) {
    // Retornar el valor RGB en un objeto
    return {
        r: r,
        g: g,
        b: b
    };
  }

  return r + ', ' + g + ', ' + b;
}

export const getContrastColor = (color, luminosityTolerance = 0.5) => {
  // Convertir el color a formato RGB
  const rgb = cssToRgb(color, true);

  // Calcular la luminosidad relativa (0 para negro, 1 para blanco)
  const luminosity = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;

  // Elegir el color de contraste (blanco o negro)
  return luminosity > luminosityTolerance ? 'black' : 'white';
};


export const getContrastColorWithAlpha = (color, alpha) => {
  // Convertir el color a formato RGB
  const rgb = cssToRgb(color, true);

  // Calcular la luminosidad relativa (0 para negro, 1 para blanco)
  const luminosity = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;

  // Elegir el color de contraste (blanco o negro)
  const contrastColor = luminosity > 0.5 ? 'black' : 'white';

  // Crear el color con la transparencia especificada
  return `rgba(${contrastColor}, ${alpha})`;
};


export const getContrastColorWithAlphaAndLuminosity = (color, alpha, luminosity) => {
  // Convertir el color a formato RGB
  const rgb = cssToRgb(color, true);

  // Calcular la luminosidad relativa (0 para negro, 1 para blanco)
  const currentLuminosity = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;

  // Ajustar la luminosidad al valor deseado
  const adjustedLuminosity = Math.max(Math.min(luminosity, 1), 0);

  // Calcular la diferencia de luminosidad
  const luminosityDifference = adjustedLuminosity - currentLuminosity;

  // Ajustar los valores RGB para obtener la luminosidad deseada
  const adjustedRgb = {
    r: Math.round(rgb.r + luminosityDifference * 255),
    g: Math.round(rgb.g + luminosityDifference * 255),
    b: Math.round(rgb.b + luminosityDifference * 255)
  };

  // Convertir el color RGB ajustado a formato hexadecimal
  const adjustedHex = `#${adjustedRgb.r.toString(16).padStart(2, '0')}${adjustedRgb.g.toString(16).padStart(2, '0')}${adjustedRgb.b.toString(16).padStart(2, '0')}`;

  // Crear el color con la transparencia especificada
  return `rgba(${adjustedHex}, ${alpha})`;
};


export function lineBreaks(text) {
  if (!text) { return ''; }
  const parts = text.split('\n').map((fragmento, index, array) => {
    return index === array.length - 1 ? fragmento : [fragmento, <br key={index} />];
  });

  // Si la text termina con '\n', elimina el último elemento que será un <br/>
  if (text.endsWith('\n')) {
    parts.pop();
  }

  return parts;
}


export const withProvider = (Component, Provider) => (props) => (
  <Provider>
    <Component {...props} />
  </Provider>
);


export const arrayToTrueMap = (value) => {
  return _.reduce(value, (acc, item) => {
    acc[item] = true;
    return acc;
  }, {});
};