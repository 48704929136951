import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { IonMenu, IonRouterOutlet, IonSplitPane } from '@ionic/react';

import { useAuth } from "./modules/user/AuthContext";

import { AdminEntityCrud } from "./routes/AdminEntityCrud";
import { AdminEntityCrudForm } from "./routes/AdminEntityCrudForm";
import { AdminEntityCrudShow } from "./routes/AdminEntityCrudShow";
import { AdminEntityCreator } from "./routes/AdminEntityCreator";
import { AdminEntityCreatorForm } from "./routes/AdminEntityCreatorForm";

import { AdminModulePage } from "./routes/AdminModulePage";

import { AdminUserCustomerData } from "./modules/user/AdminUserCustomerData";
import { AdminUserSetCustomerData } from "./modules/user/AdminUserSetCustomerData";
import { Capacitor } from "@capacitor/core";
import { PanelProvider } from "./modules/panel/usePanel";
import { MenuMainContent } from "./modules/panel/MenuMainContent";
import { AdminTabConfig } from "./modules/panel/AdminTabConfig";
import { AdminTabEntity } from "./modules/panel/AdminTabEntity";
import { AdminTabHome } from "./modules/panel/AdminTabHome";


export function LayoutPanel (props) {
  const { children } = props;
  return (
    <PanelProvider>
      <IonSplitPane contentId="main">
        {/* Menu */}
        <IonMenu type="overlay" contentId="main" side="start" menuId="main" className={`md:z-10 md:border-r md:border-gray-200`}>
          <MenuMainContent triggerId="main" history={props.history} menuName="main" />
        </IonMenu>

        <IonRouterOutlet id="main">
          {children}
        </IonRouterOutlet>
      </IonSplitPane>
    </PanelProvider>
  );
}

function Panel (props) {
  const { history } = props;
  const { user } = useAuth();
  
  useEffect(() => {
    if (user && !user?.userDoc?.data) {
      if (Capacitor.isNativePlatform()) {
        history.replace('/login');
      } else {
        history.replace('/');
      }
    }
  }, [user]);

  // TODO kickout based on appPage's permissions

  return (
    <Switch>
      {/* TAB Panel main */}
      <Route path="/a/panel">
        <IonRouterOutlet>
          <Switch>
            <Route path="/a/panel" exact component={AdminTabHome}/>
          </Switch>
        </IonRouterOutlet>
      </Route>

      {/* TAB Config */}
      <Route path="/a/config">
        <IonRouterOutlet>
          <Switch>
            <Route path="/a/config" exact component={AdminTabConfig}/>
            {/* Entity Creator */}
            <Route path="/a/config/entity-creator-list" component={AdminEntityCreator}/>
            <Route path="/a/config/entity-creator/:entityId/form" component={AdminEntityCreatorForm}/>
            {/* <Route path="/a/config/entity-creator/:entityId/show" component={AdminEntityCreatorShow}/> */}
            {/* <Route path="/a/config/entity-creator/:entityId/taxonomy" component={AdminEntityCreatorTaxonomy}/>
            <Route path="/a/config/entity-creator/:entityId/filterMenu" component={AdminEntityCreatorFilterMenu}/> */}
          </Switch>
        </IonRouterOutlet>
      </Route>

      {/* User */}
      <Route path="/a/user">
        <IonRouterOutlet>
          <Route path="/a/user/update-data" component={AdminUserSetCustomerData}/>
          <Route path="/a/user/set-data" render={props => 
              <AdminUserSetCustomerData 
                {...props} 
                mode="register"
                showGoBack={false}
                showMenu={false}
                showGoToProfile={false}
              />
            }
          />
          <Route path="/a/user/data" component={AdminUserCustomerData}/>
          {/* <Route path="/a/user/profile" component={AdminUserProfile}/> */}
        </IonRouterOutlet>
      </Route>

      {/* Entity */}
      {/* Entity Crud */}
      <Route path="/a/entity">
        <IonRouterOutlet>
          <Switch>
            <Route path="/a/entity/:entitySlug/:docId/form" exact component={AdminEntityCrudForm}/>
            <Route path="/a/entity/:entitySlug/:docId" exact component={AdminEntityCrudShow}/>
            <Route path="/a/entity/:entitySlug" exact component={AdminEntityCrud}/>
            <Route path="/a/entity" exact component={AdminTabEntity} />
          </Switch>
        </IonRouterOutlet>
      </Route>

      {/* Module custom routes */}
      <Route path="/a/:entitySlug/:action">
        <IonRouterOutlet>
          <Switch>
            <Route path="/a/:entitySlug/:action/*" exact component={AdminModulePage}/>            
            <Route path="/a/:entitySlug/:action" exact component={AdminModulePage}/>
          </Switch>
        </IonRouterOutlet>
      </Route>
    </Switch>
  );
}

function AppPanel () {
  return (
    <Route path="/" render={props => {
      return (
        <LayoutPanel {...props}>
          {/* Logged in */}
          <Route path="/a" component={Panel} />
        </LayoutPanel>
      );
    }}/>
  );
}

export default AppPanel;
