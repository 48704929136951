import { RouteProductFilter } from "./RouteProductFilter";
import { RouteProductView } from "./RouteProductView";
import { RouteCartView } from "./RouteCartView";
// import { RoutePoliticaPrivacidad } from "./RoutePoliticaPrivacidad";
// import { RouteSeguridadDeDatos } from "./RouteSeguridadDeDatos";


export default function ({ setPermissions }) {
  return {
    slug: 'pages',
    label: 'Páginas públicas',
    entities: {},
    permissions: [],
    components: {},
    routesPublic: {
      'page': {
        // 'politica-de-privacidad': {
        //   Content: RoutePoliticaPrivacidad
        // },
        // 'seguridad-de-datos': {
        //   Content: RouteSeguridadDeDatos
        // },
      },
      'products': {
        'filter': {
          PageStack: RouteProductFilter
        },
        'view': {
          PageStack: RouteProductView
        },
      },
      'carts': {
        'view': {
          PageStack: RouteCartView
        }
      }
    },
  };
};
