import { LayoutAdmin } from "./LayoutAdmin";
import { useModule } from "../../context/ModuleContext";
import PartOfModule from "../../components/Module/PartOfModule";
import { MenuOfEntityCrud } from "./MenuOfEntityCrud";
import config from "../../config";


export function AdminTabEntity(props) {
  const { isAllowed, user } = useModule();  

  return (
    <LayoutAdmin 
      history={props.history}
      title="Listados"
      defaultHref={config.modules.panel.mainPath}
    >
      <div className="ion-padding">
        <PartOfModule
          type="main"
          action="AdminEntityBeforeContent"
          param={{ isAllowed, user }}
        />

        <MenuOfEntityCrud></MenuOfEntityCrud>

        <PartOfModule
          type="main"
          action="AdminEntityAfterContent"
          param={{ isAllowed, user }}
        />
      </div>

      {/* <div className="mt-[300px]">
        <button className="p-2 bg-red-100 text-red-700 rounded-sm" type="button" 
        onClick={async () => {
          await updateallFilesMetadata();
        }}>
          Actualizar metadatos
        </button>
      </div> */}
    </LayoutAdmin>
  );
}