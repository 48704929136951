import { useState } from 'react';

export default function FrecuentAnswersBlock({ data }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div id='preguntas' className="px-4 py-20 text-center">
      <div className="container-width">
        <h3 className="pb-4 text-3xl lg:text-4xl lg:pb-6 text-center font-bold font-brand-secondary text-brand-slate">{data.title}</h3>
        <div className="grid gap-4 grid-flow-row grid-cols-1 font-brand-secondary">
          {data.items.map((item, index) => (
            <div
              key={index}
              className={`cursor-pointer border-b-2 py-3 px-4
                ${
                index === activeIndex ? 'active' : ''
              }`}
              onClick={() => handleAccordionClick(index)}
            >
              <div className="font-semibold md:text-lg">{item.title}</div>
              {index === activeIndex && <div className="my-2 lg:text-lg">{item.content}</div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
