import { useEffect } from "react";
import { useScrollTo, useScrollUtils } from "../panel/useScrollUtils";
import { IonContent, IonPage, IonRefresher, IonRefresherContent } from "@ionic/react";
import { useLayoutHome } from "./useLayoutHome";
import { MenuHome } from "../../customModules/layout/MenuHome";
import BadgeLoading from "../../components/ui/BadgeLoading";
import * as specSite from "../../customModules/specSite";
import useURLVariables from "./useURLVariables";


export function LayoutHome(props) {
  let {
    specDesign,
    mainSectionClass,
    CustomMenuHome,
    children,
    match,
    showRefresher = true
  } = props;
  const { isLayoutSetted, setLayoutDesign } = useLayoutHome();
  const { scrollToDelay } = useScrollTo();
  const { elementToScrollRef, handleScroll, resetScroll } = useScrollUtils();
  useURLVariables();

  useEffect(() => {
    scrollToDelay('main');
    resetScroll();
  }, [match?.url]);

  useEffect(() => {
    setLayoutDesign(specDesign || specSite.specDesign);
  }, [specDesign]);

  if (!isLayoutSetted) {
    return (
      <div className="pt-32 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-primary" />
      </div>
    );
  }

  const handleRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 400);
  };

  return (
    <IonPage id="main">
      <IonContent
        ref={elementToScrollRef}
        scrollEvents={true}
        onIonScroll={handleScroll}
      >
        {showRefresher ? (
          <IonRefresher slot="fixed" pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
        ) : null}
        
        {/* main menu */}
        {CustomMenuHome ? (
          <CustomMenuHome {...props} />
        ) : (
          <MenuHome {...props} />
        )}
        {/* content */}
        <section className="layout-main-section-container min-h-screen">
          <div className={`layout-main-section z-10 min-h-screen ${mainSectionClass || ''}`}>
            {children}
            {/* <div className="max-w-none -mx-5 flex place-content-center pt-[100px] pb-[150px] relative overflow-hidden">
              <img src="/[logo].png" alt="logo" className="max-w-none spin-slow object-cover w-[600px] xs:w-[700px] sm:w-[800px] md:w-[800px]" />
            </div> */}
          </div>
        </section>

      </IonContent>
    </IonPage>
  );
}