import _ from 'lodash';


const modelQueryLibraries = {};

/**
 * Almacenar en memoria las busquedas filtradas realizadas por VARIOS Models
 */
export default function getModelQueryCache(modelClass, refreshCache = false) {
  if (!modelQueryLibraries[modelClass.collectionName] || refreshCache) {
    modelQueryLibraries[modelClass.collectionName] = new ModelQueryCache(modelClass);
  }

  return modelQueryLibraries[modelClass.collectionName];
}


/**
 * Crea la clase ModelQueryCache que permita:
 * - Se debe poder realizar busquedas filtradas con Model.filterByAttributes
 * - Se debe almacenar en memoria el resultado de filterByAttributes asociado a:
 *    - el filterData utilizado
 *    - los valores de options de filterByAttributes
 *      - la página solicitada
 *      - el campo de orderBy
 * - Si se realiza una busqueda filtrada, empieza en la página 1, entonces cuando se solicita una página posterior, se debe realizar la búsqueda a partir de los primeros resultadas, optimizando así las peticiones al servidor
 */
export class ModelQueryCache {
  constructor(modelClass) {
    this.modelClass = modelClass;
    this.cache = {};
    this.firstPageCache = {};
  }

  /**
   * Obtiene los datos de la página solicitada utilizando el método filter de ModelQueryCache.
   * Luego, busca y retorna las páginas desde la página 1 hasta la página solicitada.
   * @param {Object} filterData Los datos de filtro para la búsqueda.
   * @param {Object} options Opciones adicionales para la búsqueda, como la página solicitada, el orden y el límite de resultados.
   * @returns {Array} Un array de arrays que representa las páginas desde la página 1 hasta la solicitada.
   */
  async filterAndGetPages(filterData, options) {
    const { page = 1 } = options;
    // Buscar específicamente la página solicitada utilizando el método filter
    const resultPage = await this.filter(filterData, { ...options, page });
    // Si la página solicitada es la 1, no es necesario buscar más páginas anteriores
    if (page === 1) {
      return [resultPage];
    }
    // Si la página solicitada no es la 1, buscar las páginas desde la 1 hasta la solicitada
    const pagesToFetch = _.range(1, page + 1);
    const cachedPages = await Promise.all(pagesToFetch.map(async (pageNum) => {
      const cachedPage = await this.filter(filterData, { ...options, page: pageNum });
      return cachedPage;
    }));
    return cachedPages;
  }
  
  /**
   * Mejora filterByAttributes permitiendo el siguiente caso:
   * - Si se solicita la página 5, busca en el cache la página más cercana a la 5 para utilizar "_.last(firstPageResult)?.cursor".
   * - En el caso en que se encuentre en el caché la página 3, entonces utiliza el cursor correspondiente del último registro de la página 3, además según page y limit calcula cuantos registro debe pedir para obtener la página 5.
   * - Al recibir los datos combinados de las páginas 4 y 5, las separa y guarda en el cache
   */
  async filter(filterData = {}, options = {}) {
    const { page = 1, orderBy = null, limit = 10 } = options;
    const cacheKey = JSON.stringify({ filterData, page, orderBy, limit });
    if (!this.cache[cacheKey]) {
      // If this is the first page, store the results in the firstPageCache
      if (page === 1) {
        const result = await this.modelClass.filterByAttributes(filterData, { orderBy, limit });
        this.cache[cacheKey] = result;
      } else {
        // Calculate the nearest cached page to the requested page
        let nearestCachedPage = this.findNearestCachedPage(filterData, page, orderBy, limit);
        let nearestCachedPageKey;
        if (!nearestCachedPage) {
          nearestCachedPage = 1;
          nearestCachedPageKey = JSON.stringify({ filterData, page: nearestCachedPage, orderBy, limit });
          const result = await this.modelClass.filterByAttributes(filterData, { orderBy, limit });
          this.cache[nearestCachedPageKey] = result;
        } else {
          nearestCachedPageKey = JSON.stringify({ filterData, page: nearestCachedPage, orderBy, limit });
        }
        const nearestCachedPageResult = this.cache[nearestCachedPageKey];
        if (nearestCachedPageResult) {
          // Use the cursor of the last item in the nearest cached page as the starting point
          const startAfter = _.last(nearestCachedPageResult)?.cursor;
          // Calculate the number of items to fetch based on the requested page and limit
          const itemsToFetch = limit * (page - nearestCachedPage);
          // Fetch the remaining items for the requested page
          const result = await this.modelClass.filterByAttributes(filterData, { orderBy, limit: itemsToFetch, startAfter });
          // Store the intermediate pages in the cache
          this.cachePages(result, nearestCachedPage, filterData, orderBy, limit);
        }
      }
    }
  
    return this.cache[cacheKey] || [];
  }

  /**
   * Caches the intermediate pages.
   * @param {array} resultPages The intermediate pages to cache.
   * @param {object} filterData Los datos del filtro.
   * @param {string} orderBy El campo de ordenación.
   * @param {number} limit El límite de resultados por página.
   */
  cachePages(result, nearestCachedPage, filterData, orderBy, limit) {
    // Separate the intermediate pages from the result
    const resultPages = _.chunk(result, limit);
    _.forEach(resultPages, (results, page) => {
      const pageKey = JSON.stringify({ filterData, page: nearestCachedPage + 1 + parseInt(page), orderBy, limit });
      this.cache[pageKey] = results;
    });
  }

  /**
   * Busca en el cache la página más cercana a la solicitada.
   *
   * @param {object} filterData Los datos del filtro.
   * @param {number} page La página solicitada.
   * @param {string} orderBy El campo de ordenación.
   * @param {number} limit El límite de resultados por página.
   * @returns {number} La página más cercana en caché.
   */
  findNearestCachedPage(filterData, page, orderBy, limit) {
    let nearestCachedPage = Math.floor((page - 1) / limit) * limit + 1;
    while (!this.cache[JSON.stringify({ filterData, page: nearestCachedPage, orderBy, limit })]) {
      nearestCachedPage -= limit;
    }
    return nearestCachedPage;
  }  
}