import ContactBTN from "../../components/ContactBTN";
import config from "../../config";
import { useStateSingleResult } from "../../libs/ModelClass";
import ClipboardInput from "../../components/ui/ClipboardInput";


const AdminHomeAfterContent = ({ user }) => {
  const managerDoc = useStateSingleResult({
    entitySlug: config.modules.accountManager.accountManagerEntitySlug,
    id: user.userDoc.data.accountManager
  });
  const managerUserProfileDoc = useStateSingleResult({
    entitySlug: config.modules.user.usersEntitySlug,
    id: managerDoc?.data.userProfile
  });
  const getAffiliateLink = () => {
    return `${config.protocol}://${config.domain}?affiliate=${managerDoc?.id}`;
  };

  return (
    <div className="space-y-8">
      {managerUserProfileDoc ? (
        <div className="">
          <h2 className="text-lg font-brand-main font-semibold">Gestor de cuenta</h2>
          <ContactBTN
            label="Contactar" 
            message={`Hola ${managerUserProfileDoc.data.name}`} 
            number={managerUserProfileDoc.data.phone}
            design="small"
          />
        </div>
      ) : null}

      {managerUserProfileDoc && user.rolesDoc?.data.nameSlug === 'account-manager' ? (
        <div className="">
          <h2 className="text-lg font-brand-main font-semibold">Enlace de afiliados</h2>
          <ClipboardInput value={getAffiliateLink()} />
        </div>
      ) : null}
    </div>
  );
};

export default function ({ setPermissions }) {
  return {
    slug: 'layout',
    label: 'Layout',
    entities: {},
    permissions: [],
    components: {
      'main': {
        // return true if user has already registered, 
        // if false redirect to register form
        'userConditionToLogin': async (userWithCredentials, history) => {
          return !!userWithCredentials?.userDoc?.data?.name;
        },
        // 'userProfileBeforeSave': async (user, values) => {},
        //// Example
        // 'userProfileOnRedirect': async (user, history, config) => {
        //   if (user.rolesDoc.data.nameSlug === 'deliveryDriver') {
        //     history.push(config.modules.panel.mainPathForDrivers);
        //   } else {
        //     history.push(config.modules.panel.mainPath);
        //   }
        // },
        AdminHomeAfterContent
      }
    }
  };
};