import { Capacitor } from "@capacitor/core";
import { getApp, initializeApp } from "firebase/app";
import { getAuth, indexedDBLocalPersistence, initializeAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { getStorage, ref, uploadBytes, deleteObject, getDownloadURL, list, updateMetadata } from 'firebase/storage';
import { v4 } from 'uuid';
import _ from 'lodash';
import config from './config';
import { slug } from "./libs/utils";
import * as geofire from 'geofire-common';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBrtbR9sc9y3EmXjc2DOzWfSpu-vzu05HU",
  authDomain: "queens-fit-wear.firebaseapp.com",
  projectId: "queens-fit-wear",
  storageBucket: "queens-fit-wear.appspot.com",
  messagingSenderId: "589028156724",
  appId: "1:589028156724:web:a27847ade67e005bd2f161",
  measurementId: "G-71CDDNZ9MR"  
};
const filesMetadata = {
  cacheControl: 'public, max-age='+30*24*60*60+', immutable' // 30 days as attachments cache
};

////////////////////////////////////////////////////////////////
// Initialize Firebase
////////////////////////////////////////////////////////////////

export const app = initializeApp(firebaseConfig);
export const auth = getFirebaseAuth(app);
export const db = getFirestore();
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

function getFirebaseAuth (app) {
  if (Capacitor.isNativePlatform()) {
    return initializeAuth(app, {
      persistence: indexedDBLocalPersistence,
    });
  } else {
    return getAuth(app);
  }
};

////////////////////////////////////////////////////////////////
// Funciones de Geo
////////////////////////////////////////////////////////////////

export function getGeofire() {
  return geofire;
}

////////////////////////////////////////////////////////////////
// Actions
//////////////////////////////////////////////////////////////// 

export function addLogEvent (event, properties) {
  logEvent(analytics, event, properties);
}

export function addUserProperties (properties) {
  setUserProperties(analytics, properties);
} 

export async function uploadFile (file, name) {
  const storageRef = ref(storage, name || v4());
  await uploadBytes(storageRef, file, filesMetadata);
  const url = await getDownloadURL(storageRef);
  return url;
}

export async function deleteFile(path) {
  try {
    const fileRef = ref(storage, path);
    await deleteObject(fileRef);
  } catch (error) {
    console.log('Error deleting file:', error);
    throw error;
  }
}

/**
 * Elimina las variaciones de una imagen según las dimensiones especificadas.
 * @param {string} filePath - Ruta de la imagen a eliminar.
 */
export async function deleteImageFile(filePath) {
  try {
    const [basePath] = filePath.split('.'); // Obtener la ruta base sin la extensión
    const [folder, hash] = basePath.split('/');
    const format = filePath.split('.').pop().split('?')[0]; // Obtener la extensión correctamente
    const deletePromises = config.imageDimensions.map((dimension) => {
      const path = `${folder}/${hash}${dimension.suffix}.${format}`;
      const fileRef = ref(storage, path);
      return deleteObject(fileRef);
    });
    await Promise.all(deletePromises);
  } catch (error) {
    console.log('Error deleting image files:', error);
    throw error;
  }
}

////////////////////////////////////////////////////////////////
// Analytics functions
////////////////////////////////////////////////////////////////

export function useAnalytics ({ productsById, cart, getVariantAttr }) {
  
  /* 
  Format {
    currency: 'USD',
    value: 9.99,
    items: [{
      ... // single item array
    }]
  }
  */
  function viewItem (productId) {
    // console.log('viewItem params', productId)
    let singleItemList = productRawToItem()({ id: productId });
    let item = {
      currency: singleItemList.currency,
      value: singleItemList.price,
      items: [singleItemList]
    };
    // console.log('viewItem result', item, singleItemList);
    addLogEvent('view_item', item);
    return item;
  }

  /* 
  Format {
    item_list_name: 'Related products',
    item_list_id: 'related_products',
    items: [{ ... }],
  }
  */
  function viewItemList (name, productsRaw, filtersRaw) {
    // console.log('viewItemList params', name, productsRaw, filtersRaw)
    let list = {
      item_list_name: name,
      item_list_id: slug(name),
    };
    let items = productsRaw.map(productRawToItem(list));
    list.items = items;
    if (_.size(filtersRaw)) {
      let filters = '';
      _.map(filtersRaw, (options, filter) => {
        filters += filter + '=' + _.keys(options).join('_');
      });
      list.filters = filters;
    }
    // console.log('viewItemList result', list);
    addLogEvent('view_item_list', list);
    return list;
  }
  
  /* 
  Format {
    item_id: 'SKU_12345',
    item_name: 'jeggings',
    coupon: 'SUMMER_FUN',
    discount: 2.22,
    index: 5,
    item_list_name: 'Related Products',
    item_list_id: 'related_products',
    affiliation: 'Google Store',
    item_brand: 'Gucci',
    item_category: 'pants',
    item_variant: 'black',
    price: 9.99,
    currency: 'USD',
    quantity: 1
  }
  */
  function productRawToItem (listDef) {
    return ({ id }, index) => {
      let prodName = getVariantAttr('name', id);
      let prodInCart;
      if (cart.products.length) {
        prodInCart = _.find(cart.products, (product) => product.id === id);
      }
      let item = {
        item_id: slug(prodName) + '_' + id,
        item_name: prodName,
        // coupon: '',
        // discount: '',
        // affiliation: '',
        // item_brand: '',
        item_category: getVariantAttr('sectors', id, ''),
        item_variant: productsById[id].parentId === 'main' ? 'main' : getVariantAttr('colors', id),
        price: parseInt(getVariantAttr('price', id, 0)),
        currency: config.currency,
      };
      if (listDef) {
        item['index'] = index;
        item['item_list_name'] = listDef['item_list_name'];
        item['item_list_id'] = listDef['item_list_id'];
      }
      if (prodInCart) {
        item['quantity'] = prodInCart.qty;
      }
      return item;
    }
  }

  return {
    viewItem,
    viewItemList,
    productRawToItem
  }
}

////////////////////////////////////////////////////////////////
// Storage functions
////////////////////////////////////////////////////////////////

export async function updateallFilesMetadata () {
  // Create a reference under which you want to list
  const listRef = ref(storage, config.imgFolder);

  // Fetch the first page of 100.
  const results = await list(listRef, { maxResults: 1000 });

  for (const key in results.items) {
    if (Object.hasOwnProperty.call(results.items, key)) {
      const file = results.items[key];
      console.log(file.fullPath)
      await updateMetadata(file, filesMetadata);
    }
  }
  console.log('Updated all files metadata');
}