import { Fragment, memo } from "react";
import { EntityDocListView } from "../../components/EntityDocView";
import useBreakpoints from "../../libs/useBreakpoints";
import { getImageURL, priceFormat } from "../../libs/utils";


const CartItemThumbGrid = (props) => {
  let {
    items,
    entitySpects,
    history,
    onShow,
    classes = {},
    renderAsGrid = true
  } = props;
  const { isMinBreakpointActive } = useBreakpoints();

  const renderItems = (items) => (
    items.map((item) => (
      <Fragment key={item.id}>
        <EntityDocListView
          {...entitySpects}
          doc={item}
          classes={{ fieldLabel: "hidden" }}
          render={({ ViewData, mainAttr, mainImgAttr }) => (
            <button 
              onClick={() => onShow(item)}
              className="
                flex-1 border border-gray-300 rounded overflow-hidden 
                shadow hover:shadow-md
                hover:scale-[1.01]
                transition-all duration-200 ease-in-out
              "
            >
              {/* image xs */}
              <img 
                className={`${classes.imgProportion} w-full object-cover`} 
                src={item?.data?.[mainImgAttr]?.length && getImageURL(item.data[mainImgAttr][0], 'md')} 
              /> 
              {/* data */}
              <div className="bg-white pb-1">
                <div className="my-4 px-4">
                  <ViewData field="sizes" 
                    classes={{ 
                      fieldContainer: 'mb-4 text-sm',
                      fieldViewSelectedOption: 'font-bold text-black',
                      fieldViewUnselectedOption: 'text-gray-400 line-through' 
                    }}
                    parts={{ beforeItems: () => (
                      <ViewData field="colors" classes={{ 
                          fieldContainer: '-top-1 ml-0.5 mr-1.5 scale-125 text-xs'
                        }}
                      />
                    )}}
                  />
                  <ViewData field="name" classes={{ fieldContainer: 'px-1 md:text-md lg:text-lg font-semibold leading-tight' }} />
                  <ViewData field="price" classes={{ fieldContainer: 'text-sm md:text-base text-gray-500 my-1 lg:my-0' }} decorator={priceFormat} />
                </div>
              </div>
            </button>
          )}
        />
      </Fragment>
    ))
  );

  return (
    items ? (
      renderAsGrid ? (
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-5 items-start">
          {renderItems(items)}
        </div>
      ) : (
        renderItems(items)
      )
    ) : null
  );
};

export default CartItemThumbGrid;

export const CartItemThumbGridMemo = memo(CartItemThumbGrid, (prevProps, nextProps) => {
  return prevProps.items === nextProps.items;
});