export default function CtaImgAndContent({ data }) {
  return (
    <div id="explorar" className="text-black text-center flex flex-col lg:flex-row lg:mb-10 xl:mb-16">

      {/* Left / Top */}
      <div className="relative h-[60vh] sm:h-screen w-full lg:basis-7/12">

        <div className="
          h-[42vh] sm:h-[65vh] md:h-[75vh] lg:h-[85vh] xl:h-[95vh]
          w-[55vw]             md:w-[50vw] lg:w-[40vw] xl:w-[70vh]
          sm:left-16 lg:left-8 xl:left-12 top-8 sm:top-2
          bg-cover bg-center absolute left-10"
          style={{ backgroundImage: `url(${data.backImageUrl})` }}>
        </div>

        <div className="
          h-[40vh] sm:h-[60vh] md:h-[70vh] lg:h-[70vh] xl:h-[75vh] 
          w-[55vw] sm:w-[60vw] md:w-[50vw] lg:w-[40vw] xl:w-[35vw]
          sm:right-16 lg:right-4 xl:right-12 
          bottom-5 sm:bottom-20 md:bottom-20 lg:bottom-4 xl:-bottom-10 bg-cover bg-center absolute right-10"
          style={{ backgroundImage: `url(${data.frontImageUrl})` }}>
        </div>

      </div>


      {/* Right / Bottom*/}
      <div className="lg:basis-5/12 p-8">

        <div className="flex flex-col text-center items-center lg:text-base text-sm lg:text-start sm:mt-4 lg:mt-64 sm:px-10 lg:px-4 ">
          <img src={data.title} alt='Sentite Queen' className="h-16 w-72 lg:h-20 lg:w-96" />
          <p className="pb-4 pt-2 lg:px-2 xl:px-12 lg:ml-4 text-base md:text-lg font-brand-secondary">{data.content}</p>
        </div>

        <div className="flex items-center justify-center place-self-center text-center w-40 mx-auto text-white bg-black hover:bg-white hover:text-black hover:border-2 hover:border-black p-2 sm:pr-4 rounded-sm shadow-sm cursor-pointer hover:shadow-md ">
          <a href={data.ctaButton.ctaUrl} target="_blank" className="text-base" rel="noreferrer">
            {data.ctaButton.ctaTitle}
          </a>
        </div>
      </div>
    </div>
  );
}
