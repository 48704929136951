import { processRenderProps } from './dataTypeUtils';
import {
  FormField, FormFieldCustom
} from '../Form';
import {
  // Number
  FilterRenderNumber,
  FilterCreatorNumber,
} from '../Filters';
import RawInputCurrency, { RawInputCountryDetails } from '../Form/RawInputCurrency';


export default {
  Render(props) {
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);

    return (
      <FormFieldCustom
        name={_fieldName}
        {...param}
        {...style}
        {...props}
        Render={RawInputCurrency}
      />
    );
  },
  
  RenderFilter(props) {
    return <FilterRenderNumber {...props} DataTypeRender={this.Render} />
  },

  RenderFilterParam(props) {
    return <FilterCreatorNumber {...props} />
  },

  RenderInputParams: ({ values, param }) => (null),

  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),

  RenderShowParams: ({ values }) => (<>
    {/* Country Select */}
    <FormFieldCustom
      name="param.country"
      title="Seleccionar Divisa"
      placeholder="Seleccionar Divisa"
      Render={RawInputCountryDetails}
    />
  </>),

  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => (null)
}