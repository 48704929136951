import _ from 'lodash';
import dayjs from 'dayjs';
import config from '../../config';
import entities from './entities.json';
import { VariantsCRUD, updateVariantDocs, deleteVariantDocs } from "./VariantsCRUD";
import { getImageURL, priceFormat } from "../../libs/utils";
import { EntityDocListView } from "../../components/EntityDocView";
import EntityGetOneDocument from "../../components/EntityGetOneDocument";
import { registerBlocks } from "../blockStack/BlockStack";
import BlockProductGridWithFilters from "./BlockProductGridWithFilters";
import BlockProductView from "./BlockProductView";
import BlockCartView from "./BlockCartView";


let { 
  carts,
  cartItems,
  cartItemVariants,
  cartItemCategories,
  cartItemTypes
} = entities;


/*
  carts: [{

    userId,
    
    isPaid,
    paymentImage,

    observation,

    priceTotal,

    items: [{
      id,        // id in cart
      extraOfId, // sub item of item in cart, example: toppings
      
      itemId,    // id of the entityDoc
      itemType,  // entitySlug of the item, example: realEstates, products, meals
      
      qty,
      priceOne,
      priceTotal
    }]

  }],
*/

// const AdminHomeBeforeLinksList = () => {
//   return (
//     <div className="col-span-2 mb-4">
//       <Link to={`/admin/cart/add`} className="px-4 p-1.5 text-lg text-left bg-brand-red text-white hover:scale-105 shadow-md shadow-brand-red/30 rounded-md inline-block">
//         {config?.modules?.cart?.labelAddCartBtn || 'Hacer pedido'}
//       </Link>
//     </div>
//   );
// };

const getCrudSettingsCartItems = () => {
  return {
    listOutstandingOnly: true
  };
}

const UnderListItemTitleForCartItems = ({ doc, entitySlug, ViewData }) => (<>
  <div className="flex flex-row gap-2 place-content-start">
    <EntityGetOneDocument
    entitySlug={config?.modules?.cart?.cartItemVariantsEntitySlug} // retrocompatible
    docWhere={{ field: 'mainItemId', value: doc?.id }}>
      {(props) => (
        <div>
          {(doc?.data?.mainImgs?.length || props?.doc?.data?.variantImgs?.length)  ? (
            <div className="w-[110px]">
              <img src={getImageURL((doc?.data?.mainImgs?.length ? doc?.data?.mainImgs : props?.doc?.data?.variantImgs)[0], 'xs')} 
              className="rounded h-[110px] w-full object-cover" /> {/* image xs */}
            </div>
          ) : null}
        </div>
      )}
    </EntityGetOneDocument>
    <div className="">
      <div className="-mt-1.5">
        {(doc?.data?.name && doc?.data?.name !== ' ') ? (
          <ViewData field="name" force={true} classes={{ fieldContainer: 'text-lg uppercase font-semibold text-gray-800' }} />
        ) : (doc?.data?.itemCategory ? (
          <EntityGetOneDocument
          entitySlug={config?.modules?.cart?.cartItemCategoriesEntitySlug} // retrocompatible
          docId={doc?.data?.itemCategory}>
            {entitySpecs => (
              <EntityDocListView {...entitySpecs} render={({ ViewData }) => (
                <ViewData field="nameSingle" force={true} taxonomyTypesDocList={entitySpecs.taxonomyTypesDocList} classes={{ fieldContainer: 'text-lg uppercase font-semibold text-gray-800', fieldLabel: 'hidden' }} />
              )} />
            )}
          </EntityGetOneDocument>
        ) : null)}
        <ViewData field="itemType" force={true} classes={{ fieldContainer: 'text-sm uppercase font-normal text-gray-400' }} />
        <ViewData field="itemCategory" force={true} classes={{ fieldContainer: 'text-sm uppercase font-normal text-gray-400' }} />
        <ViewData field="price" force={true} classes={{ fieldContainer: 'text-gray-500' }} decorator={priceFormat} />
      </div>
    </div>
  </div>
</>);

const UnderListItemTitleForCarts = ({ doc, ViewData }) => (<>
  <span className="px-1 py-0.5 font-mono bg-gray-900 text-white rounded-md">{doc?.id}</span>
  <div className={`py-0.5 text-sm text-black`}>{dayjs(doc?.data?.createdAt).format('LLLL')}</div>
</>);

registerBlocks([
  BlockProductGridWithFilters,
  BlockProductView,
  BlockCartView
]);

export default function ({ setPermissions }) {
  return {
    name: 'Cart',
    slug: 'cart',

    permissions: [
      setPermissions({ slug: 'carts', label: 'Pedidos', type: 'coded' }),
      setPermissions({ slug: 'cartItems', label: 'Productos', type: 'coded' }),
      setPermissions({ slug: 'cartItemVariants', label: 'Variantes de Productos', type: 'coded' }),
      setPermissions({ slug: 'cartItemCategories', label: 'Categorías de productos', type: 'coded' }),
      setPermissions({ slug: 'cartItemTypes', label: 'Tipos de productos', type: 'coded' }),
    ],

    entities: {
      carts,
      cartItems,
      cartItemVariants,
      cartItemCategories,
      cartItemTypes
    },

    // implemented on customModules
    routesPublic: {},

    components: {
      // 'main': {
      //   AdminHomeBeforeLinksList
      // },
      'carts': {
        crud: {
          UnderListItemTitle: UnderListItemTitleForCarts,
        }
      },
      'cartItems': {
        crud: {
          settings: getCrudSettingsCartItems,
          UnderListItemTitle: UnderListItemTitleForCartItems,
          UnderForm: VariantsCRUD,
        }
      },
    },
    events: {
      'cartItems': {
        beforeSave: updateVariantDocs,
        beforeDelete: deleteVariantDocs
      }
    }
  };
};