
import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { InputNumber } from 'primereact/inputnumber';
import Model from '../../libs/ModelClass';
import { numberFormat } from '../../libs/utils';

const MaterialsModel = Model.extend('-materials');
const FormulasModel = Model.extend('-formulas');

export const measurementsLabels = [
  { value: 'cup', label: 'Taza', labelShort: 'Tza' },
  { value: 'tsp', label: 'Cucharadita', labelShort: 'Cdta' },
  { value: 'tbsp', label: 'Cucharada', labelShort: 'Cda' },
  { value: 'oz', label: 'Onza', labelShort: 'Onz' },
  { value: 'g', label: 'Gramo', labelShort: 'g' },
  { value: 'kg', label: 'Kilogramo', labelShort: 'kg' },
  { value: 'ml', label: 'Mililitro', labelShort: 'mL' },
  { value: 'l', label: 'Litro', labelShort: 'L' },
  { value: 'm', label: 'Metro', labelShort: 'm' },
  { value: 'mm', label: 'Milímetro', labelShort: 'mm' },
  { value: 'cm', label: 'Centímetro', labelShort: 'cm' },
];

export const ShowRelatedListMaterials = (props) => {
  const {
    relatedListId
  } = props;
  const [formulaDoc, setFormulaDoc] = useState();
  const [items, setItems] = useState([]);
  const [materialsOptions, setMaterialsOptions] = useState([]);
  const [listId, setListId ] = useState(nanoid());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    !formulaDoc && fetchFormula();
  }, [relatedListId]);

  const fetchFormula = async () => {
    const materialsOptions = await fetchMaterials();
    let doc = await FormulasModel.findById(relatedListId);
    const materialsPopulated = doc.data.materials.map((materialItem) => {
      return {
        material: materialsOptions.find(opt => opt.value === materialItem.material),
        qty: materialItem.qty,
        measure: measurementsLabels.find(opt => opt.value === materialItem.measure)
      }
    });
    setItems(materialsPopulated);
    setFormulaDoc(doc);
  }

  const fetchMaterials = async () => {
    let docs = await MaterialsModel.getAll();
    docs = docs.filter(docs => docs.data.deleted !== true);
    let options = docs.map(doc => ({
      value: doc.id,
      label: doc.data.name
    }));
    setMaterialsOptions(options);
    return options;
  }

  return (<>
    <div key={listId} className='mb-2 rounded border border-gray-200'>
      {items?.map((item, index) => (
        <div key={index} className="space-x-1 px-1.5 py-1 border-b border-gray-200">
          <span className="">{numberFormat(item.qty || 0, 2)}</span>
          <span className="">{item.measure?.labelShort}</span>
          <span className="">{item.material?.label}</span>
        </div>
      ))}
    </div>
  </>);
};

const RawInputRelatedListMaterials = (props) => {
  const {
    input
  } = props;
  const [formulaDoc, setFormulaDoc] = useState();
  const [items, setItems] = useState([]);
  const [materialsOptions, setMaterialsOptions] = useState([]);
  const [listId, setListId ] = useState(nanoid());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    !formulaDoc && fetchOrCreateFormula();
  }, [input?.value]);

  useEffect(() => {
    input?.onChange(formulaDoc?.id);
  }, [formulaDoc]);

  const fetchOrCreateFormula = async () => {
    const materialsOptions = await fetchMaterials();
    let doc;
    if (input?.value) {
      doc = await FormulasModel.findById(input.value);
    } else {
      doc = await FormulasModel.create({ materials: [] });
    }
    const materialsPopulated = doc.data.materials.map((materialItem) => {
      return {
        material: materialsOptions.find(opt => opt.value === materialItem.material),
        qty: materialItem.qty,
        measure: measurementsLabels.find(opt => opt.value === materialItem.measure)
      }
    });
    setItems(materialsPopulated);
    setFormulaDoc(doc);
  }

  const saveItems = async (updatedItems) => {
    setIsLoading(true);
    const itemsToSave = (updatedItems || items).map(({ material, qty, measure }) => {
      return {
        material: material?.value || null,
        qty: qty || null,
        measure: measure?.value || null
      };
    });
    formulaDoc.data.materials = itemsToSave;
    await formulaDoc.save();
    setIsLoading(false);
  };

  const fetchMaterials = async () => {
    let docs = await MaterialsModel.getAll();
    docs = docs.filter(docs => docs.data.deleted !== true);
    let options = docs.map(doc => ({
      value: doc.id,
      label: doc.data.name
    }));
    setMaterialsOptions(options);
    return options;
  }

  const handleAddItem = () => {
    const newItem = { material: null, qty: 0 };
    const newItems = [...items, newItem];
    setItems(newItems);
  };

  const handleMaterialChange = async (index, material) => {
    const updatedItems = [...items];
    updatedItems[index].material = material;
    setItems(updatedItems);
    await saveItems(updatedItems);
  };

  const handleQtyChange = (index, qty) => {
    const updatedItems = [...items];
    updatedItems[index].qty = qty;
    setItems(updatedItems);
  };

  const handleMeasurementChange = async (index, measure) => {
    const updatedItems = [...items];
    updatedItems[index].measure = measure;
    setItems(updatedItems);
    await saveItems(updatedItems);
  };

  const handleDelete = async (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
    await saveItems(updatedItems);
  };

  const handleMaterialCreate = async (index, newMaterialName) => {
    setIsLoading(true);
    let newMaterialDoc = await MaterialsModel.create({ name: newMaterialName });
    const updatedMaterials = [
      {
        value: newMaterialDoc.id,
        label: newMaterialName
      },
      ...materialsOptions
    ];
    setMaterialsOptions(updatedMaterials);
    await handleMaterialChange(index, updatedMaterials[0]);
    setIsLoading(false);
  };

  return (<>
    <div key={listId} className='mb-2 rounded-md border border-gray-300'>
      {items?.map((item, index) => (
        <div key={index} className="flex flex-row gap-2 items-start px-1.5 py-2 border-b border-gray-200 hover:bg-gray-200">
          <div className="flex flex-row flex-wrap gap-1 items-center">
            <CreatableSelect
              className="w-full"
              // isClearable
              isMulti={false}
              options={materialsOptions}
              placeholder="Material"
              isDisabled={isLoading}
              isLoading={isLoading}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: 'lightgray',
                  primary: 'darkgray',
                },
              })}
              onCreateOption={newMaterialName => handleMaterialCreate(index, newMaterialName)}
              value={item.material}
              onChange={option => handleMaterialChange(index, option)}
            />
            <InputNumber
              className={`border px-2 py-1.5 rounded w-28 ${isLoading ? 'bg-gray-100 border-gray-200' : 'bg-white border-gray-300'}`}
              placeholder="Cantidad"
              minFractionDigits={2}
              maxFractionDigits={2}
              locale="es-PY"
              disabled={isLoading}
              value={item.qty || 0}
              onChange={(target) => handleQtyChange(index, target.value)}
              onBlur={() => saveItems()}
            />
            <Select
              className="w-36"
              options={measurementsLabels}
              isMulti={false}
              placeholder={`Medida`}
              isDisabled={isLoading}
              isLoading={isLoading}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: 'lightgray',
                  primary: 'darkgray',
                },
              })}
              value={item.measure}
              onChange={(option) => handleMeasurementChange(index, option)}
            />
          </div>
          <button type="button" onClick={() => handleDelete(index)} className="pr-0.5 font-semibold text-gray-400">
            x
          </button>
        </div>
      ))}
    </div>
    <div className="">
      <button type="button" onClick={handleAddItem} className="bg-gray-200 text-gray-600 rounded px-2 p-0.5 text-xs">
        Agregar elemento
      </button>
    </div>
  </>);
};

export default RawInputRelatedListMaterials;