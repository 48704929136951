import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import { lineBreaks } from "../../../libs/utils";


export default function () {
  return {
    type: 'textGrid',
    variation: 'compact',
    template: TextGridBCompact
  };
};

export function TextGridBCompact({ data }) {
  return (
    <div className="px-8 py-24 bg-brand-white text-center">
      <div className="container-width">
        <div className="flex flex-wrap md:grid md:grid-cols-3 gap-8 sm:gap-4 lg:gap-6 ">
          {data.items.map((item, index) => (
            <div key={index} className="font-brand-secondary">
              <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
                <div>
                  <h4 className="font-bold font-brand-main text-black text-2xl mb-2">{lineBreaks(item.title)}</h4>
                  <p className="font-brand-secondary text-gray-500 text-xl">{lineBreaks(item.content)}</p>
                </div>
              </AnimationAddClassWhenVisible>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
